import {getFacility} from '@Features/facility/facilitySelectors';
import {EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON, EVENT_SHOW_SUCCESS_SCREEN_TICKETS} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useSummaryData} from '../../../hooks/useSummaryData';
import Card from './InternalOrderCard';
import TicketsSectionContent from './TicketsSectionContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TicketsSectionLoadingContent from './TicketsSectionLoadingContent';

const TicketsSection = () => {
  const {
    documentAddress,
    isDocumentAddressAvailable,
    isPreviewMode,
    latestOrderStorageData
  } = useSummaryData();

  const facility = useSelector(getFacility);

  if (!isPreviewMode && !isDocumentAddressAvailable) {
    return null;
  }

  const showLoader = !documentAddress && !isPreviewMode;

  useEffect(() => {
    if (!isPreviewMode && !showLoader) {
      const {
        isEventsSent
      } = latestOrderStorageData;
      const {id: facilityId, name: facilityName, company} = {...facility};
      const {industry} = {...company};

      pubsub.trigger(EVENT_SHOW_SUCCESS_SCREEN_TICKETS, {
        facilityId,
        facilityName,
        industry,
        language: parseLanguage(locale.locale),
        isFirstLoading: !isEventsSent
      });
    }
  }, [showLoader]);

  const handleDownloadTickets = () => {
    if (documentAddress) {
      pubsub.trigger(EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON, {
        language: parseLanguage(locale.locale),
        isFirstLoading: !latestOrderStorageData.isEventsSent
      });
      window.open(documentAddress);
      window.focus();
    }
  };

  return (
    <Card>
      {
        showLoader ? <TicketsSectionLoadingContent /> : <TicketsSectionContent />
      }
      <Box
        sx={{
          display: 'flex',
          width: 1,
          justifyContent: {sm: 'end'}
        }}
      >
        <FollowCursorTooltip
          title={locale.translate('actionDisabledInPreviewMode')}
          enable={isPreviewMode}
          styles={{width: {xs: 1, sm: 'auto'}}}
        >
          <Button
            variant={!isPreviewMode ? 'outlined' : 'contained'}
            disabled={isPreviewMode || showLoader}
            color="inherit"
            sx={{width: {xs: 1, sm: 'auto'}}}
            onClick={handleDownloadTickets}
            endIcon={<FileDownloadOutlinedIcon />}
            {...showLoader && {
              disabled: true,
              endIcon: <CircularProgress size={16} color="inherit" />
            }}
          >
            {showLoader ? locale.translate('generating') : locale.translate('download')}
          </Button>
        </FollowCursorTooltip>
      </Box>

    </Card>
  );
};

export default TicketsSection;
