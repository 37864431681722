import React, {useEffect, useRef, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getOrderMutable} from '@Utils/order';
import pubsub from '@Utils/pubsub';
import {EVENT_SUBMITTED_FORM} from '@Utils/events';
import {getFacility} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import {getCheckoutButtonLabel, getCheckoutPriceToPay} from '@Utils/checkoutUtils';
import {parseLanguage} from '@Utils/eventsUtils';
import CheckoutGenerateUserSection from './CheckoutGenerateUserSection';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {getBasketValue} from '@Utils/basket';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {getCheckoutGenerateSchema} from './CheckoutGenerateSchema';
import {InternalPaymentMethods, ICheckoutGenerate, TInternalCheckoutFormValues} from './types';
import {handleApiErrors} from '@Utils/api/errors/apiError';
import {PHONE_PREFIXES} from '@Components/checkoutPages/agreements/phoneNumberSection/PhoneNumberPrefixes';
import PaymentSection from './PaymentSection';
import dayjs from 'dayjs';
import {getSpecificCountry} from '@Utils/invoiceCountries';
import {EInvoiceFormType} from '@Components/invoice/types';
import CheckoutInvoiceSection from './CheckoutInvoiceSection';
import RequestValidationError from '@Utils/api/errors/requestValidationErrror';
import {IBasketItem} from '@Consts/types';
import {useScroll} from '@Utils/hooks/useScroll';
import {useSavedInternalPaymentMethod} from '@Hooks/useSavedInternalPaymentMethod';
import gtm from '@Utils/gtm/core';

const CheckoutGenerate = ({onSubmit}: ICheckoutGenerate) => {
  const formRef = useRef<HTMLFormElement>();
  const facility = useSelector(getFacility);

  if (!facility) {
    return null;
  }

  const {
    id: facilityId,
    name: facilityName,
    company,
    timezone
  } = facility;
  const [defaultError, setDefaultError] = useState<null | string>(null);
  const {
    industry,
    isInternalPaymentReceiptEnabled,
    isOnlinePaymentReceiptEnabled,
    isInternalChannelWithOnlinePaymentEnabled,
    country,
    paymentType
  } = company;
  const basketItems = useSelector(getBasketItems);
  const basketValue = getBasketValue(basketItems, true);
  const config = useSelector(getConfiguration);
  const {widgetType, shippingMethod, onlineGroupId} = config;
  const withContactPhoneNumber = basketItems.some(({activity}: IBasketItem) => activity.salePolicy.buyerPhoneRequired);
  const paymentCompleteUntil = dayjs().tz(timezone).hour(23).minute(59).second(59).add(1, 'day');
  const {getSavedPaymentMethod, savePaymentMethod} = useSavedInternalPaymentMethod();

  const defaultValues: TInternalCheckoutFormValues = {
    phonePrefix: PHONE_PREFIXES[0],
    phoneNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    paymentMethod: getSavedPaymentMethod() ?? (
      isInternalPaymentReceiptEnabled ?
        InternalPaymentMethods.PAYMENT_CARD :
        InternalPaymentMethods.OTHER
    ),
    paymentCompleteUntil,
    isPaymentOnline: false,
    sendInvoice: false,
    invoiceFormType: EInvoiceFormType.COMPANY_SIMPLIFIED,
    invoiceAddressCity: '',
    invoiceAddressStreet: '',
    invoiceAddressPostalCode: '',
    invoiceAddressCountry: getSpecificCountry(country),
    invoiceCompanyName: '',
    invoiceCompanyNip: '',
    invoiceFirstName: '',
    invoiceLastName: '',
    isInvoiceRecipient: false,
    invoiceRecipientName: '',
    invoiceRecipientAddressStreet: '',
    invoiceRecipientAddressCity: '',
    invoiceRecipientAddressPostalCode: '',
    invoiceRecipientAddressCountry: getSpecificCountry(country)
  };
  const {scrollToElement} = useScroll();

  function scrollEffect() {
    if (formRef?.current) {
      scrollToElement(formRef.current);
    }
  }
  useEffect(() => {
    setTimeout(scrollEffect, 550);

    const {amount, currency} = amountToPay;
    const price = amount ? parseFloat(amount) : 0;

    gtm.beginCheckout(facility, onlineGroupId, {basketItems, currency, price});
  }, []);

  const schema = getCheckoutGenerateSchema(timezone);

  const formProperties = useForm<TInternalCheckoutFormValues>({
    defaultValues,
    resolver: yupResolver<TInternalCheckoutFormValues>(schema),
    context: {withContactPhoneNumber}
  });

  const {handleSubmit, setError, watch} = formProperties;
  const invoiceFormType = watch('invoiceFormType');

  async function handleFormSubmit(data: TInternalCheckoutFormValues) {
    const orderMutable = getOrderMutable({
      ...data,
      ...config,
      phonePrefix: data.phonePrefix?.value,
      withContactPhoneNumber,
      basketItems,
      timezone,
      basketValue
    });

    const {items, ...orderDetails} = orderMutable;

    pubsub.trigger(EVENT_SUBMITTED_FORM, {
      facilityId,
      facilityName,
      numberOfTickets: items.length,
      industry,
      affiliationHash: config.affiliationHash,
      orderDetails: JSON.stringify(orderDetails),
      paymentItems: JSON.stringify(orderMutable.items),
      language: parseLanguage(locale.locale)
    });

    data.paymentMethod &&
      savePaymentMethod(data.paymentMethod);

    try {
      await onSubmit(orderMutable);
    } catch (error) {
      if (error instanceof RequestValidationError) {
        handleApiErrors(error.errors, setError, invoiceFormType);
      } else {
        setDefaultError(locale.translate('orderCreationError'));
      }
    }
  }

  const isPaymentOnline = watch('isPaymentOnline');
  const paymentMethod = watch('paymentMethod') as InternalPaymentMethods | undefined;
  const isOnlinePaymentDisabled = !isInternalChannelWithOnlinePaymentEnabled && isPaymentOnline;
  const amountToPay = getCheckoutPriceToPay(
    basketItems,
    paymentType,
    shippingMethod
  );
  const checkoutButtonLabel = getCheckoutButtonLabel(
    amountToPay,
    widgetType,
    paymentType,
    paymentMethod
  );

  return (
    <FormProvider {...formProperties}>
      <Box component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)} ref={formRef} >
        <Paper elevation={2}>
          <Stack direction="column" sx={{p: {xs: 2, md: 3}}}>
            <CheckoutGenerateUserSection withContactPhoneNumber={withContactPhoneNumber} />
            {
              basketValue ? <PaymentSection /> : null
            }

            { !isOnlinePaymentDisabled && (
              <>
                <Divider sx={{mb: 2, mt: 2}} />

                <CheckoutInvoiceSection
                  isOnlinePaymentReceiptEnabled={isOnlinePaymentReceiptEnabled}
                  isInternalPaymentReceiptEnabled={isInternalPaymentReceiptEnabled}
                />

                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  sx={{mt: 3}}
                  data-testid="buttonFormSubmit"
                >
                  {checkoutButtonLabel}
                </Button>
              </>
            )}
            {
              defaultError && (
                <Alert sx={{mt: 3}} severity="error">
                  <Typography>{locale.translate('orderCreationError')}</Typography>
                </Alert>
              )
            }
          </Stack>
        </Paper>
      </Box>
    </FormProvider>
  );
};

export default CheckoutGenerate;
