import React, {useMemo, useRef, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch, useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import pubsub from '@Utils/pubsub';
import FlexBox from '@Components/common/flexBox/FlexBox';
import Text from '@Components/common/text/Text';
import Header from '@Components/common/header/Header';
import locale from '@Utils/locale';
import {
  getDataByShippingMethodType,
  getInitialFormValues,
  getShippingMethodDescription,
  getShippingMethodsAnalyticsData,
  isShippingMethodRequired,
  parsePoint
} from './utils';
import classNames from 'classnames';
import ShippingMethodSelect from './ShippingMethodSelect';
import {EVENT_INVALID_PARCEL_LOCKER_ADDRESS, EVENT_NO_SHIPPING_METHODS_ERROR} from '@Utils/events';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getShippingMethods} from '@Features/shippingMethods/shippingMethodsSelectors';
import {ParcelsProvider} from '@Components/parcelsProvider/ParcelsProvider';
import {updateOrderShippingMethod} from '@Features/configuration/configurationActions';
import ShippingPhoneNumberSection
  from '@Components/checkoutPages/agreements/phoneNumberSection/ShippingPhoneNumberSection';
import {getFormattedPrice} from '@Utils/format';
import DeliveryFormSection from './DeliveryFormSection';
import {validForm, isParcelLockerDataValid} from './validation';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {ELeading, ETextColor, ETextModifier, ETextTransform} from '@Components/common/text/types';
import {parseLanguage} from '@Utils/eventsUtils';
import {ECurrency, EShippingMethodsTypes} from '@Consts/apiGlobals';
import {EFlexDirection} from '@Components/common/flexBox/types';
import {getVariantDefaultCost} from '@Utils/activity/variant';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import {useScroll} from '@Utils/hooks/useScroll';

const ShippingMethods = ({
  activeView,
  onChangeView,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId
}) => {
  const facility = useSelector(getFacility);
  const shippingMethods = useSelector(getShippingMethods);
  const basketItems = useSelector(getBasketItems);
  const {shippingMethod: shippingMethodSelected} = useSelector(getConfiguration);
  const {items, id: viewId} = activeView;
  const description = getShippingMethodDescription(items, basketItems);
  const isShippingRequired = isShippingMethodRequired(items);
  const [selectedShippingMethodId, setSelectedShippingMethodId] = useState(shippingMethodSelected?.id || null);
  const [errors, setErrors] = useState({});
  const [parcelLockerPoint, setParcelLockerPoint] = useState(shippingMethodSelected);
  const [isMapVisible, setIsMapVisible] = useState(!shippingMethodSelected?.parcelLockerCode);
  const dispatch = useDispatch();
  const initialValues = getInitialFormValues(shippingMethodSelected);
  const [values, setValues] = useState(initialValues);
  const form = {
    values,
    errors: {...errors}
  };

  const defaultCost = getVariantDefaultCost(items[0].variant);
  const currency = defaultCost ? defaultCost.currency : ECurrency.PLN;
  const updatedShippingMethods = isShippingRequired ?
    shippingMethods :
    [{
      id: 0,
      name: locale.translate('digitalShippingMethod'),
      price: {amount: 0, currency},
      type: EShippingMethodsTypes.DIGITAL
    }, ...shippingMethods];
  const selectedMethod = useMemo(() =>
    updatedShippingMethods?.find(({id}) => id === selectedShippingMethodId),
  [selectedShippingMethodId]);
  const nextLabel = locale.translate('next');
  const submitButtonLabel = selectedMethod && Object.keys(selectedMethod?.price) ?
    nextLabel + ` (+${getFormattedPrice(selectedMethod?.price?.amount)} ${currency})` :
    nextLabel;
  const parcelLockerAddressRef = useRef(parcelLockerAddressRef);
  const {
    street: parcelLockerStreet,
    city: parcelLockerCity,
    postalCode: parcelLockerPostalCode
  } = {...parcelLockerPoint?.parcelLockerAddress};
  const {shippingMethodsString, itemsDataString} = getShippingMethodsAnalyticsData(shippingMethods, items);
  const shippingMethodsAnalyticsFullData = {
    facilityId: facility.id,
    facilityName: facility.name,
    items: itemsDataString,
    language: parseLanguage(locale.locale),
    shippingMethods: shippingMethodsString
  };
  const {scrollToElement} = useScroll();

  React.useEffect(() => {
    setErrors({});
  }, [selectedMethod]);

  React.useEffect(() => {
    scrollEffect();
  }, [isMapVisible]);

  React.useEffect(() => {
    if (shippingMethods === null) {
      pubsub.trigger(EVENT_NO_SHIPPING_METHODS_ERROR, shippingMethodsAnalyticsFullData);
    }
  }, []);

  React.useEffect(() => {
    if (requestedCheckoutPageId) {
      onChangeView(requestedCheckoutPageId);
    }
  }, [requestedCheckoutPageId]);

  function scrollEffect() {
    if (parcelLockerAddressRef?.current) {
      scrollToElement(parcelLockerAddressRef.current);
    }
  }

  function handleOnClosePage() {
    const isPageCloseRequest = true;

    return handleClickNext({isPageCloseRequest});
  }

  function handleShowMap() {
    setParcelLockerPoint(null);

    return setIsMapVisible(true);
  }

  const resetError = name => {
    setErrors({
      ...errors,
      [name]: null
    });
  };

  React.useEffect(() => {
    const keys = parcelLockerPoint ? Object.keys(parcelLockerPoint) : [];

    if (keys?.length) {
      resetError('parcelLocker');
    }
  }, [parcelLockerPoint]);

  const handleSetErrors = errors => {
    setErrors(errors);
  };

  const handleParcelLockerSelect = point => {
    setIsMapVisible(false);

    if (!isParcelLockerDataValid(point) && selectedMethod?.type === EShippingMethodsTypes.PARCEL_LOCKER) {
      pubsub.trigger(EVENT_INVALID_PARCEL_LOCKER_ADDRESS, shippingMethodsAnalyticsFullData);
      return setErrors({
        ...errors,
        'parcelLocker': locale.translate('parcelLockerPointError')
      });
    }
    const parsedPoint = parsePoint(point);

    return setParcelLockerPoint(parsedPoint);
  };

  const handleSetValue = (name, value) => setValues({
    ...values,
    [name]: value
  });

  const handleClickNext = ({isPageCloseRequest, requestedCheckoutPageId}) => {
    const isFormValid = validForm(selectedShippingMethodId, selectedMethod, parcelLockerPoint, form, handleSetErrors);

    if (!isFormValid) {
      return null;
    }

    const data = getDataByShippingMethodType({parcelLockerPoint, selectedMethod, values});

    dispatch(updateOrderShippingMethod(data));

    if (isPageCloseRequest) {
      return onPageClose();
    }

    if (requestedCheckoutPageId) {
      return onChangeView(requestedCheckoutPageId);
    }

    return onChangeView(viewId + 1);
  };

  const handleChangeMethod = method => {
    setSelectedShippingMethodId(method);
  };

  return (
    <FlexBox direction={EFlexDirection.COLUMN} className="dl-dotpay dl-dotpay__checkout-page">
      <div className="dl-dotpay__content">
        <div className="dl-mb-3 dl-dotpay__content--with-icon">
          <Text color={ETextColor.LIGHT} wrap>
            {locale.translate('forChosen')} {description}
          </Text>
          <div
            className={classNames(
              {
                'dl-dotpay--clickable dl-icon dl-icon--margin-correction dl-icon--arrow-up': fullCheckoutProgress
              })
            }
            onClick={handleOnClosePage}
          />
        </div>

        <Header className="dl-mb-1">
          {locale.translate('chooseShippingMethod')}
        </Header>

        {
          updatedShippingMethods ? (
            <ShippingMethodSelect
              shippingMethods={updatedShippingMethods}
              selectedShippingMethodId={selectedShippingMethodId}
              handleChangeMethod={handleChangeMethod}
              analyticsData={shippingMethodsAnalyticsFullData}
            />
          ) : null
        }

        {
          errors?.shippingMethodSelect && (
            <div className="dl-text--error" dl-test="error-text">
              {locale.translate('pleaseChooseOneOption')}
            </div>
          )
        }

        {
          (selectedMethod?.type === EShippingMethodsTypes.PARCEL_LOCKER && !isMapVisible) &&
            <div className="dl-mb-4" ref={parcelLockerAddressRef} >
              <Header className="dl-mb-2">
                {locale.translate('parcelLockerSelected')}
              </Header>
              <FlexBox direction={EFlexDirection.COLUMN}>
                {
                  !errors?.parcelLocker ? (
                    <FlexBox direction={EFlexDirection.COLUMN}>
                      <Text leading={ELeading.SMALL}>
                        {parcelLockerPoint?.parcelLockerCode}
                      </Text>
                      <Text leading={ELeading.SMALL}>
                        {parcelLockerStreet}
                      </Text>
                      <Text leading={ELeading.SMALL}>
                        {parcelLockerPostalCode} {parcelLockerCity}
                      </Text>
                    </FlexBox>
                  ) : (
                    <div className="dl-text--error">
                      {errors.parcelLocker}
                    </div>
                  )
                }
                <Text
                  transform={ETextTransform.UNDERLINE}
                  modifier={ETextModifier.CLICKABLE}
                  onClick={handleShowMap}
                >
                  {locale.translate('selectAnotherParcelLocker')}
                </Text>
              </FlexBox>
            </div>
        }

        {
          (selectedMethod?.type === EShippingMethodsTypes.PARCEL_LOCKER && isMapVisible) &&
            <div>
              <Header className="dl-mb-3">
                {locale.translate('selectParcelLocker')}
              </Header>
              <div className="dl-mb-4">
                <ParcelsProvider onSelect={handleParcelLockerSelect} />
                {
                  errors?.parcelLocker &&
                  <div className="dl-text--error">
                    {errors.parcelLocker}
                  </div>
                }
              </div>
            </div>
        }

        {
          selectedMethod?.type === EShippingMethodsTypes.COURIER &&
            <DeliveryFormSection
              form={form}
              setFieldValue={handleSetValue}
              resetError={resetError}
            />
        }

        {
          (
            selectedMethod?.type === EShippingMethodsTypes.PARCEL_LOCKER ||
            selectedMethod?.type === EShippingMethodsTypes.COURIER
          ) &&
            <ShippingPhoneNumberSection
              form={form}
              setFieldValue={handleSetValue}
              customHeader={locale.translate('enterDeliveryPhoneNumber')}
              resetError={resetError}
            />
        }

        <Button
          onClick={handleClickNext}
          variant="contained"
          color="success"
          fullWidth
          sx={{mt: 2}}
          endIcon={<ChevronRightIcon />}
        >
          {submitButtonLabel}
        </Button>
      </div>
    </FlexBox>
  );

};

export default ShippingMethods;
