import {EStorageKeys} from '@Consts/storageConsts';

const getStorage = () =>
  Object.values(EStorageKeys).reduce((acc, key) => {
    const storageValue = window.localStorage.getItem(key);

    if (!storageValue) {
      return acc;
    }

    return {...acc, [key]: JSON.parse(storageValue)};
  }, {});

export default getStorage;
