import {CookieConsents, updateConsentsEvent} from '@Hooks/useAmplitude';

declare global {
  interface Window {
    CookiebotCallback_OnLoad?: () => void;
    CookiebotCallback_OnAccept?: () => void;
    CookiebotCallback_OnDecline?: () => void;
    CookieConsent?: {
      consent: CookieConsents
    }
  }
}

export function handleCookiebot(getIframe: () => HTMLIFrameElement | undefined) {
  function onCookiebotChange(newConsents?: CookieConsents) {
    const iframe = getIframe()?.contentWindow;

    iframe?.postMessage({
      name: updateConsentsEvent,
      cookieConsents: {
        statistics: newConsents?.statistics
      }
    }, '*');
  }

  window.CookiebotCallback_OnLoad = function() {
    const newConsents = window?.CookieConsent?.consent;

    onCookiebotChange(newConsents);
  };

  window.CookiebotCallback_OnAccept = function() {
    const newConsents = window?.CookieConsent?.consent;

    onCookiebotChange(newConsents);
  };

  window.CookiebotCallback_OnDecline = function() {
    const newConsents = window?.CookieConsent?.consent;

    onCookiebotChange(newConsents);
  };
}
