import {isVariantMultiday} from '@Utils/activity/variant';
import {COLON_HOUR_FORMAT, DOT_DAY_FORMAT_REVERSED} from '@Utils/dayjs/const';
import {getTimezoneDate, getFormattedDate} from '@Utils/dayjs/dayjsUtils';
import locale from '@Utils/locale';

export const DATE_PLACEHOLDER = '--/--/----';
export const TIME_PLACEHOLDER = '--:--';

const MIN_VALID_TIME = '00:00';
const MAX_VALID_TIME = '23:59';

export const getNarrowedDateDescription = ({activity, selections}, timezone) => {
  const {admissionPolicy} = activity;
  const {validSince, validUntil} = selections;
  const {validSinceTime, validUntilTime} = admissionPolicy;
  const placeholder = '--/--/----';

  const validSinceTimeFormatted = getFormattedDate(
    getTimezoneDate(validSince, timezone, true),
    DOT_DAY_FORMAT_REVERSED
  );
  const validUntilTimeFormatted = getFormattedDate(
    getTimezoneDate(validUntil, timezone, true),
    DOT_DAY_FORMAT_REVERSED
  );

  const since = `${validSince ? validSinceTimeFormatted : placeholder}`;
  const sinceText = validSinceTime !== MIN_VALID_TIME ?
    `${since} ${locale.translate('hour')} ${validSinceTime}` : since;
  const until = `${validUntil ? validUntilTimeFormatted : placeholder}`;
  const untilText = validUntilTime !== MAX_VALID_TIME ?
    `${until} ${locale.translate('hour')} ${validUntilTime}` : until;

  return `${locale.translate('useDatetime')} ${locale.translate('betweenDates', {since: sinceText, until: untilText})}`;
};

export const getActivePreviewIndexes = (previews, activeView) => {
  if (!activeView) {
    return previews.map(({id}) => id);
  }
  const {items: activeViewItems} = activeView;

  if (!activeViewItems) {
    return [];
  }
  const itemUids = activeViewItems.map(({uid}) => uid);
  const activePreviews = previews.reduce((activePreviews, preview) => {
    const {items} = preview;

    const itemsMatch = items.find(({uid}) =>
      itemUids.find(itemUid => itemUid === uid)
    );

    if (itemsMatch) {
      activePreviews.push(preview.id);
    }

    return activePreviews;
  }, []);

  return activePreviews || [];
};

export const getAdditionsTotalQuantity = additions =>
  additions.reduce((totalAdditiions, additions) =>
    totalAdditiions += additions?.quantity, 0);

export const isQuantityValueDifferent = (additions, additionsState) =>
  getAdditionsTotalQuantity(additions) !== getAdditionsTotalQuantity(additionsState);

export const getAdditionsChangeStatus = (additions, additionsState) => {
  if (additions?.length !== additionsState?.length) {
    return true;
  }

  return isQuantityValueDifferent(additions, additionsState);
};

export const getFormattedDatetimePickerDate = ({selectedTime, dayOffer, timezone}) => {
  const {date, description} = selectedTime ? selectedTime : {date: null, description: null};
  const selectedDate = dayOffer?.originalDate || null; // YYYY-MM-DD format
  const dateFormatted = selectedDate ?
    getFormattedDate(getTimezoneDate(selectedDate, timezone, true), DOT_DAY_FORMAT_REVERSED) :
    DATE_PLACEHOLDER;
  const timeFormatted = date ?
    getFormattedDate(getTimezoneDate(date, timezone), COLON_HOUR_FORMAT) :
    TIME_PLACEHOLDER;

  const hourAbbr = locale.translate('hour');
  const dateOfUse = locale.translate('dateOfUse');
  const dateCopy = `${dateOfUse}: ${dateFormatted}, ${hourAbbr} ${timeFormatted}`;

  return {
    dateCopy,
    description
  };
};

export const getFormattedSinglePickDate = ({selectedDates, variant, timezone}) => {
  if (selectedDates?.length) {
    const {date: startDate} = selectedDates[0];
    const {date: endDate} = selectedDates[selectedDates?.length - 1];
    const since = getFormattedDate(getTimezoneDate(startDate, timezone, true), DOT_DAY_FORMAT_REVERSED);
    const until = getFormattedDate(getTimezoneDate(endDate, timezone, true), DOT_DAY_FORMAT_REVERSED);

    if (getTimezoneDate(startDate, timezone).isSame(getTimezoneDate(endDate, timezone))) {
      return locale.translate('realiseInDay', {date: since});
    }

    return `${locale.translate('useDatetime')} ${
      locale.translate('betweenDates', {since, until})
    }`;
  }

  if (!isVariantMultiday(variant)) {
    return `${locale.translate('dateOfUse')} : ${DATE_PLACEHOLDER}`;
  }

  return `${locale.translate('useDatetime')} ${
    locale.translate('betweenDates', {since: DATE_PLACEHOLDER, until: DATE_PLACEHOLDER})
  }`;
};
