/* eslint-disable react/no-unused-prop-types */
import React, {useEffect, useState} from 'react';
import locale from '@Utils/locale';
import NumberOfDaysSection from './NumberOfDaysSection';
import {isVariantMultiday} from '@Utils/activity/variant';
import AdmissionDatePicker from './AdmissionDatePicker';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchAdmissionDates, fetchDayOffers
} from '@Features/activityAdmissionDates/activityAdmissionDatesActions';
import {getFacility, getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import {
  validSelectedMonthAndYear
} from './admissionRealizationUtils';
import {EFlexDirection} from '@Components/common/flexBox/types';
import {isActivityWithDateTimes} from '@Utils/activity/activity';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import {
  EVENT_BUY_ADMISSION_DATES_COMPLETED
} from '@Utils/events';
import {COLON_HOUR_FORMAT} from '@Utils/dayjs/const';
import {ActiveView} from '@Components/checkout/types';
import {getCalendarValueMonthAndYear} from './utils';
import {useAdmissionRealization} from '../../../hooks/admissionRealization/useAdmissionRealization';
import {useAdmissionRealizationEvents} from '../../../hooks/admissionRealization/useAdmissionRealizationEvents';
import {useAdmissionDates} from '../../../hooks/admissionRealization/useAdmissionDates';
import {getOriginalDateMonthDate} from './admissionDatesUtils';
import {getTimezoneDate, getFormattedDate} from '@Utils/dayjs/dayjsUtils';
import AdmissionRealizationTopSection from './AdmissionRealizationTopSection';
import Alert from '@mui/material/Alert';
import AdmissionSummary from './AdmissionSummary';
import Box from '@mui/material/Box';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import gtm from '@Utils/gtm/core';

type Props = {
  activeView: ActiveView,
  onChangeView: (id: number) => void,
  onPageClose: () => void,
  description: string,
  fullCheckoutProgress: boolean,
  requestedCheckoutPageId: number | null
}

type HandleNextClick = {
  isPageCloseRequest?: boolean,
  requestedCheckoutPageId?: number
}

const AdmissionRealization = ({
  activeView,
  onChangeView,
  description,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId
}: Props) => {
  const timezone = useSelector(getFacilityTimezone)!;

  const {
    id,
    numberOfAdmissionDatesMax,
    activityId,
    passType,
    selectedTime,
    itemsUniqueVariantsIds,
    calendarValue,
    itemsVariants,
    selectedDates,
    canShowItemsLeft,
    items,
    type,
    selectedDate,
    variant,
    selectedDayOffer,
    selections
  } = useAdmissionRealization(activeView);
  const {
    dates,
    dayOffers,
    numberOfAvailableDays,
    onFetchAdmissionDates,
    fetchDayOfferDateTimes,
    showCalendarPrices,
    showAvailableSeatsForDay
  } = useAdmissionDates(
    items,
    selectedDates,
    canShowItemsLeft,
    type,
    selectedDayOffer
  );
  const facility = useSelector(getFacility)!;
  const dispatch = useDispatch();
  const {onlineGroupId} = useSelector(getConfiguration);
  const [error, setError] = useState(null);
  const [isSelectErrorComponent, setIsSelectErrorComponent] = useState(false);
  const {month: initialCalendarMonth, year: InitialCalendarYear} = getCalendarValueMonthAndYear(calendarValue);
  const [calendarMonth, setCalendarMonth] = useState<number>(initialCalendarMonth);
  const [calendarYear, setCalendarYear] = useState<number>(InitialCalendarYear);
  const isAdmissionSummary = !!selections.dates?.length || !!selections.time;
  const {
    sendAmplitudeEvent
  } = useAdmissionRealizationEvents({
    activeView,
    calendarMonth,
    dates,
    dayOffers,
    numberOfAvailableDays
  });
  const handleRemoveSelectError = () => setIsSelectErrorComponent(false);

  useEffect(() => {
    if (selectedDayOffer && calendarMonth === selectedDayOffer.date.month()) {
      fetchDayOfferDateTimes({
        activityId,
        activityVariantsIds: itemsUniqueVariantsIds,
        activeViewItems: items,
        itemsVariants,
        selectedDates,
        canShowItemsLeft,
        activeViewType: type,
        calendarDate: getOriginalDateMonthDate(selectedDayOffer.originalDate),
        calendarMonth: selectedDayOffer.date.month(),
        calendarYear: selectedDayOffer.date.year(),
        numberOfAdmissionDatesMax: 1
      });
    }
  }, [selectedDayOffer, calendarMonth]);

  async function onMonthChange({month, year}: {month: number, year: number}) {
    if (validSelectedMonthAndYear(month, year) && facility && numberOfAdmissionDatesMax) {
      onFetchAdmissionDates();

      if (isActivityWithDateTimes(passType)) {
        await dispatch(fetchDayOffers({
          activityId,
          calendarMonth: month,
          calendarYear: year,
          numberOfAdmissionDatesMax,
          activityVariantsIds: itemsUniqueVariantsIds
        }));
      } else {
        await dispatch(fetchAdmissionDates({
          activityId,
          calendarMonth: month,
          calendarYear: year,
          numberOfAdmissionDatesMax,
          activityVariantsIds: itemsUniqueVariantsIds,
          activeViewItems: items,
          itemsVariants,
          selectedDates,
          canShowItemsLeft,
          activeViewType: type
        }));
      }

      if (month !== calendarMonth) {
        setCalendarMonth(month);
      }

      if (year !== calendarYear) {
        setCalendarYear(year);
      }
    }

    if (error) {
      setError(null);
    }
  }

  useEffect(() => {
    if (requestedCheckoutPageId) {
      onChangeView(requestedCheckoutPageId);
    }
  }, [requestedCheckoutPageId]);

  function handleOnClosePage() {
    const isPageCloseRequest = true;

    return handleNextClick({isPageCloseRequest});
  }

  function handleNextClick({isPageCloseRequest, requestedCheckoutPageId}: HandleNextClick) {
    const sendCompletedEvent = (error?: string) => {
      sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_COMPLETED, {
        admissionDateDay: selectedDate?.date,
        numberOfSelectedDates: selectedDates?.length,
        admissionDateHour: isActivityWithDateTimes(passType) && selectedTime?.date ?
          getFormattedDate(getTimezoneDate(selectedTime?.date, timezone), COLON_HOUR_FORMAT) : null,
        result: error ? 'error' : 'ok',
        error
      });
    };

    if (requestedCheckoutPageId) {
      sendCompletedEvent();
      return onChangeView(requestedCheckoutPageId);
    }

    if (!selectedDate && !selectedDayOffer) {
      const error = locale.translate('pleaseSelectDate');

      sendCompletedEvent(error);
      return setError(error);
    }

    if (type === ECheckoutViewsTypes.TIMES && !selectedTime) {
      const error = locale.translate('pleaseSelectTime');

      if (dates && dates[0].group.length > 8) {
        setIsSelectErrorComponent(true);
      } else {
        setIsSelectErrorComponent(false);
      }
      sendCompletedEvent(error);
      return setError(error);
    }

    setError(null);

    if (isPageCloseRequest) {
      return onPageClose();
    }

    gtm.viewAdmissionsCompleted(facility, items, onlineGroupId);

    sendCompletedEvent();
    return onChangeView(id + 1);
  }

  return (
    <FlexBox direction={EFlexDirection.COLUMN} className="dl-dotpay dl-dotpay__checkout-page">
      <AdmissionRealizationTopSection
        description={description}
        fullCheckoutProgress={fullCheckoutProgress}
        handleOnClosePage={handleOnClosePage}
      />
      <Box>

        {
          (type === ECheckoutViewsTypes.DATES && isVariantMultiday(variant)) &&
          <NumberOfDaysSection
            basketItems={items}
            variant={variant}
          />
        }

        <AdmissionDatePicker
          dayOffers={dayOffers}
          datesGroupedByDay={dates}
          activeView={activeView}
          setError={setError}
          loading={!dates}
          calendarMonth={calendarMonth}
          calendarYear={calendarYear}
          onMonthChange={onMonthChange}
          sendAmplitudeEvent={sendAmplitudeEvent}
          selections={selections}
          showCalendarPrices={showCalendarPrices}
          showAvailableSeatsForDay={showAvailableSeatsForDay}
          isSelectErrorComponent={isSelectErrorComponent}
          handleRemoveSelectError={handleRemoveSelectError}
        />

        {isAdmissionSummary && (
          <AdmissionSummary selections={selections} passType={passType} />
        )}

        {
          error && !isSelectErrorComponent &&
            <Alert severity="error" sx={{fontSize: '14px', mb: 2}}>
              {error}
            </Alert>
        }

      </Box>

      <div>
        <Button
          onClick={() => handleNextClick({})}
          variant="contained"
          color="success"
          fullWidth
          endIcon={<ChevronRightIcon />}
          sx={{my: 2, fontSize: '15px'}}
        >
          {locale.translate('next')}
        </Button>
      </div>
    </FlexBox>
  );
};

export default AdmissionRealization;
