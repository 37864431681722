import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Stack from '@mui/material/Stack';
import PhonePrefixField from '@Components/checkout/field/PhonePrefixField';
import PhoneNumberField from '@Components/checkout/field/PhoneNumberField';
import MarkedHeader from '@Components/headers/MarkedHeader';
import locale from '@Utils/locale';

type Props = {
  widgetIsBusiness?: boolean;
}

const PhoneSection = ({widgetIsBusiness}: Props) => (
  <>
    {
      widgetIsBusiness && (
        <MarkedHeader text={locale.translate('contactPhoneNumber')} />
      )
    }
    <Stack direction="row" spacing={2}>
      <PhonePrefixField />
      <PhoneNumberField />
    </Stack>
  </>
);

export default PhoneSection;
