import React from 'react';
import {useSummaryData} from '../../../hooks/useSummaryData';
import {useSelector} from 'react-redux';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {EVENT_BUY_CROSS_SELL_CLICKED, EVENT_BUY_CROSS_SELL_LOADED} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import Box from '@mui/material/Box';
import {getCrossSellOfferAffiliationHash} from './utils';
import {SxProps, Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import {ICrossSellOffer} from '@Consts/apiGlobals';
import CarouselItem from '@Components/carousel/CarouselItem';

const formContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 450
};

const imageStyles: SxProps<Theme> = {
  cursor: 'pointer',
  objectFit: 'contain',
  maxHeight: {xs: 160, md: 235},
  width: 1,
  mb: 2
};

interface ICrossSellOfferProps {
  crossSellOffer: ICrossSellOffer
}

const CrossSellOffer = ({crossSellOffer}: ICrossSellOfferProps) => {
  const {latestOrderStorageData, isPreviewMode} = useSummaryData();
  const facility = useSelector(getFacility);
  const {facilityId} = useSelector(getConfiguration);
  const {name: facilityName, company} = {...facility};
  const {industry} = {...company};
  const {bannerUrl, redirectUrl, description} = crossSellOffer;

  const handleSendEvent = (eventName: string) => {
    const {isEventsSent} = latestOrderStorageData;

    pubsub.trigger(eventName, {
      facilityId,
      facilityName,
      language: parseLanguage(locale.locale),
      industry,
      affiliationHash: getCrossSellOfferAffiliationHash(redirectUrl),
      isFirstLoading: !isEventsSent
    });
  };

  function handleRedirect() {
    if (redirectUrl) {
      handleSendEvent(EVENT_BUY_CROSS_SELL_CLICKED);
      window.open(redirectUrl);
      window.focus();
    }
  }

  React.useEffect(() => {
    handleSendEvent(EVENT_BUY_CROSS_SELL_LOADED);
  }, []);

  return (
    <CarouselItem
      styles={{
        border: '1px solid',
        borderColor: 'primary.main',
        p: {xs: 2, md: 3}
      }}
    >
      <Box sx={formContentStyles}>
        <Box
          onClick={handleRedirect}
          component="img"
          src={bannerUrl}
          sx={imageStyles}
        />

        <Typography sx={{mb: 2, fontWeight: 500, textAlign: 'center'}} variant="h5">
          {locale.translate('thisMayInterestYou')}
        </Typography>

        <Typography sx={{mb: 3, textAlign: 'center', lineHeight: 1.57, color: 'text.secondary'}} variant="body2">
          {description}
        </Typography>
        <FollowCursorTooltip
          title={locale.translate('actionDisabledInPreviewMode')}
          enable={isPreviewMode}
          styles={{width: {xs: 1, sm: 'auto'}}}
        >
          <Button
            sx={{width: 1, mb: 5}}
            variant="contained"
            color="inherit"
            disabled={isPreviewMode}
            onClick={handleRedirect}
          >
            {locale.translate('checkItOut')}
          </Button>
        </FollowCursorTooltip>
      </Box>
    </CarouselItem>

  );
};

export default CrossSellOffer;
