import React, {useState, useEffect} from 'react';
import CounterInput from '@Components/activities/activity/counterPicker/CounterInput';
import locale from '@Utils/locale';
import {useDispatch} from 'react-redux';
import {blockNextStepWith, unblockNextStepWith} from '@Features/basket/basketActions';

type Props = {
    additionId: number,
    quantity: number,
    maxQuantity: number,
    handleQuantityChange(quantity: number): void,
    handleIncrease: VoidFunction,
    handleDecrease: VoidFunction,
    disableReason?: string,
}

export const AdditionCounterPicker = ({
  additionId,
  quantity,
  maxQuantity,
  disableReason,
  handleQuantityChange,
  handleIncrease,
  handleDecrease
}: Props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<number | ''>(quantity);

  const isMaxValueError = typeof value === 'number' && value > maxQuantity;
  const handleBlockNextStep = () => {
    if (isMaxValueError) {
      return dispatch(blockNextStepWith(additionId));
    }

    dispatch(unblockNextStepWith(additionId));
  };

  const handleChange = (newValue: number | '') => setValue(newValue);
  const handleBlur = () => handleQuantityChange(typeof value === 'number' ? value : 0);

  useEffect(() => {
    setValue(quantity);

    return () => {
      if (typeof value === 'string') {
        handleQuantityChange(0);
      }
    };
  }, [quantity]);

  useEffect(() => {
    handleBlockNextStep();
  }, [isMaxValueError]);

  return (
    <CounterInput
      value={value}
      onIncrease={handleIncrease}
      disableIncreaseReason={disableReason}
      onDecrease={handleDecrease}
      onChange={handleChange}
      onBlur={handleBlur}
      textFieldAriaLabel="numberOfAdditions"
      invalidFieldMessage={
        isMaxValueError && locale.translate('maxValueError', {value: maxQuantity})
      }
    />
  );
};
