import {IBasketItem} from '@Consts/types';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getBasketValue} from '@Utils/basket';
import {useSelector} from 'react-redux';
import {getWidgetType} from '@Features/configuration/configurationSelectors';
import {isWidgetOnlineClient, isWidgetOnlineClientPreview} from '@Utils/widgetType';

export type EnabledPaymentMethods = {
  isSecureFormBlikEnabled: boolean;
  isCardEnabled: boolean;
};

export const usePaymentMethodsEnabled = (): EnabledPaymentMethods => {
  const facility = useSelector(getFacility);

  if (!facility) {
    return {
      isSecureFormBlikEnabled: false,
      isCardEnabled: false
    };
  }

  const {isPaymentProviderBlikEnabled, isPaymentProviderCardsEnabled} = facility.company;
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const widgetType = useSelector(getWidgetType);
  const isWidgetOnlineType = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);
  const basketPriceValue = getBasketValue(basketItems, true);
  const isWidgetValid = isWidgetOnlineType && basketPriceValue > 0;

  return {
    isSecureFormBlikEnabled: isPaymentProviderBlikEnabled && isWidgetValid,
    isCardEnabled: isPaymentProviderCardsEnabled && isWidgetValid
  };
};
