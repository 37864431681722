import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography, {TypographyOwnProps} from '@mui/material/Typography';
import {SxProps, Theme} from '@mui/material/styles';

type Props = {
  text: string;
  variant?: TypographyOwnProps['variant'];
  sectionStyles?: SxProps<Theme>;
}

const MarkedHeader = ({text, variant = 'h6', sectionStyles}: Props) => (
  <Stack direction="row" spacing={1} sx={{alignItems: 'center', mb: 2, ...sectionStyles}}>
    <Box sx={{width: 6, height: 20, borderRadius: 0.5, backgroundColor: 'success.main'}} />
    <Typography variant={variant} sx={{fontSize: '20px', color: 'text.primary'}}>
      {text}
    </Typography>
  </Stack>
);

export default MarkedHeader;
