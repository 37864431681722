import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch} from 'react-redux';
import locale from '@Utils/locale';
import {findSelectedAdditionQuantity, isAdditionsLimitReached, makeAdditionsSelections} from './additionsUtils';
import FlexBox from '@Components/common/flexBox/FlexBox';
import Tooltip from '@Components/common/tooltip/Tooltip';
import {EAlignItems, EFlexWrap, EJustifyContent} from '@Components/common/flexBox/types';
import Button from '@mui/material/Button';
import {counterPickerButtonStyles} from '@Utils/componentStyles';
import {AdditionCounterPicker} from './AdditionCounterPicker';
import {IAddition} from '@Consts/apiGlobals';
import {IBasketItem} from '@Consts/types';

type Props = {
  basketItem: IBasketItem,
  addition: IAddition,
  numberOfSeats: number
}

const AdditionsCounter = ({basketItem, addition, numberOfSeats}: Props) => {
  const {id: additionId, maxNumberPerTicket, isPricePerParticipant} = addition;
  const {selections: {additions}} = basketItem;
  const additionQuantity: number = findSelectedAdditionQuantity(additions, additionId);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(additionQuantity);
  const disableReason = getPickerDisableReason();

  function getPickerDisableReason() {
    if (isPricePerParticipant && isAdditionsLimitReached(quantity, numberOfSeats, maxNumberPerTicket)) {
      return locale.translate('additionsLimitReached');
    }

    if (quantity >= maxNumberPerTicket) {
      return locale.translate('additionsLimitReached');
    }

    return false;
  }

  function handleChange(value: number) {
    setQuantity(value);
  }

  function handleAddToBasket() {
    if (!disableReason) {
      handleChange(quantity + 1);
    }
  }

  function handleRemoveFromBasket() {
    if (quantity > 0) {
      handleChange(quantity - 1);
    }
  }

  React.useEffect(() => {
    dispatch(updateBasketItemsSelections(
      [basketItem],
      makeAdditionsSelections(additions, additionId, quantity)
    ));
  }, [quantity]);

  return (
    <div className="dl-relative">
      {
        quantity ? (
          <FlexBox
            justify={EJustifyContent.SPACE_BETWEEN}
            align={EAlignItems.CENTER}
            wrap={EFlexWrap.NOWRAP}
          >
            <AdditionCounterPicker
              additionId={additionId}
              quantity={quantity}
              maxQuantity={maxNumberPerTicket}
              handleDecrease={handleRemoveFromBasket}
              handleIncrease={handleAddToBasket}
              handleQuantityChange={handleChange}
              disableReason={disableReason}
            />
          </FlexBox>
        ) : (
          <Tooltip text={disableReason}>
            <Button
              onClick={handleAddToBasket}
              disabled={disableReason}
              variant="outlined"
              sx={{...counterPickerButtonStyles, width: '158px'}}
            >
              {locale.translate('add')}
            </Button>
          </Tooltip>
        )
      }
    </div>
  );
};

export default AdditionsCounter;
