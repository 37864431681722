import {
  SINGLE,
  SINGLE_PICK_DATE,
  SINGLE_PICK_DATETIME,
  SINGLE_GROUP_EVENT,
  SINGLE_HOTEL_NARROWED,
  MULTIPLE,
  MULTIPLE_ACTIVATION,
  MULTIPLE_HOTEL_NARROWED
} from '@Consts/activityPassType';
import locale from '../locale';
import {getVariantDefaultCost} from './variant';

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE
 */
export const isActivitySingle = passType => passType === SINGLE;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE_PICK_DATE
 */
export const isActivitySinglePickDate = passType => passType === SINGLE_PICK_DATE;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE_PICK_DATETIME
 */
export const isActivitySinglePickDatetime = passType => passType === SINGLE_PICK_DATETIME;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE_GROUP_EVENT
 */
export const isActivitySingleGroupEvent = passType => passType === SINGLE_GROUP_EVENT;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE_PICK_DATE or SINGLE_PICK_DATETIME
 */
export const isActivitySingleDate = passType =>
  isActivitySinglePickDate(passType) || isActivitySinglePickDatetime(passType);

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE_GROUP_EVENT or SINGLE_PICK_DATETIME
 */
export const isActivityWithDateTimes = passType =>
  isActivitySingleGroupEvent(passType) || isActivitySinglePickDatetime(passType);

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity with dates array (type SINGLE_PICK_DATE, SINGLE_PICK_DATETIME or SINGLE_GROUP_EVENT)
 */
export const isActivityWithDates = passType =>
  isActivitySingleDate(passType) || isActivitySingleGroupEvent(passType);

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type MULTIPLE
 */
export const isActivityMultiple = passType => passType === MULTIPLE;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type MULTIPLE_ACTIVATION
 */
export const isActivityMultipleActivation = passType => passType === MULTIPLE_ACTIVATION;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE_HOTEL_NARROWED
 */
export const isActivitySingleHotelNarrowed = passType => passType === SINGLE_HOTEL_NARROWED;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type MULTIPLE_HOTEL_NARROWED
 */
export const isActivityMultipleHotelNarrowed = passType => passType === MULTIPLE_HOTEL_NARROWED;

/**
 * @param {string} passType - activity type
 * @return {boolean} is activity type SINGLE_HOTEL_NARROWED or MULTIPLE_HOTEL_NARROWED
 */
export const isActivityHotelNarrowed = passType =>
  isActivitySingleHotelNarrowed(passType) || isActivityMultipleHotelNarrowed(passType);

/**
 * Checks if activity with dates have not empty dates array
 * @param {Activity} activity - activity object
 * @return {boolean} is dates array empty
 */
export const areActivityDatesInvalid = ({passType, admissionPolicy}) => (
  isActivityWithDates(passType) ? !admissionPolicy.firstAdmissionDate : false
);

/**
 * Returns parsed admission duration: array i shape of [hours, minutes] or null
 * @param {number} durationInMinutes - admission duration in minutes
 * @return {Array | null} parsed duration to the hours and miutes
 */
export const getParsedAdmissionDuration = durationInMinutes => {
  if (durationInMinutes !== -1 && durationInMinutes) {
    const HOUR = 60;
    const hours = Math.floor(durationInMinutes / HOUR);
    const minutes = durationInMinutes % HOUR;
    const parsed = [];

    if (hours) {
      parsed.push(`${hours} ${locale.translate('hours')}`);
    }

    if (minutes) {
      parsed.push(`${minutes} ${locale.translate('minutes')}`);
    }

    return parsed.length ? parsed : null;
  }

  return null;
};

/**
 * Check if activity have different prices
 * @param {Variant[]} variants - array of activity variants
 * @return {boolean} if activity have different prices
 */
export const isActivityWithDifferentPrices = variants => {
  const prices = variants
    .reduce((prices, {cost, datesCostMin, datesCostMax}) => {
      if (datesCostMin?.price && datesCostMax?.price) {
        if (datesCostMin.price === datesCostMax.price) {
          prices = prices.concat(datesCostMin.price);

        } else if (datesCostMin.price < datesCostMax.price) {
          prices = prices.concat(datesCostMin.price, datesCostMax.price);
        }
      }

      if (cost) {
        prices.push(cost.price);
      }

      return prices;
    }, []);

  return new Set([...prices]).size > 1;
};

export const hasActivityValidPrice = variants =>
  variants.every(({cost, datesCostMin, datesCostMax}) => cost || datesCostMin || datesCostMax);

/**
 * Returns activity variant with lowest cost
 * @param {Variant[]} variants - activiy variant
 * @return {Variant} activity variant
 */
export const getActivityLowestPriceVariant = variants => (
  variants.length ?
    variants.reduce((lowestPriceVariant, variant) => {
      const lowestCost = getVariantDefaultCost(lowestPriceVariant);
      const nextCost = getVariantDefaultCost(variant);

      if (nextCost?.price <= 3) {
        if (lowestCost?.price <= 3 && nextCost?.price < lowestCost?.price) {
          return variant;
        }

        return lowestPriceVariant;
      }

      if (lowestCost?.price <= 3) {
        return variant;
      }

      if (nextCost?.price < lowestCost?.price) {
        return variant;
      }

      return lowestPriceVariant;
    }, variants[0]) : []
);

/**
 * Checks if activity should show variant pickers
 * @param {Variant[]} variants - array of activity variants
 * @return {Boolean} should show variant pickers (multiple variants or single variant with name)
 */
export const shouldShowVariantsPickers = variants =>
  variants
    .map(variant => variant.name)
    .filter(name => Boolean(name))
    .length > 1;

/**
 * Returns admission dates limit
 * @param {AdmissionDate[]} dates - array of admission dates
 * @return {number} biggest difference between limit and used from all dates
 */
export const getAdmissionDatesLimit = dates =>
  [...dates]
    .map(({limit, used}) => limit - used)
    .sort((firstDiff, secondDiff) => secondDiff - firstDiff)[0];

/**
 * Returns sale policy dates limit
 * @param {SalePolicy} salePolicy - sale policy object
 * @return {number | null} difference between limit and usesCount
 */
export const getActivitySalePolicyLimit = ({limit, usesCount}) => {
  const difference = limit - usesCount;

  return !limit || difference <= 0 ? null : difference;
};

/**
 * Returns activity limit
 * @param {Activity} activity - activity object
 * @return {number | null} activity limit
 */
export const getActivityLimit = ({salePolicy}) =>
  getActivitySalePolicyLimit(salePolicy);

/**
 * Checks if activity limit is reached based on selections number
 * @param {Activity} activity - activity object
 * @param {number} selectionsNumber - number of selections in basket
 * @return {boolean} activity limit reached
 */
export const isActivityLimitReached = (activity, selectionsNumber) => {
  const limit = getActivityLimit(activity);

  return limit && limit - selectionsNumber <= 0;
};

/**
 * Returns activity addition
 * @param {Addition[]} additions - array of activity additions
 * @param {number} additionId - addition id
 * @return {Addition} activity addition
 */
export const getAdditionById = (additions, additionId) =>
  additions.find(({id}) => id === additionId) || null;

/**
 * Returns activity question
 * @param {Question[]} questions - array of activity questions
 * @param {number} questionId - question id
 * @return {Question} activity question
 */
export const getQuestionById = (questions, questionId) =>
  questions.find(({id}) => id === questionId) || null;
