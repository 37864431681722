/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'Der ausgewählte Bereich enthält nicht verfügbare Begriffe',
  'PLN': 'PLN',
  'EUR': 'EUR',
  'PLNtestB': 'zł',
  'EURtestB': '€',
  'validPostalCodeLength': 'Geben Sie eine gültige Postleitzahl ein (z. B. 00-000)',
  'minLength': 'Minimale Anzahl von Zeichen: %{length}',
  'maxLength': 'Maximale Anzahl von Zeichen: %{length}',
  'parcelLockerSelected': 'Ausgewähltes Paketfach:',
  'selectParcelLocker': 'Paketfach auswählen',
  'selectAnotherParcelLocker': 'Wählen Sie ein anderes Paketfach',
  'parcelLockerPointError': 'Die Adresse des Paketfachs konnte nicht korrekt ermittelt werden',
  'enterDeliveryPhoneNumber': 'Geben Sie die Telefonnummer für die Zustellung ein',
  'onlineMethodsDescription': 'Für andere Dienste, elektronische Zustellung.',
  'shippingMethodRequired': 'Bevor Sie mit der Zahlung fortfahren, müssen Sie eine Versandart auswählen.',
  'chooseShippingMethod': 'Versandart wählen',
  'generateWithAmount': 'Erzeugen (%{amountToPay})',
  'additionsHeader': 'Zugänge: %{activityName}',
  'additionsLimitReached': 'Das Limit für Zugänge wurde erreicht',
  'perPersonNumber': '%{number} pers. x',
  'selectedDayIsNotAvailable': 'Datum %{date} ist nicht verfügbar',
  'selectedDateIsNotAvailable': 'Das Datum %{date} ist nicht verfügbar',
  'errorOccurredWhileFetchingOrder': 'Wir können Ihre Bestellung nicht laden. Wenn Sie die Zahlung vornehmen, überprüfen Sie bitte Ihre Mailbox.',
  'priceTooHigh': 'Der Wert der ausgewählten Dienstleistungen ist zu hoch. Es ist nicht möglich, mit der Zahlung fortzufahren.',
  'invalidNip': 'Die Mehrwertsteuernummer ist falsch',
  'orderCreationError': 'Fehler bei der Erstellung der Bestellung - versuchen Sie es erneut',
  'activitiesFetchError': 'Aktivitäten der Einrichtung können nicht geladen werden',
  'chooseLimitReached': 'Limit der ausgewählten Dienste erreicht',
  'localTours': 'Lokale Touren',
  'clauseAcceptanceRequired': 'Akzeptanz der Klausel erforderlich',
  'invalidPostalCode': 'Ungültige Postleitzahl',
  'invalidNIPLength': 'Firmen-NIP hat ungültige Länge',
  'invalidPhone': 'Ungültige Telefonnummer',
  'invalidEmail': 'Ungültige E-Mail Adresse',
  'invalidFormatError': 'Unzulässiges Format',
  'toLongError': 'Maximale Anzahl von Zeichen ist 30',
  'toShortError': 'Minimale Anzahl von Zeichen ist 3',
  'invalidTypeError': 'Ungültiger Wert',
  'missingFieldError': 'Wert aus diesem Feld kann nicht extrahiert werden',
  'unknownError': 'Unbekannter Fehler',
  'errorScriptNotFound': 'Fehler - Ihr Browser wird nicht unterstützt oder die Shop-Konfiguration ist ungültig',
  'addressRequired': 'Adresse erforderlich',
  'regulationAcceptRequired': 'Akzeptanz der Bedingungen und Datenschutzrichtlinien erforderlich',
  'regulationsAcceptRequired': 'Akzeptanz der Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie erforderlich',
  'phoneRequired': 'Telefonnummer erforderlich',
  'lastNameRequired': 'Nachname erforderlich',
  'firstNameRequired': 'Vorname erforderlich',
  'emailRequired': 'E-Mail Adresse erforderlich',
  'answerRequired': 'Antwort erforderlich',
  'skippTheLine': 'Überspringen Sie die Ticketzeile',
  'downloadInPreviewIsDisabled': 'Im Vorschaumodus wird die Bestellung per E-Mail verschickt und kann nicht aus dieser Ansicht heruntergeladen werden',
  'noValidDatesRange': 'Kein gültiger Datumsbereich verfügbar',
  'chooseNumberOfDays': 'Anzahl der Tage wählen',
  'day': 'Tag',
  'perPersonAbbr': 'pp',
  'maxNumberOfParticipants': 'Maximale Anzahl der Teilnehmer ist %{number}',
  'minNumberOfParticipants': 'Mindestteilnehmerzahl ist %{number}',
  'enterNumberOfParticipants': 'Anzahl der Teilnehmer eingeben',
  'numberOfParticipants': 'Anzahl der Teilnehmer',
  'numberOfParticipantsBetween': 'Anzahl der Teilnehmer: von %{min} bis %{max}',
  'generate': 'Erzeugen Sie',
  'seats': 'Stk.',
  'attention': 'Achtung!',
  'forSinglePerson': 'für 1 Person',
  'chooseAdditions': 'Zusätze wählen',
  'pleaseChooseOneOption': 'Die Auswahl der einen Option ist erforderlich',
  'or': 'oder',
  'addToAppleWallet': 'Zu Apple Wallet hinzufügen',
  'realiseOrder': 'Bestellung ausführen',
  'generatingPdf': 'PDF wird generiert...',
  'transactionCompletedSuccessfully': 'Transaktion erfolgreich abgeschlossen!',
  'immediatelyProceedThroughPayment': 'Sobald die Zahlung bestätigt ist, werden die Tickets unten geladen und an %p gesendet',
  'optionally': 'wahlweise',
  'shopOnline': 'Online-Geschäft',
  'orderOnline': 'Online bestellen',
  'discountsAreDisabledInPreview': 'Rabatte sind in der Vorschau deaktiviert',
  'unsuccessfullyOrderedSending': 'Das Senden der Auftragsbestätigung an den %{email} war nicht erfolgreich. Versuchen Sie es später noch einmal oder senden Sie eine Nachricht an die pomoc@droplabs.pl',
  'successfullyOrderedSending': 'Erfolgreich gesendete Bestätigung an den %{email}',
  'requiredField': 'Feld erforderlich',
  'forSendingAcceptTos': 'Für den Versand akzeptiert der Kunde',
  'agreementFormWithClause': 'Vertragsformular mit Informationsklausel',
  'send': 'Senden',
  'downloadToPrint': 'Herunterladen und ausdrucken',
  'clientEmail': 'E-Mail Adresse des Kunden',
  'chooseSendingConfirmationMethod': 'Fertig! Wählen Sie, wie Sie dem Kunden eine Bestätigung zukommen lassen möchten',
  'orderPreviewInfo': 'Die Musterbestellung wurde an die E-Mail Adresse gesendet: %p. Wenn Sie die Nachricht nicht innerhalb weniger Minuten nach der Zahlung sehen, überprüfen Sie Ihren Spam-Ordner. Wenn das nicht funktioniert, geben Sie "Droplabs" in die Suchmaschine Ihres E-Mail-Programms ein.',
  'exceededTimeout': 'Überschreitung der Wartezeit für die Zahlung',
  'buyerWillSettleWithYou': 'Die Person, die die Dienste des %p in Anspruch nehmen wird, rechnet mit Ihnen ab.',
  'youCanPrintTickets': 'Nach dem Kauf können Sie Ihre gebrauchsfertigen Tickets ausdrucken. Die Tickets haben keinen Preis',
  'onlinePaymentForOrder': 'Sie bezahlen die Bestellung online. %p stellt für Ihr Unternehmen eine Rechnung für einen bestimmten Zeitraum aus, in der Regel für den gesamten Monat zusammen.',
  'youWillPayForOrderBasedOnInvoice': 'Sie bezahlen die Bestellung auf der Grundlage einer Rechnung, die von %p für Ihr Unternehmen für einen bestimmten Zeitraum, in der Regel für den gesamten Monat, ausgestellt wird.',
  'wholeAmountWillBeChargedForUSed': 'Der volle Betrag wird von %p nur für die genutzten Tickets in Rechnung gestellt. %p kann eine zusätzliche Gebühr erheben, auch für nicht genutzte Fahrkarten.',
  'youCanPrintReservationConfirmation': 'Nach der Buchung können Sie die Bestätigung sofort ausdrucken.',
  'paymentWillBeChargedOnSite': 'Der Gesamtbetrag der Bestellung muss vor Ort während des Dienstes bezahlt werden.',
  'missingFacilityId': 'Ungültige Konfiguration.',
  'cannotLoadFacility': 'Anlage %p kann nicht abgerufen werden',
  'add': 'Hinzufügen',
  'choose': 'Anzeigen',
  'choosing': 'Wählen Sie',
  'hide': 'Ausblenden',
  'expand': 'Erweitern Sie',
  'menuDetails': 'Einzelheiten',
  'menuSummary': 'Bezahlung',
  'menuSummaryThanks': 'Vielen Dank für Ihre Bestellung!',
  'noActivitiesInSales': 'Nichts zur Auswahl. Besuchen Sie uns bald wieder!',
  'hours': 'Stunden',
  'minutes': 'min',
  'goToTheNextStep': 'Fahren Sie fort',
  'sellerOfActivities': '%{name} ist der Verkäufer der Dienstleistungen.',
  'ifYouNeedHelpAssociatedWithOffer': 'Haben Sie Fragen zu diesem Angebot? Schreiben Sie an %p.',
  'saleSupportedByDroplabs': 'Droplabs - e-Commerce, Verkaufs- und Vertriebssystem für Tickets, Gutscheine, Pässe und Dienstleistungen.',
  'ifYouNeedTechnicalSupport': 'Benötigen Sie technische Unterstützung? Kontaktieren Sie uns unter %p',
  'activityAdmissionDateLabel': 'Eintrittsdatum: %{name}',
  'chooseDayOfUseActivities': 'Zulassungsdatum auswählen',
  'chooseTimeOfUseActivities': 'Einlasszeitpunkt auswählen',
  'chooseNarrowedTimeOfUseActivities': 'Eingrenzung des Aufnahmedatums wählen',
  'chooseTime': 'Wählen Sie eine Stunde',
  'chooseEmailForSendingTickets': 'Wie lautet Ihre E-Mail Adresse?',
  'email': 'E-Mail Adresse',
  'name': 'Name',
  'buyersName': 'Name des Käufers',
  'surname': 'Nachname',
  'buyersSurname': 'Nachname des Käufers',
  'goToThePayment': 'Zahlung vornehmen',
  'orderFreeTickets': 'Bestellung',
  'buyWithoutPayment': 'Kaufen und später bezahlen',
  'businessTicketsHaveBeenSentIntoEmail': 'Das Ticket wurde an die Adresse gesendet: %p |||| Die Fahrkarten wurden an die Adresse gesendet: %p',
  'checkSpamInCaseOfNotReceivingEmail': 'Wenn Sie einige Minuten lang keine E-Mail von uns sehen, überprüfen Sie bitte Ihren Spam-Ordner oder geben Sie Droplabs in die Suchleiste Ihres Postfachs ein.',
  'buyAnotherActivities': 'Weitere Aktivitäten bestellen',
  'noFixedDatesForChosenActivities': 'Leider sind keine freien Termine für die gewählten Dienstleistungen verfügbar.',
  'forChosen': 'Gilt für:',
  'chooseFixedDateBeforePayment': 'Bitte wählen Sie das Aufnahmedatum, bevor Sie fortfahren.',
  'chooseFixedDatetimeBeforePayment': 'Bitte wählen Sie die Aufnahmezeit für das gewählte Datum, bevor Sie fortfahren.',
  'noFixedDatesForChosenPeriod': 'Es gibt keine verfügbaren Aufnahmedaten für den gewählten Zeitraum.',
  'noFixedDatesOnDay': '%{date} ist nicht verfügbar.',
  'noFixedDatesBetweenDates': 'Keine Termine verfügbar zwischen %{since} und %{until}.',
  'noFixedDatesBeforeDate': 'Keine Termine vor %{date} verfügbar.',
  'noFixedDatesAfterDate': 'Keine Termine nach %{date} verfügbar.',
  'next': 'Nächste',
  'back': 'Zurück',
  'onWebsite': 'Buchungs-URL',
  'january': 'Januar',
  'february': 'Februar',
  'march': 'März',
  'april': 'April',
  'may': 'Mai',
  'june': 'Juni',
  'july': 'Juli',
  'august': 'August',
  'september': 'September',
  'october': 'Oktober',
  'november': 'November',
  'december': 'Dezember',
  'sundayShort': 'So',
  'sunday': 'Sonntag',
  'mondayShort': 'Mo',
  'monday': 'Montag',
  'mondayTwoPart': 'Mo',
  'tuesdayTwoPart': 'Di',
  'wednesdayTwoPart': 'Mi',
  'thursdayTwoPart': 'Do',
  'fridayTwoPart': 'Fr',
  'saturdayTwoPart': 'Sa',
  'sundayTwoPart': 'So',
  'tuesdayShort': 'Di',
  'tuesday': 'Dienstag',
  'wednesdayShort': 'Mi',
  'wednesday': 'Mittwoch',
  'thursdayShort': 'Do',
  'thursday': 'Donnerstag',
  'fridayShort': 'Fr',
  'friday': 'Freitag',
  'saturdayShort': 'Sa',
  'saturday': 'Samstag',
  'canNotBuyMoreTickets': 'Sie können nicht mehr von dieser Aktivität kaufen. Keine Plätze',
  'willEndSoon': 'Bald ablaufend!',
  'itemsLeft': '%{limit} übrig!',
  'willEndBefore': 'Angebot bis %{date}!',
  'soldOut': 'Ausverkauft!',
  'bookingRequired': 'Buchung nach Bezahlung erforderlich',
  'bookingAfterBuy': 'Nach dem Kauf erhalten Sie %p zur Buchung',
  'phone': 'eine Telefonnummer',
  'website': 'eine Internetadresse',
  'bookingEmail': 'eine E-Mail Adresse',
  'more': 'mehr',
  'less': 'weniger',
  'from': 'Von',
  'durationUnlimitedSentence': 'Ganzer Tag',
  'durationUnlimited': 'ganztägig',
  'useNumber': 'Anzahl der Eintritte',
  'useDatetime': 'Aufnahme',
  'betweenDates': 'zwischen %{since} und %{until}',
  'onDate': 'am %{date}',
  'validBeforeDate': 'vor %{date}',
  'validInDays': 'in %{number}',
  'today': 'heute',
  'numberOfDays': 'Tag |||| Tage',
  'activeInDays': 'Gültig: %{number} Tage nach Aktivierung',
  'activationInDays': 'Aktivierung in der Einrichtung %{days} nach dem Kauf',
  'activationDates': 'Aktivierung in der Einrichtung %{dates}',
  'pickFixedDateInTheNextStep': 'Prüfen Sie im nächsten Schritt alle verfügbaren Termine',
  'dateOfUse': 'Zulassungsdatum',
  'withDiscountLabel': 'Hast du einen Rabattcode?',
  'optional': 'optional',
  'discountCode': 'Rabatt-Code',
  'useDiscountCode': 'Code verwenden',
  'discountUsed': 'Aktiver Rabattcode (%{code}):',
  'invalidDiscountCode': 'Der Rabattcode (%{code}) funktioniert nicht für die gewählten Dienstleistungen.',
  'discountCodeNotFound': 'Rabattcode (%{code}) ist ungültig oder veraltet.',
  'discountAdditionsNotIncluded': 'Zugaben sind nicht rabattfähig.',
  'notEligibleDiscountTickets': 'Andere Tickets sind nicht für diesen Code geeignet.',
  'close': 'schließen',
  'tryAgain': 'erneut versuchen',
  'phoneNumber': 'Telefon-Nummer',
  'contactPhoneNumber': 'Telefonnummer der Kontaktperson',
  'clientPhoneNumber': 'Telefonnummer des Käufers',
  'restCountries': 'Übrige Länder',
  'sellerIs': 'Der Verkäufer und',
  'administratorOfThePersonalData': 'Verwalter Ihrer persönlichen Daten ist',
  'sellerCopy':
    `Die Verarbeitung Ihrer Daten erfolgt insbesondere zum Zweck der Vertragserfüllung, d. h. um Ihnen den Kauf einer Fahrkarte zu ermöglichen, auf der Grundlage von Artikel 6 Absatz 1 Buchstabe b der DSGVO.
    Sie haben das Recht auf Auskunft über den Inhalt Ihrer Daten und das Recht auf Berichtigung, Löschung, Einschränkung der Verarbeitung, das Recht auf Datenübertragbarkeit, das Recht auf Widerspruch und das Recht auf Beschwerde bei der Aufsichtsbehörde.
    Ihre Daten sind nicht Gegenstand einer automatisierten Entscheidungsfindung, einschließlich Profiling.`,
  'infoAboutPrivacyPolicy': 'Den vollständigen Inhalt der Informationsklausel finden Sie hier',
  'businessSellerCopy': 'Der Verkäufer ist',
  'privacyPolicy': 'Datenschutz',
  'privacyPolicyAccept': 'Datenschutz-Politik',
  'termsOfServiceAgreement': 'Ich stimme zu',
  'termsOfService': 'Nutzungsbedingungen',
  'inTermsOfService': 'Bedingungen für den Service',
  'inPrivacyPolicy': 'Datenschutzbestimmungen',
  'and': 'und',
  'acceptedTermsOfServiceIn': 'Akzeptierte Bedingungen finden Sie in',
  'facilityTermsOfService': '%{facilityName}\'s Allgemeine Geschäftsbedingungen',
  'activitiesLimitExceeded': 'Oh, wir sind für ausgewählte Dienstleistungen ausverkauft. Bitte kaufen Sie andere Dienstleistungen.',
  'invalidDataOrCharacters': 'Die eingegebenen Daten sind ungültig oder enthalten ungültige Zeichen. Ändern Sie sie und versuchen Sie es erneut.',
  'invalidAdmissionDate': 'Das Datum ist ungültig.',
  'internalErrorMessage': 'Fehler, bitte versuchen Sie es später erneut.',
  'widgetDisabled': 'Verkauf deaktiviert. Versuchen Sie es später noch einmal.',
  'bubbleDisabled': 'Verkauf ausgeschlossen!',
  'buyOnline': 'Online kaufen!',
  'buyVoucher': 'Gutschein kaufen',
  'buyTicket': 'Ticket kaufen!',
  'bookingOnline': 'Online buchen',
  'buySkipass': 'Skipass kaufen',
  'buyCourse': 'Kurs kaufen',
  'iWantInvoice': 'Ich möchte eine Rechnung erhalten',
  'address': 'Straße und Haus- / Wohnungsnummer',
  'city': 'Stadt',
  'postalCode': 'Postleitzahl',
  'companyName': 'Name der Firma',
  'requiredOnlyForInvoices': 'Nur für Firmenrechnungen erforderlich',
  'companyNIP': 'Steuernummer',
  'optionalSentence': 'Optional',
  'invalidPrice': 'Kann nicht mit der Zahlung fortfahren, da der sichtbare Preis von den tatsächlichen Daten abweicht - bitte aktualisieren Sie die Seite, um neue Daten zu laden',
  'paymentDelayedInfo': 'Die Zahlung der Gebühren für die Bestellung ist auf der Grundlage der Mehrwertsteuerrechnung erforderlich, die von %{companyName} bereitgestellt wird',
  'oncePerDay': 'Eintrag einmal pro Tag',
  'withoutLimit': 'Eintritt ohne Tageslimit',
  'ticketsReady': 'Ticket ist fertig! |||| Tickets sind fertig!',
  'pleaseWait': 'Bitte warten Sie...',
  'downloadTickets': 'Eintrittskarte herunterladen |||| Eintrittskarten herunterladen',
  'download': 'Herunterladen',
  'loadingTickets': 'Wir bereiten Ihre Tickets vor!',
  'doOrder': 'Bestellung',
  'doReservation': 'Reservieren',
  'generateExampleOrder': 'Beispielbestellung generieren',
  'hotelNight': 'Hotelübernachtung',
  'chooseHotelNight': 'Wählen Sie den Zeitraum der Unterkunft',
  'insertHotelNight': 'Zeitraum der Unterkunft hinzufügen',
  'chooseHotelNightDescription': 'Einträge für Gäste sind nur während der Unterkunft möglich.',
  'setNarrowedDates': 'Sie müssen das Anfangs- und Enddatum der Unterkunft wählen',
  'endDateCantBeTheSameAsStart': 'Das Ende der Unterkunft muss mindestens einen Tag später sein als der Beginn der Unterkunft.',
  'save': 'speichern',
  'insertYear': 'Jahr einfügen',
  'hour': 'Stunde',
  'realiseInDay': 'Realisierung am %{date}',
  'realiseInDateWithTime': 'Realisierung am %{date}, Sie wählen die Zeit im nächsten Schritt',
  'missingPaymentProvider': 'Die Zahlung wurde vom Verkäufer noch nicht aktiviert. Versuchen Sie es später erneut.',
  'seeOtherActivities': 'Andere Aktivitäten sehen',
  'hideOtherActivities': 'Andere Aktivitäten ausblenden',
  'givenDateIsSoldOut': 'Plätze für %{date} waren ausverkauft',
  'givenDateIsSoldOutTestB': 'Die Plätze für den %{date} sind bereits ausverkauft',
  'isOutOfLimit': 'Keine Plätze für %{time}',
  'setDifferentHour': 'Die Anzahl der verfügbaren Plätze für den %{time} ist: %{numberOfSeats}, wählen Sie eine andere Uhrzeit oder ein anderes Datum',
  'givenTimeIsSoldOut': 'Plätze für %{time} waren ausverkauft',
  'givenTimeIsSoldOutTestB': 'Die Plätze für den %{time} sind bereits ausverkauft',
  'hasNoSeatsForGivenDate': 'Keine Plätze für %{date}',
  'setDifferentDate': 'Die Anzahl der verfügbaren Plätze für den %{date} ist: %{availableSeats}, wählen Sie ein anderes Datum',
  'seatsSoldOut': 'ausverkauft',
  'hasNoSeats': 'keine Plätze',
  'chooseDifferentDate': 'Anderes Datum wählen',
  'actuallySoldOut': 'Aktuell ausverkauft!',
  'noDates': 'Derzeit keine Termine!',
  'customerData': 'Kundendaten',
  'optionalText': 'optional',
  'numberOfParticipantsLabel': 'Anzahl der Teilnehmer: %{name}',
  'avaliableNumber': 'Verbleibende Sitze: %{number}',
  'filter': 'Filter',
  'clear': 'Sauber',
  'checkItOut': 'Überprüfen Sie es',
  'remove': 'Löschen',
  'thisMayInterestYou': 'Dies könnte für Sie von Interesse sein',
  'requiredActivityVariantsNotPresentInOrder': 'Für den Kauf ist eine andere Variante erforderlich. Kehren Sie zur Liste der Dienste zurück und wählen Sie die entsprechende Variante.',
  'requiredActivityVariants': 'Sie vermissen etwas in Ihrer Bestellung.',
  'backTo': 'Zurück zum Tab',
  'addSmall': 'hinzufügen',
  'requiredActivity': 'die gewünschte Dienstleistung',
  'oneOfRequiredActivity': 'mindestens eine erforderliche Dienstleistung',
  'oneOfRequiredActivityVariants': 'mindestens eine der erforderlichen Dienstleistungsoptionen',
  'to': 'Bis',
  'marketingConsentsAgreement': 'Ich möchte mich für den Newsletter anmelden (optional)',
  'marketingConsentsPromo': 'Bleiben Sie auf dem Laufenden mit allen Aktionen und Informationen, wir senden nur das Wichtigste, wir versprechen keinen Spam!',
  'setAllConsents': 'Alle markieren',
  'InvalidNipFormat': 'Ungültige Umsatzsteuer-Identifikationsnummer',
  'numberOfPeopleInGroup': 'Anzahl der Personen in der Gruppe',
  'paymentFormOutside': 'Form der Bezahlung außerhalb von Droplabs',
  'other': 'Andere',
  'paymentTerminal': 'Zahlungsterminal',
  'cash': 'Bargeld',
  'bankTransfer': 'Überweisung auf Konto',
  'prefix': 'Vorsilbe',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Die Zahlungsfrist ist zu kurz',
  'paymentCompleteUntilDaysLimitExceeded': 'Die Zahlungsfrist ist zu lang',
  'setPaymentDate': 'Setzen Sie eine Zahlungsfrist',
  'setPaymentDateDesc': 'Wenn der Kunde die Bestellung nicht bis zum angegebenen Datum bezahlt, gehen die Plätze wieder in den Pool der verfügbaren Plätze zurück.',
  'newOrder': 'Einen neuen Auftrag erstellen',
  'LastStep': 'Der letzte Schritt vor Ihnen...',
  'copyLinkAndSendToCustomer': 'Kopieren Sie den Link und senden Sie ihn an den Kunden, damit er seine Bestellung bezahlen kann.',
  'orClick': 'oder klicken Sie',
  'CopyLink': 'Link kopieren',
  'paymentOutside': 'Bezahlung außerhalb von Droplabs',
  'paymentOnline': 'Online-Zahlung',
  'cancel': 'Absagen',
  'setDateAndTime': 'Datum und Uhrzeit auswählen',
  'privatePerson': 'Soukromá osoba',
  'company': 'Firma',
  'invoiceOptionalNote': 'Rechnungshinweis (optional)',
  'youWillReceiveInvoiceSimplified': 'Sie erhalten eine Quittung mit Ihrer USt-IdNr., die eine vereinfachte Rechnung darstellt.',
  'nameOptional': 'Vorname (fakultativ)',
  'surnameOptional': 'Nachname (fakultativ)',
  'emailOptional': 'E-Mail Adresse (fakultativ)',
  'isExpired': 'ist abgelaufen',
  'isAdmissionDateInvalid': 'betrifft eine andere Vorlaufzeit',
  'isInvalid': 'ist ungültig',
  'isUsed': 'wurde verwendet',
  'buyerData': 'Käuferdaten',
  'requiredEmailAlert': 'Bitte geben Sie die E-Mail-Adresse des Kunden an, sonst erhält er keine Rechnung.',
  'issueAnInvoice': 'Rechnung erstellen',
  'areYouLeaving': 'Gehen Sie schon aus?',
  'exitConfirmationComment': 'Wenn Sie jetzt kaufen, wird Ihnen ein unveränderter Preis garantiert. Wenn Sie das Programm verlassen, verlieren Sie die bisher durchgeführten Schritte.',
  'abandonPurchase': 'Ihren Einkauf abbrechen',
  'continueShopping': 'Einkauf fortsetzen',
  'thisFeatureIsNotEnabled': 'Diese Funktion ist nicht verfügbar',
  'thisFeatureIsNotEnabledSolution': 'Sie haben nichts zu verlieren! Schreiben Sie uns an %p, wir helfen Ihnen bei der Auswahl des richtigen Tarifs.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Bitte warten Sie',
  'waitingForPaymentConfirmation': 'Wir warten auf die Bestätigung der Zahlung.',
  'paymentProcessInterrupted': 'Wenn die Zahlung unterbrochen wurde, können Sie fortfahren',
  'clickHere': 'hier klicken',
  'ticketsLoadedAndSentToEmail': 'Tickets werden unten geladen und an Ihre E-Mail-Adresse gesendet:',
  'paymentAccepted': 'Zahlung akzeptiert',
  'orderNumber': 'Bestellnummer:',
  'orderSendOnEmail': 'Auftragsbestätigung per E-Mail gesendet:',
  'emailNotGetThrough': 'E-Mail nicht erhalten?',
  'emailNotGetThroughTooltip': 'Wenn Sie die E-Mail nicht innerhalb weniger Minuten nach der Zahlung sehen, überprüfen Sie Ihren Spam-Ordner. Wenn das nicht hilft, geben Sie \'Droplabs\' in die Suche Ihres E-Mail-Programms ein.',
  'orderCreated': 'Bestellung erstellt',
  'generatingTickets': 'Generiere Tickets',
  'generatingTicketsDescription': 'In Kürze können Sie Ihr Ticket herunterladen und es Ihrem virtuellen Portemonnaie hinzufügen. Wir haben auch eine Kopie desselben Tickets an die angegebene E-Mail-Adresse gesendet.',
  'everythingDone': 'Alles ist bereit!',
  'ticketsReadyToDownload': 'Tickets sind bereit zum Herunterladen. Klicken Sie auf die Schaltfläche zum Herunterladen.',
  'pdfDocumentIsReady': 'Erfolg, PDF bereit!',
  'clickForPdfDocument': 'PDF bereit zum Herunterladen. Klicken Sie auf die Schaltfläche zum Herunterladen.',
  'pdfDocumentIsGenerating': 'Generiere PDF',
  'pdfDocumentIsGeneratingDescription': 'Die Generierung des PDFs kann einen Moment dauern, Sie müssen nicht warten.',
  'generating': 'Generiere...',
  'sendOrder': 'Bestellung senden',
  'sendOrderDescription': 'Geben Sie die E-Mail-Adresse an, an die die Bestellung zusammen mit dem Ticket gesendet werden soll.',
  'orderSendedOnEmail': 'Die Bestellung wurde an die angegebene E-Mail-Adresse gesendet',
  'orderSomethingElse': 'Möchten Sie noch etwas bestellen?',
  'actionDisabledInPreviewMode': 'Diese Aktion kann nicht durchgeführt werden, dieser Modus dient nur zur Vorschau.',
  'anotherRecipient': 'Ich möchte einen anderen Empfänger angeben',
  'recipientName': 'Name des Empfängers',
  'digitalShippingMethod': 'Elektronischer Versand an eine angegebene E-Mail Adresse',
  'somethingIsWrong': 'Es ist etwas schief gelaufen...',
  'waitingForPaymentError': 'Ihre Bestellung wurde storniert oder die Zahlungsfrist ist abgelaufen. Erstellen Sie eine neue Bestellung oder erneuern Sie Ihre Zahlung.',
  'waitingForPayment': 'Warten auf Zahlung',
  'waitingForPaymentDesc': 'Der Bestellbetrag wurde an das Viva Wallet Terminal gesendet. Akzeptieren Sie die Zahlung, sobald der Betrag auf dem Bildschirm des Terminals erscheint. Wenn Sie die Zahlung nicht annehmen möchten, brechen Sie die Transaktion ab.',
  'sendAgain': 'Erneut senden',
  'paymentRepeatError': 'Die Zahlung konnte nicht erneut gesendet werden',
  'paymentRepeatSuccess': 'Die Zahlung wurde erneut gesendet',
  'rePayment': 'Zahlung wiederholen',
  'setTicketAsUsed': 'Als Verwendet markieren',
  'setTicketAsUsedDescription': 'Erfassen der Verwendung aller neu generierten Tickets, gültig für heute.',
  'useTickets': 'Tickets verwenden',
  'useTicketsSuccess': 'Die Tickets wurden erfolgreich als verwendet markiert.',
  'useTicketsError': 'Es ist nicht möglich, die Tickets als verwendet zu markieren.',
  'mismatchedTicketDate': 'Die Bestellung betrifft ein anderes Erfüllungsdatum.',
  'ticketInactive': 'Die Bestellung ist nicht aktiv.',
  'useTicketsErrorMoreDetails': 'Bestelldetails finden Sie im Abschnitt \'Kundensupport\' oder \'Zugangskontrolle\'.',
  'country': 'Land',
  'notSavedData': 'Die Daten werden nicht gespeichert',
  'notSavedDataDesc': 'Wenn Sie zusätzliche Daten eingegeben haben, beachten Sie bitte, dass diese Informationen verloren gehen, wenn Sie zur Auswahl der Dienste gehen.',
  'changeActivities': 'Artikel ändern',
  'AD': 'Andorra',
  'AE': 'Vereinigte Arabische Emirate',
  'AF': 'Afghanistan',
  'AG': 'Antigua und Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albanien',
  'AM': 'Armenien',
  'AO': 'Angola',
  'AQ': 'Antarktis',
  'AR': 'Argentinien',
  'AS': 'Amerikanisch-Samoa',
  'AT': 'Österreich',
  'AU': 'Australien',
  'AW': 'Aruba',
  'AX': 'Åland-Inseln',
  'AZ': 'Aserbaidschan',
  'BA': 'Bosnien und Herzegowina',
  'BB': 'Barbados',
  'BD': 'Bangladesch',
  'BE': 'Belgien',
  'BF': 'Burkina Faso',
  'BG': 'Bulgarien',
  'BH': 'Bahrain',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint-Barthélemy',
  'BM': 'Bermuda',
  'BN': 'Brunei Darussalam',
  'BO': 'Bolivien (Plurinationaler Staat)',
  'BQ': 'Bonaire, Sint Eustatius und Saba',
  'BR': 'Brasilien',
  'BS': 'Bahamas',
  'BT': 'Bhutan',
  'BV': 'Bouvetinsel',
  'BW': 'Botswana',
  'BY': 'Weißrussland',
  'BZ': 'Belize',
  'CA': 'Kanada',
  'CC': 'Kokosinseln (Keelinginseln)',
  'CD': 'Kongo, Demokratische Republik',
  'CF': 'Zentralafrikanische Republik',
  'CG': 'Kongo',
  'CH': 'Schweiz',
  'CI': 'Elfenbeinküste',
  'CK': 'Cookinseln',
  'CL': 'Chile',
  'CM': 'Kamerun',
  'CN': 'China',
  'CO': 'Kolumbien',
  'CR': 'Costa Rica',
  'CU': 'Kuba',
  'CV': 'Cabo Verde',
  'CW': 'Curaçao',
  'CX': 'Weihnachtsinsel',
  'CY': 'Zypern',
  'CZ': 'Tschechien',
  'DE': 'Deutschland',
  'DJ': 'Dschibuti',
  'DK': 'Dänemark',
  'DM': 'Dominica',
  'DO': 'Dominikanische Republik',
  'DZ': 'Algerien',
  'EC': 'Ecuador',
  'EE': 'Estland',
  'EG': 'Ägypten',
  'EH': 'Westsahara',
  'ER': 'Eritrea',
  'ES': 'Spanien',
  'ET': 'Äthiopien',
  'FI': 'Finnland',
  'FJ': 'Fidschi',
  'FK': 'Falklandinseln (Malvinen)',
  'FM': 'Mikronesien (Föderierte Staaten)',
  'FO': 'Färöer-Inseln',
  'FR': 'Frankreich',
  'GA': 'Gabun',
  'GB': 'Vereinigtes Königreich Großbritannien und Nordirland',
  'GD': 'Grenada',
  'GE': 'Georgien',
  'GF': 'Französisch-Guayana',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GL': 'Grönland',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadeloupe',
  'GQ': 'Äquatorialguinea',
  'GR': 'Griechenland',
  'GS': 'Südgeorgien und die Südlichen Sandwichinseln',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HK': 'Hongkong',
  'HM': 'Heard und McDonaldinseln',
  'HN': 'Honduras',
  'HR': 'Kroatien',
  'HT': 'Haiti',
  'HU': 'Ungarn',
  'ID': 'Indonesien',
  'IE': 'Irland',
  'IL': 'Israel',
  'IM': 'Isle of Man',
  'IN': 'Indien',
  'IO': 'Britisches Territorium im Indischen Ozean',
  'IQ': 'Irak',
  'IR': 'Iran (Islamische Republik)',
  'IS': 'Island',
  'IT': 'Italien',
  'JE': 'Jersey',
  'JM': 'Jamaika',
  'JO': 'Jordanien',
  'JP': 'Japan',
  'KE': 'Kenia',
  'KG': 'Kirgisistan',
  'KH': 'Kambodscha',
  'KI': 'Kiribati',
  'KM': 'Komoren',
  'KN': 'St. Kitts und Nevis',
  'KP': 'Korea (Demokratische Volksrepublik)',
  'KR': 'Korea, Republik',
  'KW': 'Kuwait',
  'KY': 'Kaimaninseln',
  'KZ': 'Kasachstan',
  'LA': 'Laos, Demokratische Volksrepublik',
  'LB': 'Libanon',
  'LC': 'St. Lucia',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Liberia',
  'LS': 'Lesotho',
  'LT': 'Litauen',
  'LU': 'Luxemburg',
  'LV': 'Lettland',
  'LY': 'Libyen',
  'MA': 'Marokko',
  'MC': 'Monaco',
  'MD': 'Moldau, Republik',
  'ME': 'Montenegro',
  'MF': 'Saint Martin (französischer Teil)',
  'MG': 'Madagaskar',
  'MH': 'Marshallinseln',
  'MK': 'Nordmazedonien',
  'ML': 'Mali',
  'MM': 'Myanmar',
  'MN': 'Mongolei',
  'MO': 'Macao',
  'MP': 'Nördliche Marianen',
  'MQ': 'Martinique',
  'MR': 'Mauretanien',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauritius',
  'MV': 'Malediven',
  'MW': 'Malawi',
  'MX': 'Mexiko',
  'MY': 'Malaysia',
  'MZ': 'Mosambik',
  'NA': 'Namibia',
  'NC': 'Neukaledonien',
  'NE': 'Niger',
  'NF': 'Norfolkinsel',
  'NG': 'Nigeria',
  'NI': 'Nicaragua',
  'NL': 'Niederlande, Königreich der',
  'NO': 'Norwegen',
  'NP': 'Nepal',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'Neuseeland',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'Französisch-Polynesien',
  'PG': 'Papua-Neuguinea',
  'PH': 'Philippinen',
  'PK': 'Pakistan',
  'PL': 'Polen',
  'PM': 'Saint-Pierre und Miquelon',
  'PN': 'Pitcairninseln',
  'PR': 'Puerto Rico',
  'PS': 'Palästinensisches Gebiet, besetztes',
  'PT': 'Portugal',
  'PW': 'Palau',
  'PY': 'Paraguay',
  'QA': 'Katar',
  'RE': 'Réunion',
  'RO': 'Rumänien',
  'RS': 'Serbien',
  'RU': 'Russische Föderation',
  'RW': 'Ruanda',
  'SA': 'Saudi-Arabien',
  'SB': 'Salomonen',
  'SC': 'Seychellen',
  'SD': 'Sudan',
  'SE': 'Schweden',
  'SG': 'Singapur',
  'SH': 'St. Helena, Ascension und Tristan da Cunha',
  'SI': 'Slowenien',
  'SJ': 'Svalbard und Jan Mayen',
  'SK': 'Slowakei',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somalia',
  'SR': 'Suriname',
  'SS': 'Südsudan',
  'ST': 'São Tomé und Príncipe',
  'SV': 'El Salvador',
  'SX': 'Sint Maarten (niederländischer Teil)',
  'SY': 'Syrische Arabische Republik',
  'SZ': 'Eswatini',
  'TC': 'Turks- und Caicosinseln',
  'TD': 'Tschad',
  'TF': 'Französische Südgebiete',
  'TG': 'Togo',
  'TH': 'Thailand',
  'TJ': 'Tadschikistan',
  'TK': 'Tokelau',
  'TL': 'Timor-Leste',
  'TM': 'Turkmenistan',
  'TN': 'Tunesien',
  'TO': 'Tonga',
  'TR': 'Türkei',
  'TT': 'Trinidad und Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwan, Provinz China',
  'TZ': 'Tansania, Vereinigte Republik',
  'UA': 'Ukraine',
  'UG': 'Uganda',
  'UM': 'Amerikanisch-Ozeanien',
  'US': 'Vereinigte Staaten von Amerika',
  'UY': 'Uruguay',
  'UZ': 'Usbekistan',
  'VA': 'Heiliger Stuhl',
  'VC': 'St. Vincent und die Grenadinen',
  'VE': 'Venezuela (Bolivarische Republik)',
  'VG': 'Britische Jungferninseln',
  'VI': 'Amerikanische Jungferninseln',
  'VN': 'Vietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis und Futuna',
  'WS': 'Samoa',
  'YE': 'Jemen',
  'YT': 'Mayotte',
  'ZA': 'Südafrika',
  'ZM': 'Sambia',
  'ZW': 'Simbabwe',
  'nearestDate': 'Nächstes verfügbares Datum',
  'nearestDateWithHour': '%{day} um %{hour} Uhr',
  'tomorrow': 'morgen',
  'paymentMethod': 'Zahlungsmethode',
  'secureFormDescription': 'Zahlen Sie schnell per Überweisung / Kreditkarte',
  'typeGeneratedBlikCode': 'Geben Sie den einmaligen Code ein, der in Ihrer Banking App generiert wurde',
  'blik': 'BLIK',
  'typeBlikCode': 'Geben Sie den BLIK-Code ein',
  'blikCodePatternError': 'Der Code muss 6 Ziffern enthalten',
  'blikCode': 'BLIK-Code',
  'simplifiedInvoice': 'Vereinfacht',
  'fullInvoice': 'Vollständig',
  'privateInvoice': 'Benannt',
  'simplifiedInvoiceInformation': 'Der Kunde erhält eine Quittung mit der Mehrwertsteuernummer als vereinfachte Rechnung. Sie kann nur für in Polen registrierte Unternehmen ausgestellt werden. Für ausländische Unternehmen wählen Sie bitte die vollständige Rechnung.',
  'isNotPolishNip': 'Die angegebene NIP-Nummer ist nicht polnisch.',
  'polishNip': 'Polnische MwSt.-Nummer',
  'checkTheBankingApp': 'Überprüfen Sie die Bankanwendung',
  'confirmPaymentWithPin': 'Bestätigen Sie die Zahlung jetzt mit Ihrer PIN über die App auf Ihrem Telefon.',
  'ticketsLoadedAfterConfirm': 'Tickets werden nach Bestätigung der Zahlung geladen und an die E-Mail gesendet:',
  'paymentWithBlik': 'Zahlen mit BLIK',
  'cannontLoadPage': 'Seite konnte nicht geladen werden',
  'refreshPage': 'Seite neu laden',
  'isSoldOut': 'Ausverkauft',
  'removeSelectedActivities': 'Ausgewählte Dienste löschen',
  'removeSelectedServicesQuestion': 'Ausgewählte Dienste entfernen?',
  'allServicesWillBeRemoved': 'Alle Dienste werden entfernt und Sie kehren zur erneuten Auswahl zurück',
  'hourFull': 'Stunde',
  'hasNoSeatsTest': 'Keine Plätze',
  'pleaseSelectDate': 'Bitte wählen Sie ein Datum',
  'pleaseSelectTime': 'Bitte wählen Sie eine Zeit',
  'selected': 'Ausgewählte',
  'term': 'Datum',
  'price': 'Preis',
  'startDate': 'Startdatum',
  'endDate': 'Enddatum',
  'daysQuanity': 'Anzahl der Tage',
  'decrease': 'Verringern',
  'increase': 'Erhöhen',
  'minValueError': 'Der Mindestwert ist %{value}',
  'maxValueError': 'Der Höchstwert ist %{value}',
  'numberOfActivities': 'Anzahl der Dienste'
};

/* eslint-enable max-len */
