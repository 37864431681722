import {OnlinePaymentMethods} from '@Components/checkout/types';
import {PHONE_PREFIXES} from '@Components/checkoutPages/agreements/phoneNumberSection/PhoneNumberPrefixes';
import {TAgreementsFormValues} from '@Components/checkoutPages/agreements/types';
import {EInvoiceFormType} from '@Components/invoice/types';
import {IFacility} from '@Consts/apiGlobals';
import {
  getStorageEmail,
  getStorageFirstName,
  getStorageInvoiceAddressCity,
  getStorageInvoiceAddressCountry,
  getStorageInvoiceAddressPostalCode,
  getStorageInvoiceAddressStreet,
  getStorageInvoiceCompanyName,
  getStorageInvoiceCompanyNip,
  getStorageInvoiceFirstName,
  getStorageInvoiceFormType,
  getStorageInvoiceLastName,
  getStorageInvoiceRecipientAddressCity,
  getStorageInvoiceRecipientAddressCountry,
  getStorageInvoiceRecipientAddressPostalCode,
  getStorageInvoiceRecipientAddressStreet,
  getStorageInvoiceRecipientName,
  getStorageIsInvoiceRecipient,
  getStorageLastName,
  getStoragePhoneNumber,
  getStoragePhonePrefix,
  getStorageSendInvoice
} from '@Features/storage/storageSelectors';
import {getSpecificCountry} from '@Utils/invoiceCountries';
import {useSelector} from 'react-redux';
import {EnabledPaymentMethods} from './usePaymentMethodsEnabled';

interface Props {
  facility: IFacility,
  widgetIsBusiness: boolean,
  withContactPhoneNumber: boolean,
  enabledPaymentMethods: EnabledPaymentMethods
}

export const useAgreementsFormDefaultValues = ({
  facility,
  widgetIsBusiness,
  withContactPhoneNumber,
  enabledPaymentMethods
}: Props): TAgreementsFormValues => {
  const {
    isMarketingConsentsEnabled,
    company
  } = facility;
  const {
    isUsersInvoiceNoteEnabled,
    country
  } = company;

  const phonePrefixStorage = useSelector(getStoragePhonePrefix);

  const phonePrefix = PHONE_PREFIXES.find(
    obj => obj.value === phonePrefixStorage
  );

  const defaultValues: TAgreementsFormValues = {
    email: useSelector(getStorageEmail) ?? '',
    phonePrefix: phonePrefix?.value ? phonePrefix : PHONE_PREFIXES[0],
    phoneNumber: withContactPhoneNumber ? useSelector(getStoragePhoneNumber) ?? '' : '',
    firstName: useSelector(getStorageFirstName) ?? '',
    lastName: useSelector(getStorageLastName) ?? '',
    invoiceAddressCity: useSelector(getStorageInvoiceAddressCity) ?? '',
    invoiceAddressStreet: useSelector(getStorageInvoiceAddressStreet) ?? '',
    invoiceAddressPostalCode: useSelector(getStorageInvoiceAddressPostalCode) ?? '',
    invoiceAddressCountry: useSelector(getStorageInvoiceAddressCountry) ?? getSpecificCountry(country),
    invoiceCompanyName: useSelector(getStorageInvoiceCompanyName) ?? '',
    invoiceCompanyNip: useSelector(getStorageInvoiceCompanyNip) ?? '',
    invoiceFirstName: useSelector(getStorageInvoiceFirstName) ?? '',
    invoiceLastName: useSelector(getStorageInvoiceLastName) ?? '',
    sendInvoice: !widgetIsBusiness ? useSelector(getStorageSendInvoice) || false : false,
    termsOfService: false,
    selectAllConsets: false,
    ...enabledPaymentMethods.isSecureFormBlikEnabled && {paymentMethod: OnlinePaymentMethods.SECURE_FORM},
    ...enabledPaymentMethods.isSecureFormBlikEnabled && {blikCode: ''},
    ...enabledPaymentMethods.isCardEnabled && {paymentMethod: OnlinePaymentMethods.PAYMENT_CARD},
    invoiceFormType: useSelector(getStorageInvoiceFormType) ?? EInvoiceFormType.COMPANY,
    ...isMarketingConsentsEnabled && {isAgreeMarketingConsents: false},
    ...isUsersInvoiceNoteEnabled && {invoiceNote: ''},
    isInvoiceRecipient: useSelector(getStorageIsInvoiceRecipient) ?? false,
    invoiceRecipientName: useSelector(getStorageInvoiceRecipientName) ?? '',
    invoiceRecipientAddressStreet: useSelector(getStorageInvoiceRecipientAddressStreet) ?? '',
    invoiceRecipientAddressCity: useSelector(getStorageInvoiceRecipientAddressCity) ?? '',
    invoiceRecipientAddressPostalCode: useSelector(getStorageInvoiceRecipientAddressPostalCode) ?? '',
    invoiceRecipientAddressCountry: useSelector(getStorageInvoiceRecipientAddressCountry) ?? getSpecificCountry(country)
  };

  return defaultValues;
};
