/* global fbq */
/* globals __IS_PRODUCTION__ */
import './polyfil';
import {
  FACEBOOK_VIEW_CONTENT_EVENT,
  FACEBOOK_ADD_PAYMENT_INFO_EVENT,
  FACEBOOK_INITIATE_CHECKOUT_EVENT,
  FACEBOOK_PURCHASE_EVENT,
  FACEBOOK_ADD_TO_CART
} from './utils/facebookPixel';
import {
  MESSAGE_IFRAME_INITIALIZE,
  MESSAGE_SET_HEIGHT
} from '@Consts/message';
import {debounce} from '@Utils/utils';
import {GTM_MESSAGE_NAME} from '@Utils/gtm/core';
import getConfig from './widget/utils/getConfig';
import {WidgetMode} from './widget/types';
import getLoggedUser from './storage/getLoggedUser';
import getStorage from './storage/getStorage';
import {getNamespacedStorage, setNamespacedStorage, removeNamespacedStorage} from './storage/namespacedStorage';
import {getScrollData} from '@Utils/scrollUtils';
import {handleCookiebot} from '@Utils/cookiebot';

let initialized = false;

window.dlWidgetInitialized = window.dlWidgetInitialized || false;

const getIframe = iframeSource => {
  let iframeByDomain;

  iframeSource = iframeSource || 'https://widget.droplabs.pl/index.html';

  if (__IS_PRODUCTION__) {
    iframeByDomain = document.querySelector(`iframe[src^="${iframeSource}"]`);
  } else {
    /** Resolution for ngrok */
    // iframeByDomain = document.querySelector('iframe[src^="https://__NGROK_URL__/index.html"]')

    iframeByDomain =
      document.querySelector(`iframe[src^="${iframeSource}"]`) ||
      document.querySelector('iframe[src^="index.html"]') ||
      document.querySelector('iframe[src^="https://widget-sandbox.droplabs.pl/index.html"]');
  }

  if (iframeByDomain) {
    return iframeByDomain;
  }

  return document.querySelector('#dl-widget');
};

handleCookiebot(getIframe);

const getIframeTop = iframeElement => {
  if (iframeElement) {
    const rect = iframeElement.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    return rect.top + scrollTop;
  }

  return 0;
};

const getIframeCenter = iframeElement => {
  if (iframeElement) {
    const iframeTop = getIframeTop(iframeElement);
    const iframeHeight = iframeElement.scrollHeight;

    return Math.round(iframeHeight / 2 + iframeTop);
  }

  return 0;
};

let initializeModal;
const lazyLoadModal = configs => {
  if (!initializeModal) {
    import('./widget/modal').then(({default: initializeModalFn}) => {
      initializeModal = initializeModalFn;
      initializeModal(configs);
    });
  } else {
    initializeModal(configs);
  }
};

let widgetConfigurations = getConfig(document.currentScript);

const viewportHeight = () => window.innerHeight;
const getScrollTop = () => Math.max(document.documentElement.scrollTop, document.body.scrollTop);

const handleWidgetMessage = event => {
  const {isModal, url, ...messageData} = event.data;
  const iframeElement = getIframe(url);

  const setIframeHeight = () => {
    if (isModal) {
      return;
    }

    if (!iframeElement) {
      return;
    }

    if (messageData.height) {
      iframeElement.style.height = `${messageData.height}px`;
    } else {
      iframeElement.style.height = 'initial';
    }
  };

  const updateScroll = () => {
    event.source?.postMessage({
      name: 'scroll',
      scrollTop: getScrollTop(),
      viewportHeight: viewportHeight(),
      iframeTop: getIframeTop(iframeElement)
    }, '*');
  };

  const dlLoggedUserState = getLoggedUser();
  const storage = getStorage();
  const nsStorage = getNamespacedStorage();
  const scriptData = {
    name: 'scriptInitialized',
    authToken: dlLoggedUserState?.session?.authenticationToken || null,
    parentUrl: window.location.href,
    storage,
    nsStorage,
    cookieConsents: window?.CookieConsent?.consent,
    parentReferrer: document.referrer
  };

  if (messageData.name === 'iframeRequestScript') {
    event.source?.postMessage(scriptData, '*');
  }

  if (messageData.name === MESSAGE_IFRAME_INITIALIZE) {
    if (!initialized) {
      initialized = true;

      if (!isModal) {
        window.addEventListener('scroll', debounce(updateScroll, 50));
        window.addEventListener('resize', updateScroll);
      }

      event.source?.postMessage(scriptData, '*');
    }

    updateScroll();
  }

  if (messageData.name === 'triggerScroll') {
    updateScroll();
  }

  if (messageData.name === 'setStorage') {
    setNamespacedStorage(messageData);
  }

  if (messageData.name === 'removeStorage') {
    removeNamespacedStorage(messageData);
  }

  if (messageData.name === 'scrollTop') {
    const iframeTop = getIframeTop(iframeElement);

    if (iframeElement && getScrollTop() > iframeTop && !isModal) {
      const offset = messageData.offset;
      const validOffset = offset >= 0 ? offset : 120;
      const scrollTop = iframeTop - validOffset;

      if (typeof window.scroll === 'function') {
        window.scroll(0, scrollTop);
      } else {
        window.scrollTo(0, scrollTop);
      }
    }
  }

  if (messageData.name === 'scrollCenter') {
    if (iframeElement && !isModal) {
      const iframeCenter = getIframeCenter(iframeElement);
      const windowCenter = window.innerHeight / 2;
      const validScrollTop = iframeCenter - windowCenter;

      if (typeof window.scroll === 'function') {
        window.scroll({top: validScrollTop, behavior: 'smooth'});
      } else {
        window.scrollTo({top: validScrollTop, behavior: 'smooth'});
      }
    }
  }
  if (messageData.name === 'scrollRef') {
    if (isModal) {
      return;
    }

    const iframeTop = getIframeTop(iframeElement);
    const scrollData = getScrollData(iframeElement, iframeTop, messageData);

    if (scrollData.scroll) {
      if (typeof window.scroll === 'function') {
        window.scroll({
          top: scrollData.offset,
          behavior: 'smooth'
        });
      } else {
        window.scrollTo({
          top: scrollData.offset,
          behavior: 'smooth'
        });
      }
    }
  }

  if (messageData.name === 'payment' && messageData.href) {
    window.location.assign(messageData.href);
  }

  if (messageData.name === MESSAGE_SET_HEIGHT) {
    setIframeHeight();
  }

  if (typeof fbq === 'function' && messageData.name === 'facebookPixel') {
    if (messageData.action === FACEBOOK_VIEW_CONTENT_EVENT) {
      fbq('track', FACEBOOK_VIEW_CONTENT_EVENT);
    }

    if (messageData.action === FACEBOOK_ADD_PAYMENT_INFO_EVENT) {
      fbq('track', FACEBOOK_ADD_PAYMENT_INFO_EVENT);
    }

    if (messageData.action === FACEBOOK_ADD_TO_CART) {
      fbq('track', messageData.action, messageData.value);
    }

    if (
      messageData.action === FACEBOOK_INITIATE_CHECKOUT_EVENT ||
      messageData.action === FACEBOOK_PURCHASE_EVENT
    ) {
      if (messageData.value && messageData.currency) {
        fbq('track', messageData.action, {
          value: messageData.value,
          currency: messageData.currency
        });
      }
    }
  }

  if (!!window.dataLayer && event.data.name === GTM_MESSAGE_NAME) {
    window.dataLayer.push(event.data.gtmData);
  }

  if (messageData.name === 'addParam') {
    if (!messageData?.paramName || !messageData?.value) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const currentParamValue = urlParams.get(messageData.paramName);

    if (currentParamValue === messageData.value) {
      return;
    }

    urlParams.set(messageData.paramName, messageData.value);
    history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
  }

  if (messageData.name === 'deleteParam') {
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.delete(messageData.paramName);

    const pathname = window.location.pathname;
    const url = urlParams.size > 0 ? `${pathname}?${urlParams}` : pathname;

    history.pushState({}, '', url);
  }
};

const initialize = () => {
  if (window.dlWidgetInitialized) {
    return;
  }

  // mutation observer for currentScript: reinitialize modal when attributes change (for bestplan.travel)
  const observer = new MutationObserver(() => {
    widgetConfigurations = getConfig(
      document.currentScript ?? document.querySelector('script.dl-widget-script')
    );

    if (widgetConfigurations.find(({mode}) => mode === WidgetMode.modal)) {
      lazyLoadModal(widgetConfigurations);
    }
  });

  observer.observe(document.currentScript, {attributes: true});

  if (widgetConfigurations.find(({mode}) => mode === WidgetMode.modal)) {
    lazyLoadModal(widgetConfigurations);
  } else {
    // When running in inline mode, we need to remove the bubble
    const $bubbleNode = document.querySelector('.dl-bubble');
    const $bubbleMobile = document.querySelector('.dl-bubble-mobile');
    const $bubbleTabMobile = document.querySelector('.dl-bubble-tab-mobile');
    const $bubbleTab = document.querySelector('.dl-bubble-tab');

    if ($bubbleNode) {
      $bubbleNode.parentElement.removeChild($bubbleNode);
    }

    if ($bubbleMobile) {
      $bubbleMobile.parentElement.removeChild($bubbleMobile);
    }

    if ($bubbleTab) {
      $bubbleTab.parentElement.removeChild($bubbleTab);
    }

    if ($bubbleTabMobile) {
      $bubbleTabMobile.parentElement.removeChild($bubbleTabMobile);
    }
  }

  window.addEventListener('message', handleWidgetMessage, false);

  window.dlWidgetInitialized = true;
};

initialize();
