import React from 'react';
import {useSendOrderForm} from '../../../hooks/useSendOrderForm';
import {useSummaryData} from '../../../hooks/useSummaryData';
import InternalOrderCard from './InternalOrderCard';
import {FormProvider} from 'react-hook-form';
import {Alert, Box, Button, SxProps, Theme, Typography} from '@mui/material';
import locale from '@Utils/locale';
import EmailField from '@Components/checkout/field/EmailField';
import TermsOfServiceField from '@Components/formFields/TermsOfServiceField';
import TextLink from '@Components/common/TextLink';
import {BUSINESS_CLIENT_TERMS_OF_SERVICE_URL} from '@Consts/urls';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import CircularProgress from '@mui/material/CircularProgress';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const alertStyles: SxProps<Theme> = {
  mb: {xs: 3, sm: 0},
  maxWidth: {xs: 1, sm: 320, md: 350, lg: 400}
};

const InternalSendOrderSection = () => {
  const {
    isPreviewMode
  } = useSummaryData();
  const {
    formProperties,
    handleFormSubmit,
    isLoading,
    isError,
    isSuccess,
    handleCloseAlert,
    orderEmail
  } = useSendOrderForm();
  const {handleSubmit} = formProperties;

  return (
    <InternalOrderCard>
      <FormProvider {...formProperties}>
        <Box component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)} >
          <Typography sx={{mb: 1, fontWeight: 500}} variant="h5">
            {locale.translate('sendOrder')}
          </Typography>
          <Typography
            sx={{
              mb: 3,
              color: 'text.secondary'
            }}
          >
            {locale.translate('sendOrderDescription')}
          </Typography>
          <EmailField fieldStyles={{mb: 2}} componentProps={{disabled: isLoading}} />
          <TermsOfServiceField
            fieldStyles={{mb: 3}}
            componentProps={{disabled: isLoading}}
            label={
              <Box component="span" data-testid="terms-of-service">
                {locale.translate('forSendingAcceptTos')}{' '}
                <Box component="span">
                  <TextLink href={BUSINESS_CLIENT_TERMS_OF_SERVICE_URL}>
                    {locale.translate('agreementFormWithClause')}
                  </TextLink>
                </Box>
              </Box>
            }
          />
          <Box
            sx={{
              display: 'flex',
              width: 1,
              justifyContent: {sm: isError || isSuccess ? 'space-between' : 'end'},
              flexWrap: 'wrap'
            }}
          >
            {
              isError &&
              <Alert sx={alertStyles} color="error" onClose={handleCloseAlert}>
                <Typography variant="body2" >
                  {locale.translate('unsuccessfullyOrderedSending', {email: orderEmail})}
                </Typography>
              </Alert>
            }
            {
              isSuccess &&
              <Alert sx={alertStyles} onClose={handleCloseAlert}>
                <Typography variant="body2" >
                  {locale.translate('orderSendedOnEmail')}{' '}
                  <Typography component="span" sx={{fontWeight: 700}}>
                    {orderEmail}
                  </Typography>
                </Typography>
              </Alert>
            }
            <FollowCursorTooltip
              title={locale.translate('actionDisabledInPreviewMode')}
              enable={isPreviewMode}
              styles={{width: {xs: 1, sm: 'auto'}}}
            >
              <Button
                variant={!isPreviewMode ? 'outlined' : 'contained'}
                disabled={isPreviewMode || isLoading}
                color="inherit"
                sx={{width: {xs: 1, sm: 'auto'}}}
                type="submit"
                endIcon={<SendOutlinedIcon />}
                {...isLoading && {
                  disabled: true,
                  endIcon: <CircularProgress size={16} color="inherit" />
                }}
              >
                {locale.translate('send')}
              </Button>
            </FollowCursorTooltip>
          </Box>
        </Box>
      </FormProvider>
    </InternalOrderCard>
  );
};

export default InternalSendOrderSection;
