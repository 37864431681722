import {AdmissionPolicyDate} from '@Consts/apiGlobals';
import {IActivityProcessed, IActivityVariantProcessed} from '@Consts/globals';
import {IBasketItem, IBasketItemSelections, IQuestionAnswer} from '@Consts/types';
import {isActivityHotelNarrowed} from './activity/activity';
import {createCurrentDate, getStartOf} from './dates/timezoneDates';

const getAnswersToCopy = (basketItemsGroup: IBasketItem[]) =>
  basketItemsGroup.reduce((activityScopeAnswers: IQuestionAnswer[], basketItem) => {
    const basketItemWithAnswersToCopy = basketItem.selections?.answers.filter(
      answer => answer.isActivityAnswerScope
    );

    if (basketItemWithAnswersToCopy) {
      return [...activityScopeAnswers, ...basketItemWithAnswersToCopy];
    }

    return activityScopeAnswers;
  }, []);

const getUniqueAnswers = (allAnswers: IQuestionAnswer[], basketItemQuestionsIds: number[]) => {
  const uniqueQuestionsIds = [...new Set(allAnswers.map(answer => answer.questionId))];
  const uniqueBasketItemQuestionsIds = uniqueQuestionsIds.filter(
    uniqueQuestionsId => basketItemQuestionsIds.some(
      basketItemQuestionsId => basketItemQuestionsId === uniqueQuestionsId
    )
  );

  return uniqueBasketItemQuestionsIds.map(
    questionId => allAnswers.find(answer => answer.questionId === questionId)
  ) as IQuestionAnswer[];
};

export const updateBasketItemsGroupAnswers = (basketItemsGroup: IBasketItem[]) => {
  const answersToCopy = getAnswersToCopy(basketItemsGroup);

  if (answersToCopy.length) {
    return basketItemsGroup.map(basketItem => {
      const basketItemQuestionsIds = basketItem.variant?.questions?.map(question => question.id);
      const allAnswers = [
        ...basketItem.selections.answers,
        ...answersToCopy
      ];

      if (!basketItemQuestionsIds) {
        return basketItem;
      }

      return {
        ...basketItem,
        selections: {
          ...basketItem.selections,
          answers: getUniqueAnswers(allAnswers, basketItemQuestionsIds)
        }
      };
    });
  }

  return basketItemsGroup;
};

export const getValidDates = (basketItemsGroup: IBasketItem[], basketItem: IBasketItem) => {
  const {variantId} = basketItem;
  const basketItemVariantGroup = basketItemsGroup.filter(basketItem => basketItem.variantId === variantId);
  const basketItemWithVariantGroupDates = basketItemVariantGroup.find(
    basketItem => !!basketItem.selections?.dates?.length
  );
  const basketItemWithActivityGroupDates = basketItemsGroup.find(basketItem => !!basketItem.selections?.dates?.length);

  if (basketItemWithVariantGroupDates) {
    return basketItemWithVariantGroupDates.selections.dates;
  }

  return basketItemWithActivityGroupDates?.selections?.dates || [];
};

export const updateBasketItemsGroupDates = (basketItemsGroup: IBasketItem[]) => {
  const basketItemsUpdated: IBasketItem[] = basketItemsGroup.map(
    basketItem => ({
      ...basketItem,
      selections: {
        ...basketItem.selections,
        dates: getValidDates(basketItemsGroup, basketItem)
      }
    })
  );

  return basketItemsUpdated;
};

export const prepareUpsellItemsSelections = (basketItems: IBasketItem[]) => {
  const basketItemsVariants = [...new Set(basketItems.map(basketItem => basketItem.activityId))];
  let baksetUpdated: IBasketItem[] = [];

  basketItemsVariants.forEach(activityId => {
    const basketItemsGroup = basketItems.filter(basketItem => basketItem.activityId === activityId);

    if (basketItemsGroup[0].isUpsell) {
      const basketItemsWithNewDates = updateBasketItemsGroupDates(basketItemsGroup);
      const basketItemsWithNewAnswers = updateBasketItemsGroupAnswers(basketItemsWithNewDates);

      baksetUpdated = [
        ...baksetUpdated,
        ...basketItemsWithNewAnswers
      ];
    } else {
      baksetUpdated = [
        ...baksetUpdated,
        ...basketItemsGroup
      ];
    }
  });

  return baksetUpdated;
};

export const getDefaultSelectedDate = (admissionDate: AdmissionPolicyDate | null) => {
  if (!admissionDate) {
    return null;
  }

  const {numberOfSeats} = admissionDate;

  if (!numberOfSeats) {
    return null;
  }

  return admissionDate;
};

/**
 * Returns initialized basket items
 * @param {IActivity} activity - activity object
 * @param {Variant} variant - variant object
 * @param {IBasketItemSelections | undefined} oldSelections - old basket item selections
 * @return {IBasketItemSelections} basket item selections
 */
export const createBasketItemSelections = (
  activity: IActivityProcessed,
  variant: IActivityVariantProcessed,
  oldSelections?: IBasketItemSelections
) => {
  const {passType, admissionPolicy} = activity;
  const {
    startDatetime,
    minNumberOfParticipants,
    admissionDate
  } = admissionPolicy;
  const numberOfDays = variant?.numberOfAdmissionDatesMin || null;
  const validSince = isActivityHotelNarrowed(passType) ?
    startDatetime || getStartOf(createCurrentDate(), 'day') : null;
  const numberOfSeats = variant?.minNumberOfSeats ? oldSelections?.numberOfSeats ?? 1 : null;
  const numberOfParticipants = minNumberOfParticipants ? oldSelections?.numberOfParticipants ?? 1 : null;

  return {
    date: null,
    time: null,
    dates: null,
    validSince,
    validUntil: null,
    numberOfParticipants,
    numberOfSeats,
    numberOfDays,
    numberOfHotelNights: 1,
    answers: [],
    additions: [],
    discount: null,
    defaultDate: getDefaultSelectedDate(admissionDate)
  };
};

export const isBasketItemWithDefaultDate = (basketItem: IBasketItem) => !!basketItem?.selections?.defaultDate;

export const isDefaultDateLimitReached = (activityBasketItems: IBasketItem[]) => {
  const defaultDateBasketItems = activityBasketItems.filter(isBasketItemWithDefaultDate);

  if (!defaultDateBasketItems.length) {
    return false;
  }

  const {defaultDate} = defaultDateBasketItems[0].selections;
  const numberOfSeats = defaultDate?.numberOfSeats;
  const basketItemsAmount = defaultDateBasketItems.length;

  if (!numberOfSeats) {
    return true;
  }

  if (basketItemsAmount >= numberOfSeats) {
    return true;
  }

  return false;
};
