import {CheckoutView} from '@Components/checkout/types';
import {IBasketItem} from '@Consts/types';
import {isActivitySinglePickDate} from '@Utils/activity/activity';
import {getBasketItemNumberOfAddmissionDates} from '@Utils/basket';
import {shouldCreateView, shouldMerge} from './checkoutViews';
import {ECheckoutViewsTypes} from './checkoutViewsConsts';
import {itemHasDates, noDefaultDate, noViewForSameDatesNumber} from './requirements';

export const createAdmissionDatesViews = (basketItems: IBasketItem[]) => {
  const creationRequirements = [itemHasDates, noViewForSameDatesNumber, noDefaultDate];
  const mergingRequirements = [itemHasDates, noDefaultDate];

  return basketItems.reduce((creations: CheckoutView[], basketItem: IBasketItem) => {
    if (itemHasDates({basketItem})) {
      const {
        numberOfAdmissionDatesMin,
        numberOfAdmissionDatesMax
      } = getBasketItemNumberOfAddmissionDates(basketItem);

      if (shouldCreateView({
        creations,
        basketItems,
        basketItem,
        requires: creationRequirements,
        // TS creates type inference and requires fields to be provided
        questions: undefined,
        question: undefined
      })) {
        const passType = basketItem.activity.passType;
        const type = isActivitySinglePickDate(passType) ? ECheckoutViewsTypes.DATES : ECheckoutViewsTypes.TIMES;

        creations.push({
          type,
          numberOfAdmissionDatesMin,
          numberOfAdmissionDatesMax,
          items: [basketItem]
        });
      } else if (shouldMerge({
        basketItem,
        requires: mergingRequirements,
        creations
      })) {
        const relevantCreationId = creations.findIndex(creation =>
          creation.numberOfAdmissionDatesMin === numberOfAdmissionDatesMin &&
        creation.numberOfAdmissionDatesMax === numberOfAdmissionDatesMax
        );

        creations[relevantCreationId].items.push(basketItem);
      }
    }

    return creations;
  }, []);

};
