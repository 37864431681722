import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import FlexBox from '@Components/common/flexBox/FlexBox';
import {ETextColor} from '@Components/common/text/types';
import Text from '@Components/common/text/Text';
import locale from '@Utils/locale';
import classNames from 'classnames';
import Header from '@Components/common/header/Header';
import ActivityParticipants from './ActivityParticipants';
import {isActivitySinglePickDate, isActivitySinglePickDatetime} from '@Utils/activity/activity';
import {useSelector} from 'react-redux';
import pubsub from '@Utils/pubsub';
import {EVENT_VIEW_PARTICIPANTS_GROUP} from '@Utils/events';
import {getFacility} from '@Features/facility/facilitySelectors';
import {parseLanguage} from '@Utils/eventsUtils';
import {EFlexDirection} from '@Components/common/flexBox/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';

const ParticipantsGroup = ({
  activeView,
  onChangeView,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId
}) => {
  const facility = useSelector(getFacility);
  const {items: basketItems, id: viewId, activityBasketItems} = activeView;

  const {
    activity,
    variant: {maxNumberOfSeats, minNumberOfSeats},
    selections: {numberOfSeats, time, dates}
  } = basketItems[0];
  const {name: activityName, passType, salePolicy} = {...activity};
  const [sumOfAddedSeats, setSumOfAddedSeats] = useState(numberOfSeats);
  const [isParticipantsCounterError, setIsParticipantsCounterError] = useState(false);
  const isDatePass = isActivitySinglePickDate(passType) || isActivitySinglePickDatetime(passType);
  const isNotValidSeats = !numberOfSeats || numberOfSeats < minNumberOfSeats || numberOfSeats > maxNumberOfSeats;

  useEffect(() => {
    pubsub.trigger(EVENT_VIEW_PARTICIPANTS_GROUP, {
      facilityId: facility.id,
      facilityName: facility.name,
      language: parseLanguage(locale.locale),
      basketItems: JSON.stringify(basketItems)
    });
  }, []);

  useEffect(() => {
    setSumOfAddedSeats(activityBasketItems.reduce(
      (seats, basketItem) => seats + (basketItem.selections.numberOfSeats || basketItem.variant.numberOfSeats), 0)
    );
  }, [activityBasketItems]);

  useEffect(() => {
    if (requestedCheckoutPageId && !isNotValidSeats && !isParticipantsCounterError) {
      onChangeView(requestedCheckoutPageId);
    }
  }, [requestedCheckoutPageId]);

  function handleOnClosePage() {
    if (isNotValidSeats) {
      return;
    }

    return onPageClose();
  }

  function handleClickNext() {
    if (isNotValidSeats || isParticipantsCounterError) {
      return;
    }

    return onChangeView(viewId + 1);
  }

  const countAvailableSeats = () => {
    if (isActivitySinglePickDate(passType)) {
      const seats = dates.length > 0 ? dates[0].metaData.availableSeats - sumOfAddedSeats : 0;

      return seats >= 0 ? seats : 0;
    }

    if (isActivitySinglePickDatetime(passType)) {
      const seats = time.numberOfSeats - sumOfAddedSeats;

      return seats >= 0 ? seats : 0;
    }
  };

  return basketItems?.length ? (
    <FlexBox direction={EFlexDirection.COLUMN} className="dl-dotpay dl-dotpay__checkout-page">
      <div className="dl-dotpay__content">
        <div className="dl-mb-3 dl-dotpay__content--with-icon">
          <Text
            dl-test="for-chosen-text"
            color={ETextColor.LIGHT}
            wrap
          >
            {`${locale.translate('forChosen')} ${activityName}`}
          </Text>
          <div
            className={classNames(
              {
                'dl-dotpay--clickable dl-icon dl-icon--margin-correction dl-icon--arrow-up': fullCheckoutProgress
              })
            }
            onClick={handleOnClosePage}
          />
        </div>

        <div className="dl-mb-1">
          <Header>
            {locale.translate('enterNumberOfParticipants')}
          </Header>
        </div>

        {isDatePass && salePolicy?.canShowItemsLeft && (
          <div className="dl-mb-1">
            <Text dl-test="avaliable-text">
              {locale.translate('avaliableNumber', {number: countAvailableSeats()})}
            </Text>
          </div>
        )}

        <ul className="dl-mb-3 dl-additions" key={viewId}>
          {
            basketItems.map((item, index) => (
              <ActivityParticipants
                basketItem={item}
                variantViewId={index}
                key={`variant-additions-${index + 1}`}
                sumOfAddedSeats={sumOfAddedSeats}
                handleIsCounterError={setIsParticipantsCounterError}
              />
            ))
          }
        </ul>

        <div className="dl-mt-1">
          <Button
            onClick={handleClickNext}
            variant="contained"
            color="success"
            fullWidth
            endIcon={<ChevronRightIcon />}
          >
            {locale.translate('next')}
          </Button>
        </div>
      </div>
    </FlexBox>
  ) : null;
};

export default ParticipantsGroup;
