import {createBasketItemSelections} from '@Utils/basketUtils';
import {
  ADD_BASKET_ITEM,
  CLEAR_BASKET,
  UPDATE_BASKET_ITEMS_SELECTIONS,
  REMOVE_BASKET_ITEM,
  APPLY_BASKET_ITEMS_DISCOUNTS,
  RESET_BASKET_DISCOUNT,
  RESET_BASKET_SELECTIONS,
  UPDATE_BASKET_ITEMS,
  UPDATE_BASKET_ITEMS_ADMISSION_DATES,
  BLOCK_NEXT_STEP_WITH,
  UNBLOCK_NEXT_STEP_WITH
} from './basketActions';
import {updateBasket} from './basketUtils';

const initialState = {
  items: [],
  blockNextStepWith: []
};

const basketReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BASKET_ITEM: {
      const basketItem = action.payload.basketItem;

      return {
        ...state,
        items: [
          ...state.items,
          basketItem
        ]
      };
    }

    case UPDATE_BASKET_ITEMS_ADMISSION_DATES: {
      const {admissionDates, variantId} = action.payload;
      const basketItems = {...state};

      return {
        ...state,
        items: updateBasket(basketItems, admissionDates, variantId)
      };
    }

    case REMOVE_BASKET_ITEM: {
      const {activityId, variantId} = action.payload;
      const currentVariants = state.items.filter(
        item => item.activityId === activityId && item.variantId === variantId
      );
      const currentVariant = currentVariants[currentVariants.length - 1];
      const indexToRemove =
        state.items.findIndex(
          item => item.activityId === currentVariant.activityId &&
          item.variantId === currentVariant.variantId &&
          item.uid === currentVariant.uid
        );

      if (indexToRemove > -1) {
        const newItems = [...state.items];

        newItems.splice(indexToRemove, 1);

        return {
          ...state,
          items: newItems
        };
      }

      return state;
    }

    case UPDATE_BASKET_ITEMS_SELECTIONS: {
      const {basketItems, selections} = action.payload;
      const stateItems = [...state.items];

      basketItems.forEach(basketItem => {
        const matchingItemIndex = stateItems.findIndex(stateItem =>
          stateItem.uid === basketItem.uid &&
          stateItem.activityId === basketItem.activityId &&
          stateItem.variantId === basketItem.variantId
        );

        if (matchingItemIndex >= 0) {
          const previousSelections = stateItems[matchingItemIndex].selections;

          if (selections['answers']?.length) {
            const newAnswerQuestionId = selections.answers[0].questionId;
            const newAnswerText = selections.answers[0].answer;
            const previousAnswers = previousSelections.answers || [];
            const answerIndex = previousAnswers.findIndex(answer => answer.questionId === newAnswerQuestionId);

            if (answerIndex >= 0) {
              previousAnswers[answerIndex].answer = newAnswerText;

              stateItems[matchingItemIndex].selections = {
                ...previousSelections,
                answers: [...previousAnswers]
              };
            } else {
              stateItems[matchingItemIndex].selections = {
                ...previousSelections,
                answers: [...previousAnswers, ...selections.answers]
              };
            }

          } else {
            stateItems[matchingItemIndex].selections = {
              ...previousSelections,
              ...selections
            };
          }
        }
      });

      return {
        ...state,
        items: stateItems
      };
    }

    case UPDATE_BASKET_ITEMS: {
      const {basketItems} = action.payload;

      return {
        ...state,
        items: basketItems
      };
    }

    case APPLY_BASKET_ITEMS_DISCOUNTS: {
      let {discounts} = action.payload;

      discounts = discounts.map((discount, index) => ({...discount, discountId: index}));

      const updatedItems = [...state.items].reduce((allItems, basketItem) => {
        const discount = discounts.find(({activityId, variantId}) =>
          basketItem.activityId === activityId && basketItem.variantId === variantId
        );

        if (discount) {
          const usedDiscountId = discount.discountId;

          basketItem.selections.discount = discount;
          discounts = discounts.filter(discount => discount.discountId !== usedDiscountId);
        }

        return [...allItems, basketItem];
      }, []);

      return {
        ...state,
        items: updatedItems
      };
    }

    case RESET_BASKET_SELECTIONS:
      return {
        ...state,
        items: [...state.items].map(item => ({
          ...item,
          selections: createBasketItemSelections(item.activity, item.variant, item.selections)
        }))
      };

    case RESET_BASKET_DISCOUNT:
      return {
        ...state,
        items: [...state.items].map(item => ({
          ...item,
          selections: {
            ...item.selections,
            discount: null
          }
        }))
      };

    case CLEAR_BASKET:
      return {
        ...state,
        items: []
      };

    case BLOCK_NEXT_STEP_WITH: {
      const {variantId} = action.payload;

      return {
        ...state,
        blockNextStepWith: [...state.blockNextStepWith, variantId]
      };
    }

    case UNBLOCK_NEXT_STEP_WITH: {
      const {variantId} = action.payload;

      return {
        ...state,
        blockNextStepWith: state.blockNextStepWith.filter(id => id !== variantId)
      };
    }

    default:
      return state;
  }
};

export default basketReducer;
