import {IBasketItem} from '@Consts/types';
import {TPreviewTicket} from './types';

export const getPreviewTickets = (basket: IBasketItem[]): TPreviewTicket[] =>
  basket.map((basket, index) => {
    const activityName = basket.activity.name;
    const variantName = basket.variant.name;

    const formattedName = variantName ? `activityName, ${variantName}` : activityName;

    return {
      ticketId: index,
      ticketFormattedName: formattedName,
      ticketFormattedId: 'DROP/TID/0000000000'
    };
  });
