import {IButtonCustomization} from '@Consts/apiGlobals';
import {createTheme} from '@mui/material';
import {COLOR_DARK, COLOR_LIGHT, TEXT_COLOR_DARK} from './styles';

declare module '@mui/material/styles' {
  interface Palette {
    defaultPrimary: string,
  }

  interface PaletteOptions {
    defaultPrimary: string;
  }

  interface TypeBackground {
    main: string,
    text: string,
  }
}

interface IGetUserTheme {
  primaryButton: IButtonCustomization
}

export const getUserTheme = ({primaryButton}: IGetUserTheme) => createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 670,
      lg: 768,
      xl: 1024
    }
  },
  palette: {
    primary: {
      main: primaryButton.backgroundColor,
      contrastText: primaryButton.textColor === TEXT_COLOR_DARK ? COLOR_DARK : COLOR_LIGHT
    },
    background: {
      main: '#f9f9fb',
      text: '#636367'
    },
    defaultPrimary: '#1976d2'
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'large'
      },
      styleOverrides: {
        sizeLarge: {
          fontSize: '15px'
        }
      }
    }
  }
});
