import React, {useEffect, useRef, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getNarrowedDateRangeBasedOnAdmission, isNarrowedDateRangeValid} from '@Utils/admissionDatesUtils';
import {useDispatch, useSelector} from 'react-redux';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import {isActivityMultipleHotelNarrowed} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import Text from '@Components/common/text/Text';
import Header from '@Components/common/header/Header';
import './date-range.scss';
import classNames from 'classnames';
import {ETagType, ETextColor} from '@Components/common/text/types';
import DlCalendar from '@Components/calendar/DlCalendar';
import {getFacility} from '@Features/facility/facilitySelectors';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import {getDatesDifference, getFormattedDate} from '@Utils/dates/timezoneDates';

const INVALID_INPUT_CLASSNAME = 'dl-dotpay__input--invalid';

const HotelNarrowedDate = ({
  activeView,
  onChangeView,
  description,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId
}) => {
  const dispatch = useDispatch(null);
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const calendarRef = useRef(null);
  const {items, id} = activeView;
  const basketItem = items[0];
  const {activity, selections} = basketItem;
  const {validSince, validUntil} = selections;
  const {admissionPolicy, passType} = activity;
  const {validSinceTime, validUntilTime} = admissionPolicy;
  const {timezone} = useSelector(getFacility);
  const {
    minDate,
    maxDate
  } = getNarrowedDateRangeBasedOnAdmission(admissionPolicy, timezone);
  const [sinceCalendarVisible, setSinceCalendarVisible] = useState(false);
  const [untilCalendarVisible, setUntilCalendarVisible] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        sinceCalendarVisible && setSinceCalendarVisible(false);
        untilCalendarVisible && setUntilCalendarVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef, sinceCalendarVisible, untilCalendarVisible]);

  if (requestedCheckoutPageId) {
    handleClick({requestedCheckoutPageId});
  }

  function handleOnClosePage() {
    const isPageCloseRequest = true;

    return handleClick({isPageCloseRequest});
  }

  function handleSinceInputClick() {
    removeDateRangeInputsErrorClass();
    setError(null);
    setSinceCalendarVisible(true);
    setUntilCalendarVisible(false);
  }

  function handleUntilInputClick() {
    removeDateRangeInputsErrorClass();
    setError(null);
    setSinceCalendarVisible(false);
    setUntilCalendarVisible(true);
  }

  function handleValidSinceChange(newValidSince) {
    const dateSelected = newValidSince;
    let numberOfHotelNights = null;

    if (isActivityMultipleHotelNarrowed(passType) && validUntil) {
      const numberOfNights = getDatesDifference(newValidSince, validUntil);

      if (numberOfNights >= 1) {
        numberOfHotelNights = numberOfNights;
      }
    }

    dispatch(updateBasketItemsSelections(
      items,
      {validSince: dateSelected, numberOfHotelNights}
    ));

    setSinceCalendarVisible(false);
  }

  function handleValidUntilChange(newValidUntil) {
    const dateSelected = newValidUntil;
    let numberOfHotelNights = null;

    if (isActivityMultipleHotelNarrowed(passType) && validSince) {
      const numberOfNights = getDatesDifference(validSince, newValidUntil);

      if (numberOfNights >= 1) {
        numberOfHotelNights = numberOfNights;
      }
    }

    dispatch(updateBasketItemsSelections(
      items,
      {validUntil: dateSelected, numberOfHotelNights}
    ));

    setUntilCalendarVisible(false);
  }

  function setDateRangeInputsErrorClass() {
    const $dateRangeInputs = formRef.current.querySelectorAll('.dl-date-range__picker');

    Array.from($dateRangeInputs).forEach($input => {
      $input.classList.add(INVALID_INPUT_CLASSNAME);
    });
  }

  function removeDateRangeInputsErrorClass() {
    const $dateRangeInputs = formRef?.current?.querySelectorAll('.dl-date-range__picker');

    if ($dateRangeInputs) {
      Array.from($dateRangeInputs).forEach($input => {
        $input.classList.remove(INVALID_INPUT_CLASSNAME);
      });
    }
  }

  function handleClick({isPageCloseRequest, requestedCheckoutPageId}) {
    const sinceInput = formRef.current.querySelector('[name="since"]');
    const untilInput = formRef.current.querySelector('[name="until"]');

    if (validSince && validUntil) {
      if (isNarrowedDateRangeValid(validSince, validUntil)) {

        if (isPageCloseRequest) {
          return onPageClose();
        }

        if (requestedCheckoutPageId) {
          return onChangeView(requestedCheckoutPageId);
        }

        onChangeView(id + 1);
      } else {
        setDateRangeInputsErrorClass();
        setError(locale.translate('endDateCantBeTheSameAsStart'));
      }
    } else {
      if (!validSince) {
        sinceInput.classList.add(INVALID_INPUT_CLASSNAME);
      }

      if (!validUntil) {
        untilInput.classList.add(INVALID_INPUT_CLASSNAME);
      }

      setError(locale.translate('setNarrowedDates'));
    }
  }

  return (
    <div className="dl-dotpay dl-dotpay__checkout-page" ref={formRef}>
      <div className="dl-dotpay__content">
        <div className="dl-mb-3 dl-dotpay__content--with-icon">
          <Text color={ETextColor.LIGHT} wrap>
            {locale.translate('forChosen')} {description}
          </Text>
          <div
            className={classNames(
              {
                'dl-dotpay--clickable dl-icon dl-icon--margin-correction dl-icon--arrow-up': fullCheckoutProgress
              })
            }
            onClick={handleOnClosePage}
          />

        </div>

        <div className="dl-mb-2">
          <Header>
            {locale.translate('insertHotelNight')}
          </Header>
        </div>

        <Text tagType={ETagType.P} className="dl-mb-2">
          {locale.translate('chooseHotelNightDescription')}
        </Text>

        <div className="dl-date-range" key={id}>
          <div className="dl-date-range__wrapper">
            <span className="dl-date-range__content-text" data-testid="from-text">
              {
                `${locale.translate('from')} ${locale.translate('hour')} 
                ${validSinceTime} 
                ${locale.translate('onDate', {date: ''})}`
              }
            </span>
            <div
              className="dl-date-range__content-wrapper"
              onClick={handleSinceInputClick}
              data-testid="since-input"
            >
              <input
                name="since"
                className="dl-date-range__picker"
                value={validSince ? getFormattedDate(validSince, 'DD.MM.YYYY') : ''}
                placeholder="DD.MM.YYYY"
                autoComplete="off"
                disabled
              />
              <CalendarMonthOutlinedIcon sx={{color: 'background.text'}} />
            </div>

            {sinceCalendarVisible &&
              <div className="calendar" ref={calendarRef}>
                <DlCalendar
                  value={validSince}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={handleValidSinceChange}
                />
              </div>
            }
          </div>

          <div className="dl-date-range__wrapper">
            <span className="dl-date-range__content-text" data-testid="until-text">
              {
                `${locale.translate('to')} ${locale.translate('hour')} 
                ${validUntilTime} 
                ${locale.translate('onDate', {date: ''})}`
              }
            </span>
            <div
              className="dl-date-range__content-wrapper"
              onClick={handleUntilInputClick}
              data-testid="until-input"
            >
              <input
                name="until"
                className="dl-date-range__picker"
                value={validUntil ? getFormattedDate(validUntil, 'DD.MM.YYYY') : ''}
                placeholder="DD.MM.YYYY"
                autoComplete="off"
                disabled
              />
              <CalendarMonthOutlinedIcon sx={{color: 'background.text'}} />
            </div>

            {untilCalendarVisible &&
              <div className="calendar" ref={calendarRef}>
                <DlCalendar
                  value={validUntil}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={handleValidUntilChange}
                />
              </div>
            }

          </div>
        </div>

        {
          error &&
          <Text tagType={ETagType.P} className="dl-my-3" color={ETextColor.ERROR}>{error}</Text>
        }

        <div className="dl-mb-3">
          <Button
            onClick={handleClick}
            variant="contained"
            color="success"
            fullWidth
            sx={{mt: 2}}
            endIcon={<ChevronRightIcon />}
          >
            {locale.translate('next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HotelNarrowedDate;
