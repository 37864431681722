/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'El rango seleccionado incluye fechas no disponibles',
  'PLN': 'zł',
  'EUR': '€',
  'validPostalCodeLength': 'Ingrese un código postal válido (por ejemplo, 00-000)',
  'minLength': 'La longitud mínima es: %{length}',
  'maxLength': 'La longitud máxima es: %{length}',
  'parcelLockerSelected': 'Punto de recogida seleccionado:',
  'selectParcelLocker': 'Seleccionar punto de recogida',
  'selectAnotherParcelLocker': 'Seleccionar otro punto de recogida',
  'parcelLockerPointError': 'No se pudo obtener una dirección válida del punto de recogida',
  'enterDeliveryPhoneNumber': 'Ingrese el número de teléfono para la entrega',
  'onlineMethodsDescription': 'Para otros servicios, entrega electrónica.',
  'shippingMethodRequired': 'Debe seleccionar un método de envío antes de proceder al pago.',
  'chooseShippingMethod': 'Elegir método de envío',
  'generateWithAmount': 'Generar (%{amountToPay})',
  'additionsHeader': 'Adiciones: %{activityName}',
  'additionsLimitReached': 'Se ha alcanzado el límite para las adiciones',
  'perPersonNumber': '%{number} pers. x',
  'selectedDayIsNotAvailable': 'El día %{date} no está disponible.',
  'selectedDateIsNotAvailable': 'La fecha %{date} no está disponible',
  'errorOccurredWhileFetchingOrder': 'Ocurrió un error al obtener el pedido. Si ya lo pagaste, revisa tu bandeja de entrada de correo electrónico.',
  'priceTooHigh': 'El valor de los servicios seleccionados es demasiado elevado. No es posible proceder al pago.',
  'invalidNip': 'El NIP no es válido',
  'orderCreationError': 'Ocurrió un error al crear el pedido, inténtalo de nuevo.',
  'activitiesFetchError': 'Ocurrió un error al cargar las actividades',
  'chooseLimitReached': 'Límite de piezas seleccionadas alcanzado',
  'localTours': 'Tours locales',
  'clauseAcceptanceRequired': 'Se requiere la aceptación de la cláusula',
  'invalidPostalCode': 'Código postal inválido',
  'invalidNIPLength': 'El NIP debe tener 10 dígitos',
  'invalidPhone': 'Número de teléfono no válido',
  'invalidEmail': 'Correo electrónico no válido',
  'invalidFormatError': 'Formato no válido',
  'toLongError': 'Longitud máxima: 30 caracteres',
  'toShortError': 'Longitud mínima: 3 caracteres',
  'invalidTypeError': 'Valor no válido',
  'missingFieldError': 'Campo faltante',
  'unknownError': 'Error desconocido',
  'errorScriptNotFound': 'Error: Tu navegador no es compatible o la tienda está incrustada incorrectamente',
  'addressRequired': 'Se requiere proporcionar la dirección',
  'regulationAcceptRequired': 'Se requiere la aceptación de los términos y condiciones y la política de privacidad',
  'regulationsAcceptRequired': 'Se requiere la aceptación de los términos y condiciones y la política de privacidad',
  'phoneRequired': 'Se requiere proporcionar un número de teléfono',
  'lastNameRequired': 'Se requiere proporcionar un apellido',
  'firstNameRequired': 'Se requiere proporcionar un nombre',
  'emailRequired': 'Se requiere proporcionar un correo electrónico',
  'answerRequired': 'Se requiere proporcionar una respuesta',
  'skippTheLine': 'Saltar la fila en la taquilla',
  'downloadInPreviewIsDisabled': 'En modo de vista previa, el pedido se enviará por correo electrónico, no se puede descargar directamente desde esta pantalla',
  'noValidDatesRange': 'No hay un rango válido de fechas',
  'chooseNumberOfDays': 'Elige el número de días',
  'day': 'día',
  'perPersonAbbr': 'pers.',
  'maxNumberOfParticipants': 'El número máximo de participantes es %{number}',
  'minNumberOfParticipants': 'El número mínimo de participantes es %{number}',
  'enterNumberOfParticipants': 'Ingrese el número de participantes',
  'numberOfParticipants': 'Número de participantes',
  'numberOfParticipantsBetween': 'Número de participantes: de %{min} a %{max}',
  'generate': 'Generar',
  'seats': 'pz.',
  'attention': '¡Atención!',
  'forSinglePerson': 'para 1 persona',
  'chooseAdditions': 'Elegir adiciones',
  'pleaseChooseOneOption': 'Por favor, elija una opción disponible',
  'or': 'o',
  'addToAppleWallet': 'Agregar a Apple Wallet',
  'realiseOrder': 'Realizar pedido',
  'generatingPdf': 'Generando PDF...',
  'transactionCompletedSuccessfully': '¡Transacción completada con éxito!',
  'immediatelyProceedThroughPayment': 'Una vez confirmado el pago, los boletos se cargarán a continuación y se enviarán a la dirección de correo electrónico %p',
  'optionally': 'opcionalmente',
  'shopOnline': 'Comprar en línea',
  'orderOnline': 'Ordenar en línea',
  'discountsAreDisabledInPreview': 'Los descuentos están deshabilitados en la vista previa',
  'unsuccessfullyOrderedSending': 'No se pudo enviar la confirmación del pedido a la dirección %{email}. Inténtelo de nuevo o escriba a pomoc@droplabs.pl.',
  'successfullyOrderedSending': 'Se ha ordenado el envío correctamente a la dirección %{email}',
  'requiredField': 'Campo obligatorio',
  'forSendingAcceptTos': 'Para el envío, el cliente acepta',
  'agreementFormWithClause': 'Formulario de acuerdo junto con la cláusula informativa',
  'send': 'Enviar',
  'downloadToPrint': 'Descargar para imprimir',
  'clientEmail': 'Correo electrónico del cliente',
  'chooseSendingConfirmationMethod': '¡Listo! Elija el método de confirmación de envío para el cliente',
  'orderPreviewInfo': 'Se ha enviado un pedido de ejemplo a la dirección de correo electrónico: %p. Si no ve el mensaje en unos minutos, revise la carpeta de spam. Si eso no ayuda, escriba \'Droplabs\' en el motor de búsqueda de su programa de correo.',
  'exceededTimeout': 'Se excedió el tiempo de espera para el pago',
  'buyerWillSettleWithYou': 'La persona que utilizará los servicios de %p se pondrá en contacto contigo para resolver el pago.',
  'youCanPrintTickets': 'Después de la compra, puedes imprimir los boletos listos para usar. Los boletos no mostrarán el precio.',
  'onlinePaymentForOrder': 'Debes pagar en línea por el pedido. La factura será emitida por %p para tu empresa por el período acordado, generalmente mensualmente.',
  'youWillPayForOrderBasedOnInvoice': 'Debes pagar por el pedido según la factura emitida por %p para tu empresa por el período acordado, generalmente mensualmente.',
  'wholeAmountWillBeChargedForUSed': 'El monto total será cobrado por %p solo por los boletos utilizados. %p puede aplicar un cargo adicional, incluso por los boletos no utilizados.',
  'youCanPrintReservationConfirmation': 'Después de hacer una reserva, puedes imprimir la confirmación de la misma de inmediato.',
  'paymentWillBeChargedOnSite': 'El monto total del pedido deberá pagarse en el lugar al momento de la ejecución del servicio.',
  'missingFacilityId': 'La tienda está incrustada incorrectamente',
  'cannotLoadFacility': 'No se puede recuperar la facilidad %p',
  'add': 'Agregar',
  'choose': 'Elegir',
  'choosing': 'Eleccionando',
  'hide': 'Ocultar',
  'expand': 'Ampliar',
  'menuDetails': 'Detalles',
  'menuSummary': 'Pagar',
  'menuSummaryThanks': '¡Gracias por realizar tu pedido!',
  'noActivitiesInSales': 'No hay actividades en venta. ¡Vuelve pronto!',
  'hours': 'horas',
  'minutes': 'minutos',
  'goToTheNextStep': 'Continúa',
  'sellerOfActivities': 'El vendedor de las actividades es %{name}.',
  'ifYouNeedHelpAssociatedWithOffer': '¿Necesitas ayuda con la oferta? Contacta a %p.',
  'saleSupportedByDroplabs': 'Droplabs: Tienda en línea, sistema de venta y distribución para servicios, boletos, vales y pases.',
  'ifYouNeedTechnicalSupport': '¿Necesitas soporte técnico? Contacta a %p',
  'activityAdmissionDateLabel': 'Fecha de admisión para la actividad: %{name}',
  'chooseDayOfUseActivities': 'Elige el día para usar las actividades',
  'chooseTimeOfUseActivities': 'Elige la hora para usar las actividades',
  'chooseNarrowedTimeOfUseActivities': 'Elige un tiempo específico para usar las actividades',
  'chooseTime': 'Elige una hora',
  'chooseEmailForSendingTickets': '¿A qué dirección de correo electrónico enviar los tickets de confirmación?',
  'email': 'Correo electrónico',
  'name': 'Nombre',
  'buyersName': 'Nombre del comprador',
  'surname': 'Apellido',
  'buyersSurname': 'Apellido del comprador',
  'goToThePayment': 'Ir al pago',
  'orderFreeTickets': 'Ordenar boletos gratuitos',
  'buyWithoutPayment': 'Comprar sin pago',
  'businessTicketsHaveBeenSentIntoEmail': 'Las entradas han sido enviadas al correo electrónico: %p |||| Las entradas han sido enviadas al correo electrónico: %p',
  'checkSpamInCaseOfNotReceivingEmail': 'Si no ves el mensaje en unos minutos después de realizar el pago, revisa la carpeta de spam. Si no ayuda, busca \'Droplabs\' en tu programa de correo.',
  'buyAnotherActivities': 'Comprar otras actividades',
  'noFixedDatesForChosenActivities': 'Lamentablemente, no hay fechas fijas disponibles para las actividades seleccionadas.',
  'forChosen': 'Para la selección:',
  'noFixedDatesForChosenPeriod': 'No hay fechas fijas disponibles para este período.',
  'noFixedDatesOnDay': 'No hay fechas fijas disponibles el %{date}.',
  'noFixedDatesBetweenDates': 'No hay fechas fijas disponibles entre el %{since} y el %{until}.',
  'noFixedDatesBeforeDate': 'No hay fechas fijas disponibles antes del %{date}.',
  'noFixedDatesAfterDate': 'No hay fechas fijas disponibles después del %{date}.',
  'next': 'Siguiente',
  'back': 'Atrás',
  'onWebsite': 'en el sitio web',
  'january': 'Enero',
  'february': 'Febrero',
  'march': 'Marzo',
  'april': 'Abril',
  'may': 'Mayo',
  'june': 'Junio',
  'july': 'Julio',
  'august': 'Agosto',
  'september': 'Septiembre',
  'october': 'Octubre',
  'november': 'Noviembre',
  'december': 'Diciembre',
  'sundayShort': 'Do',
  'sunday': 'Domingo',
  'mondayShort': 'Lu',
  'monday': 'Lunes',
  'tuesdayShort': 'Ma',
  'tuesday': 'Martes',
  'wednesdayShort': 'Mi',
  'wednesday': 'Miércoles',
  'thursdayShort': 'Ju',
  'thursday': 'Jueves',
  'fridayShort': 'Vi',
  'friday': 'Viernes',
  'saturdayShort': 'Sá',
  'saturday': 'Sábado',
  'canNotBuyMoreTickets': 'No se pueden comprar más entradas. No hay lugares disponibles.',
  'willEndSoon': '¡Terminará pronto!',
  'itemsLeft': '¡Quedan %{limit}!',
  'willEndBefore': '¡Oferta hasta el %{date}!',
  'soldOut': '¡Agotado!',
  'bookingRequired': 'Se requiere reserva después de la compra',
  'bookingAfterBuy': 'Tras la compra, recibirás %p para reservar',
  'phone': 'teléfono',
  'website': 'sitio web',
  'bookingEmail': 'correo electrónico para reserva',
  'more': 'más',
  'less': 'menos',
  'from': 'De',
  'durationUnlimitedSentence': 'Sin límite de tiempo',
  'durationUnlimited': 'sin límite de tiempo',
  'useNumber': 'Cantidad de usos',
  'useDatetime': 'Realización',
  'betweenDates': 'de %{since} a %{until}',
  'onDate': 'el %{date}',
  'validBeforeDate': 'hasta el %{date}',
  'validInDays': 'hasta %{number}',
  'today': 'hoy',
  'numberOfDays': 'día |||| días',
  'activeInDays': 'Validez: %{number} días desde la activación',
  'activationInDays': 'Activación en el lugar %{days} después de la compra',
  'activationDates': 'Activación en el lugar %{dates}',
  'pickFixedDateInTheNextStep': 'Compruebe todas las fechas disponibles en el siguiente paso',
  'dateOfUse': 'Fecha de uso',
  'withDiscountLabel': '¿Tienes un código de descuento?',
  'optional': 'opcional',
  'discountCode': 'Código de descuento',
  'useDiscountCode': 'Usar código',
  'discountUsed': 'Código de descuento activado (%{code}):',
  'invalidDiscountCode': 'El código ingresado (%{code}) no reduce el precio de los servicios seleccionados.',
  'discountCodeNotFound': 'El código ingresado (%{code}) ha caducado, es incorrecto o ya ha sido utilizado.',
  'discountAdditionsNotIncluded': 'Los descuentos no se aplican a los complementos.',
  'notEligibleDiscountTickets': 'Los demás boletos no son elegibles para este código.',
  'close': 'cerrar',
  'tryAgain': 'inténtalo de nuevo',
  'phoneNumber': 'Número de teléfono',
  'contactPhoneNumber': 'Número de teléfono de contacto',
  'clientPhoneNumber': 'Número de teléfono del cliente',
  'restCountries': 'Otros países',
  'sellerIs': 'El vendedor es',
  'administratorOfThePersonalData': 'El administrador de los datos personales es',
  'sellerCopy':
    `Tu información será procesada, especialmente para ejecutar el contrato, es decir, para permitirte comprar el boleto, según el artículo 6, párrafo 1, letra b del RGPD.
    Tienes derecho a acceder, corregir, eliminar, restringir el procesamiento, portabilidad de datos, oponerte y presentar una queja ante la autoridad supervisora.
    Los datos no estarán sujetos a decisiones automatizadas, incluido el perfilado.`,
  'infoAboutPrivacyPolicy': 'El texto completo de la cláusula informativa está disponible aquí',
  'businessSellerCopy': 'El vendedor es',
  'privacyPolicy': 'Política de privacidad',
  'privacyPolicyAccept': 'Acepto la política de privacidad',
  'termsOfServiceAgreement': 'He leído y acepto los',
  'termsOfService': 'Términos del servicio',
  'inTermsOfService': 'en los Términos de servicio',
  'inPrivacyPolicy': 'en la Política de privacidad',
  'and': 'y',
  'acceptedTermsOfServiceIn': 'Los términos de servicio aceptados se encuentran en',
  'facilityTermsOfService': 'Términos de %{facilityName}',
  'activitiesLimitExceeded': 'Alguien te superó, mientras tanto la cantidad de boletos disponibles ha disminuido. Elige otros.',
  'invalidDataOrCharacters': 'Los datos son inválidos o contienen caracteres no permitidos. Corrígelos e inténtalo de nuevo.',
  'invalidAdmissionDate': 'La fecha es inválida.',
  'internalErrorMessage': 'Se produjo un error interno, inténtalo de nuevo más tarde',
  'widgetDisabled': 'La venta en línea está actualmente deshabilitada.',
  'bubbleDisabled': '¡Venta deshabilitada!',
  'buyOnline': '¡Compra en línea!',
  'buyVoucher': 'Compra un vale',
  'buyTicket': '¡Compra un boleto!',
  'bookingOnline': 'Reserva en línea',
  'buySkipass': 'Compra un skipass',
  'buyCourse': 'Compra un curso',
  'iWantInvoice': 'Quiero recibir una factura',
  'address': 'Calle y número / local',
  'city': 'Ciudad',
  'postalCode': 'Código postal',
  'companyName': 'Nombre de la empresa',
  'requiredOnlyForInvoices': 'Solo obligatorio para facturas de empresa',
  'companyNIP': 'NIP de la empresa',
  'optionalSentence': 'Opcional',
  'invalidPrice': 'No se puede proceder al pago porque el precio visible difiere del real. Por favor, actualiza la página para obtener datos actualizados.',
  'paymentDelayedInfo': 'El pago del pedido debe hacerse mediante una factura de IVA, que será emitida por %{companyName}.',
  'oncePerDay': 'Una entrada por día',
  'withoutLimit': 'Sin límite diario de entradas',
  'ticketsReady': '¡El boleto está listo! |||| ¡Los boletos están listos!',
  'pleaseWait': 'Por favor, espera...',
  'downloadTickets': 'Descargar boleto |||| Descargar boletos',
  'download': 'Descargar',
  'loadingTickets': '¡Tus boletos se están preparando!',
  'doOrder': 'Pedir',
  'doReservation': 'Reservar',
  'generateExampleOrder': 'Generar pedido de ejemplo',
  'hotelNight': 'noche',
  'chooseHotelNight': 'Elige el período de estancia nocturna',
  'insertHotelNight': 'Ingresa el período de estancia nocturna',
  'chooseHotelNightDescription': 'Los boletos son válidos solo durante el período de estancia nocturna de los huéspedes.',
  'setNarrowedDates': 'Debes seleccionar la fecha de inicio y fin de la estancia.',
  'endDateCantBeTheSameAsStart': 'La fecha de finalización de la estancia debe ser al menos un día después de la fecha de inicio.',
  'save': 'Guardar',
  'insertYear': 'Ingresa el año',
  'hour': 'hora',
  'realiseInDay': 'Realización el día %{date}',
  'realiseInDateWithTime': 'Realización el día %{date}, seleccionarás la hora en el próximo paso.',
  'missingPaymentProvider': 'El vendedor aún no ha activado la recepción de pagos. Inténtalo nuevamente pronto.',
  'seeOtherActivities': 'Ver otras actividades',
  'hideOtherActivities': 'Ocultar otras actividades',
  'givenDateIsSoldOut': 'Las plazas para el %{date} se han agotado',
  'isOutOfLimit': 'No hay suficientes plazas para la hora %{time}.',
  'setDifferentHour': 'El número de plazas disponibles para el %{time} es: %{numberOfSeats}, por favor seleccione otra hora o fecha',
  'givenTimeIsSoldOut': 'Las plazas para las %{time} se han agotado',
  'hasNoSeatsForGivenDate': 'No hay suficientes plazas para el %{date}.',
  'setDifferentDate': 'El número de plazas disponibles para las %{date} es: %{availableSeats}, por favor seleccione otra hora o fecha',
  'seatsSoldOut': 'agotadas',
  'hasNoSeats': 'no hay plazas',
  'chooseDifferentDate': 'Elige una fecha diferente',
  'actuallySoldOut': '¡Agotado!',
  'noDates': '¡Actualmente no hay fechas disponibles!',
  'customerData': 'Datos del cliente',
  'optionalText': 'opcional',
  'numberOfParticipantsLabel': 'Número de participantes: %{name}',
  'avaliableNumber': 'Plazas disponibles: %{number}',
  'filter': 'Filtrar',
  'clear': 'Limpiar',
  'checkItOut': 'Echar un vistazo',
  'remove': 'Eliminar',
  'thisMayInterestYou': 'Esto puede interesarte',
  'requiredActivityVariantsNotPresentInOrder': 'Se requiere una variante diferente para la compra. Vuelve a la lista de servicios y selecciona la variante adecuada.',
  'requiredActivityVariants': 'Faltan algunas actividades en tu pedido.',
  'backTo': 'Volver a',
  'addSmall': 'añada',
  'requiredActivity': 'actividad requerida',
  'oneOfRequiredActivity': 'al menos una de las actividades requeridas',
  'oneOfRequiredActivityVariants': 'al menos una de las opciones de servicio requeridas',
  'to': 'Para',
  'marketingConsentsAgreement': 'Deseo suscribirme al boletín (opcional)',
  'marketingConsentsPromo': 'Mantente actualizado con todas las promociones e información, ¡solo enviamos lo más importante, prometemos nada de spam!',
  'setAllConsents': 'Seleccionar todo',
  'InvalidNipFormat': 'Formato de NIP inválido',
  'numberOfPeopleInGroup': 'Número de personas en el grupo',
  'paymentFormOutside': 'Forma de pago fuera de Droplabs',
  'other': 'Otro',
  'paymentTerminal': 'Terminal de pago',
  'cash': 'Efectivo',
  'bankTransfer': 'Transferencia bancaria',
  'prefix': 'Prefijo',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Fecha límite de pago demasiado corta',
  'paymentCompleteUntilDaysLimitExceeded': 'Fecha límite de pago demasiado larga',
  'setPaymentDate': 'Establecer fecha de pago',
  'setPaymentDateDesc': 'Si el cliente no paga en el plazo indicado, las plazas volverán a estar disponibles.',
  'newOrder': 'Crear un nuevo pedido',
  'LastStep': 'Último paso...',
  'copyLinkAndSendToCustomer': 'Copia el enlace y envíaselo al cliente para que pague el pedido.',
  'orClick': 'o haz clic en',
  'CopyLink': 'Copiar enlace',
  'paymentOutside': 'Pago fuera de Droplabs',
  'paymentOnline': 'Pago en línea',
  'cancel': 'Cancelar',
  'setDateAndTime': 'Seleccionar fecha y hora',
  'privatePerson': 'Persona privada',
  'company': 'Empresa',
  'invoiceOptionalNote': 'Nota para la factura (opcional)',
  'youWillReceiveInvoiceSimplified': 'Recibirás un recibo con NIF que sirve como factura simplificada.',
  'nameOptional': 'Nombre (opcional)',
  'surnameOptional': 'Apellido (opcional)',
  'emailOptional': 'Correo electrónico (opcional)',
  'isExpired': 'ha caducado',
  'isAdmissionDateInvalid': 'se refiere a un plazo de entrega diferente',
  'isInvalid': 'es inválido',
  'isUsed': 'ha sido usado',
  'buyerData': 'Datos del comprador',
  'requiredEmailAlert': 'Recuerda proporcionar el correo electrónico del cliente, de lo contrario, no recibirá la factura.',
  'issueAnInvoice': 'Emitir factura',
  'areYouLeaving': '¿Te vas?',
  'exitConfirmationComment': 'Al comprar ahora, se le garantiza un precio sin cambios. Si lo abandonas, perderás los pasos que hayas completado hasta el momento.',
  'abandonPurchase': 'Abandonar compra',
  'continueShopping': 'Continuar comprando',
  'thisFeatureIsNotEnabled': 'Esta función no está activada',
  'thisFeatureIsNotEnabledSolution': '¡No te preocupes! Contáctanos en %p, te ayudaremos a encontrar el plan adecuado.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Por favor, espera',
  'waitingForPaymentConfirmation': 'Esperando la confirmación del pago.',
  'paymentProcessInterrupted': 'Si el proceso de pago se interrumpió, puedes pagar el pedido',
  'clickHere': 'haciendo clic aquí',
  'ticketsLoadedAndSentToEmail': 'Las entradas se cargarán a continuación y se enviarán por correo electrónico a:',
  'paymentAccepted': 'Pago aceptado',
  'orderNumber': 'Número de pedido:',
  'orderSendOnEmail': 'Confirmación del pedido enviada al correo electrónico:',
  'emailNotGetThrough': '¿No ha llegado el correo?',
  'emailNotGetThroughTooltip': 'Si no recibes el correo en unos minutos, revisa la carpeta de spam. Si no aparece, busca \'Droplabs\' en tu programa de correo.',
  'orderCreated': 'Pedido creado',
  'generatingTickets': 'Generando entradas',
  'generatingTicketsDescription': 'Pronto podrás descargar tu boleto y agregarlo a tu billetera virtual. También hemos enviado una copia de este boleto a la dirección de correo electrónico proporcionada.',
  'everythingDone': '¡Todo listo!',
  'ticketsReadyToDownload': 'Las entradas están listas para descargar. Haz clic en el botón para descargarlas.',
  'pdfDocumentIsReady': '¡Éxito, PDF listo!',
  'clickForPdfDocument': 'El PDF está listo para descargar. Haz clic en el botón para obtenerlo.',
  'pdfDocumentIsGenerating': 'Generando documento PDF',
  'pdfDocumentIsGeneratingDescription': 'La generación del PDF puede tomar un momento. No es necesario que esperes.',
  'generating': 'Generando...',
  'sendOrder': 'Enviar pedido',
  'sendOrderDescription': 'Indica la dirección de correo electrónico a la que debe enviarse el pedido junto con la entrada.',
  'orderSendedOnEmail': 'El pedido se ha enviado a la dirección de correo electrónico proporcionada',
  'orderSomethingElse': '¿Quieres pedir algo más?',
  'actionDisabledInPreviewMode': 'Esta acción no está disponible, esta vista es solo para previsualización.',
  'anotherRecipient': 'Quiero proporcionar otro destinatario',
  'recipientName': 'Nombre del destinatario',
  'digitalShippingMethod': 'Método de envío digital a la dirección de correo electrónico especificada',
  'somethingIsWrong': 'Algo salió mal...',
  'waitingForPaymentError': 'El pedido fue cancelado o se agotó el tiempo para pagarlo. Genera un nuevo pedido o vuelve a intentar el pago.',
  'waitingForPayment': 'Esperando el pago',
  'waitingForPaymentDesc': 'El monto del pedido se ha enviado al terminal Viva Wallet. Acepta el pago tan pronto como aparezca el monto en la pantalla del terminal. Si no deseas aceptar el pago, cancela la transacción.',
  'sendAgain': 'Enviar de nuevo',
  'paymentRepeatError': 'No se pudo repetir el pago',
  'paymentRepeatSuccess': 'El pago se repitió correctamente',
  'rePayment': 'Repetir pago',
  'setTicketAsUsed': 'Marcar como usado',
  'setTicketAsUsedDescription': 'Marca como utilizadas todas las entradas recién generadas, válidas para el día de hoy.',
  'useTickets': 'Marcar como usado',
  'useTicketsSuccess': 'Las entradas se han marcado como utilizadas correctamente.',
  'useTicketsError': 'No se pueden marcar las entradas como utilizadas.',
  'mismatchedTicketDate': 'El pedido es para una fecha de uso diferente.',
  'ticketInactive': 'El pedido no está activo.',
  'useTicketsErrorMoreDetails': 'Puedes ver los detalles del pedido yendo a las secciones \'Servicio al cliente\' o \'Control de acceso\'.',
  'country': 'País',
  'notSavedData': 'Los datos no se guardarán',
  'notSavedDataDesc': 'Si ingresaste información adicional, recuerda que al seleccionar actividades, perderás esta información.',
  'changeActivities': 'Cambiar actividades',
  'AD': 'Andorra',
  'AE': 'Emiratos Árabes Unidos',
  'AF': 'Afganistán',
  'AG': 'Antigua y Barbuda',
  'AI': 'Anguila',
  'AL': 'Albania',
  'AM': 'Armenia',
  'AO': 'Angola',
  'AQ': 'Antártida',
  'AR': 'Argentina',
  'AS': 'Samoa Americana',
  'AT': 'Austria',
  'AU': 'Australia',
  'AW': 'Aruba',
  'AX': 'Islas Åland',
  'AZ': 'Azerbaiyán',
  'BA': 'Bosnia y Herzegovina',
  'BB': 'Barbados',
  'BD': 'Bangladés',
  'BE': 'Bélgica',
  'BF': 'Burkina Faso',
  'BG': 'Bulgaria',
  'BH': 'Baréin',
  'BI': 'Burundi',
  'BJ': 'Benín',
  'BL': 'San Bartolomé',
  'BM': 'Bermudas',
  'BN': 'Brunéi',
  'BO': 'Bolivia',
  'BQ': 'Caribe neerlandés',
  'BR': 'Brasil',
  'BS': 'Bahamas',
  'BT': 'Bután',
  'BV': 'Isla Bouvet',
  'BW': 'Botsuana',
  'BY': 'Bielorrusia',
  'BZ': 'Belice',
  'CA': 'Canadá',
  'CC': 'Islas Cocos',
  'CD': 'República Democrática del Congo',
  'CF': 'República Centroafricana',
  'CG': 'Congo',
  'CH': 'Suiza',
  'CI': 'Costa de Marfil',
  'CK': 'Islas Cook',
  'CL': 'Chile',
  'CM': 'Camerún',
  'CN': 'China',
  'CO': 'Colombia',
  'CR': 'Costa Rica',
  'CU': 'Cuba',
  'CV': 'Cabo Verde',
  'CW': 'Curazao',
  'CX': 'Isla de Navidad',
  'CY': 'Chipre',
  'CZ': 'República Checa',
  'DE': 'Alemania',
  'DJ': 'Yibuti',
  'DK': 'Dinamarca',
  'DM': 'Dominica',
  'DO': 'República Dominicana',
  'DZ': 'Argelia',
  'EC': 'Ecuador',
  'EE': 'Estonia',
  'EG': 'Egipto',
  'EH': 'Sahara Occidental',
  'ER': 'Eritrea',
  'ES': 'España',
  'ET': 'Etiopía',
  'FI': 'Finlandia',
  'FJ': 'Fiyi',
  'FK': 'Islas Malvinas',
  'FM': 'Micronesia',
  'FO': 'Islas Feroe',
  'FR': 'Francia',
  'GA': 'Gabón',
  'GB': 'Reino Unido',
  'GD': 'Granada',
  'GE': 'Georgia',
  'GF': 'Guayana Francesa',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GL': 'Groenlandia',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadalupe',
  'GQ': 'Guinea Ecuatorial',
  'GR': 'Grecia',
  'GS': 'Islas Georgias del Sur y Sandwich del Sur',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bisáu',
  'GY': 'Guyana',
  'HK': 'Hong Kong',
  'HM': 'Islas Heard y McDonald',
  'HN': 'Honduras',
  'HR': 'Croacia',
  'HT': 'Haití',
  'HU': 'Hungría',
  'ID': 'Indonesia',
  'IE': 'Irlanda',
  'IL': 'Israel',
  'IM': 'Isla de Man',
  'IN': 'India',
  'IO': 'Territorio Británico del Océano Índico',
  'IQ': 'Iraq',
  'IR': 'Irán',
  'IS': 'Islandia',
  'IT': 'Italia',
  'JE': 'Jersey',
  'JM': 'Jamaica',
  'JO': 'Jordania',
  'JP': 'Japón',
  'KE': 'Kenia',
  'KG': 'Kirguistán',
  'KH': 'Camboya',
  'KI': 'Kiribati',
  'KM': 'Comoras',
  'KN': 'San Cristóbal y Nieves',
  'KP': 'Corea del Norte',
  'KR': 'Corea del Sur',
  'KW': 'Kuwait',
  'KY': 'Islas Caimán',
  'KZ': 'Kazajistán',
  'LA': 'Laos',
  'LB': 'Líbano',
  'LC': 'Santa Lucía',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Liberia',
  'LS': 'Lesoto',
  'LT': 'Lituania',
  'LU': 'Luxemburgo',
  'LV': 'Letonia',
  'LY': 'Libia',
  'MA': 'Marruecos',
  'MC': 'Mónaco',
  'MD': 'Moldavia',
  'ME': 'Montenegro',
  'MF': 'San Martín',
  'MG': 'Madagascar',
  'MH': 'Islas Marshall',
  'MK': 'Macedonia del Norte',
  'ML': 'Malí',
  'MM': 'Birmania',
  'MN': 'Mongolia',
  'MO': 'Macao',
  'MP': 'Islas Marianas del Norte',
  'MQ': 'Martinica',
  'MR': 'Mauritania',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauricio',
  'MV': 'Maldivas',
  'MW': 'Malaui',
  'MX': 'México',
  'MY': 'Malasia',
  'MZ': 'Mozambique',
  'NA': 'Namibia',
  'NC': 'Nueva Caledonia',
  'NE': 'Níger',
  'NF': 'Isla Norfolk',
  'NG': 'Nigeria',
  'NI': 'Nicaragua',
  'NL': 'Países Bajos',
  'NO': 'Noruega',
  'NP': 'Nepal',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'Nueva Zelanda',
  'OM': 'Omán',
  'PA': 'Panamá',
  'PE': 'Perú',
  'PF': 'Polinesia Francesa',
  'PG': 'Papúa Nueva Guinea',
  'PH': 'Filipinas',
  'PK': 'Pakistán',
  'PL': 'Polonia',
  'PM': 'San Pedro y Miquelón',
  'PN': 'Islas Pitcairn',
  'PR': 'Puerto Rico',
  'PS': 'Palestina',
  'PT': 'Portugal',
  'PW': 'Palaos',
  'PY': 'Paraguay',
  'QA': 'Catar',
  'RE': 'Reunión',
  'RO': 'Rumanía',
  'RS': 'Serbia',
  'RU': 'Rusia',
  'RW': 'Ruanda',
  'SA': 'Arabia Saudita',
  'SB': 'Islas Salomón',
  'SC': 'Seychelles',
  'SD': 'Sudán',
  'SE': 'Suecia',
  'SG': 'Singapur',
  'SH': 'Santa Elena, Ascensión y Tristán de Acuña',
  'SI': 'Eslovenia',
  'SJ': 'Svalbard y Jan Mayen',
  'SK': 'Eslovaquia',
  'SL': 'Sierra Leona',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somalia',
  'SR': 'Surinam',
  'SS': 'Sudán del Sur',
  'ST': 'Santo Tomé y Príncipe',
  'SV': 'El Salvador',
  'SX': 'Sint Maarten',
  'SY': 'Siria',
  'SZ': 'Esuatini',
  'TC': 'Islas Turcas y Caicos',
  'TD': 'Chad',
  'TF': 'Territorios Franceses del Sur',
  'TG': 'Togo',
  'TH': 'Tailandia',
  'TJ': 'Tayikistán',
  'TK': 'Tokelau',
  'TL': 'Timor Oriental',
  'TM': 'Turkmenistán',
  'TN': 'Túnez',
  'TO': 'Tonga',
  'TR': 'Turquía',
  'TT': 'Trinidad y Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwán',
  'TZ': 'Tanzania',
  'UA': 'Ucrania',
  'UG': 'Uganda',
  'UM': 'Islas menores alejadas de EE. UU.',
  'US': 'Estados Unidos',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistán',
  'VA': 'Ciudad del Vaticano',
  'VC': 'San Vicente y las Granadinas',
  'VE': 'Venezuela',
  'VG': 'Islas Vírgenes Británicas',
  'VI': 'Islas Vírgenes de EE. UU.',
  'VN': 'Vietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis y Futuna',
  'WS': 'Samoa',
  'YE': 'Yemen',
  'YT': 'Mayotte',
  'ZA': 'Sudáfrica',
  'ZM': 'Zambia',
  'ZW': 'Zimbabue',
  'nearestDate': 'Fecha más próxima disponible',
  'nearestDateWithHour': '%{day} a las %{hour}',
  'tomorrow': 'mañana',
  'paymentMethod': 'Método de pago',
  'secureFormDescription': 'Pague rápidamente con transferencia bancaria / tarjeta de crédito',
  'typeGeneratedBlikCode': 'Introduce el código de un solo uso generado en tu aplicación bancaria',
  'blik': 'BLIK',
  'typeBlikCode': 'Introduce el código BLIK',
  'blikCodePatternError': 'El código debe contener 6 dígitos',
  'blikCode': 'Código BLIK',
  'simplifiedInvoice': 'Simplificado',
  'fullInvoice': 'Completo',
  'privateInvoice': 'Con nombre',
  'simplifiedInvoiceInformation': 'El cliente recibirá un recibo con el número de IVA como factura simplificada. Sólo puede expedirse a empresas registradas en Polonia. Para las empresas extranjeras, elija la factura completa.',
  'isNotPolishNip': 'El número NIP indicado no es polaco.',
  'polishNip': 'Número de IVA polaco',
  'checkTheBankingApp': 'Comprueba la aplicación bancaria',
  'confirmPaymentWithPin': 'Ahora confirma el pago con tu PIN utilizando la aplicación en tu teléfono.',
  'ticketsLoadedAfterConfirm': 'Los boletos se cargarán después de la confirmación del pago y se enviarán por correo electrónico:',
  'paymentWithBlik': 'Pagar con BLIK',
  'cannontLoadPage': 'No se pudo cargar esta página',
  'refreshPage': 'Actualizar la página',
  'isSoldOut': 'Agotado',
  'removeSelectedActivities': 'Eliminar los servicios seleccionados',
  'removeSelectedServicesQuestion': '¿Eliminar los servicios seleccionados?',
  'allServicesWillBeRemoved': 'Todos los servicios serán eliminados y volverás a la re-selección',
  'hourFull': 'Hora',
  'hasNoSeatsTest': 'No hay plazas',
  'pleaseSelectDate': 'Seleccione una fecha',
  'pleaseSelectTime': 'Seleccione una hora',
  'selected': 'Selección',
  'term': 'Fecha',
  'price': 'Precio',
  'startDate': 'Fecha de inicio',
  'endDate': 'Fecha de finalización',
  'daysQuanity': 'Número de días',
  'decrease': 'Disminuir',
  'increase': 'Aumentar',
  'minValueError': 'El valor mínimo es %{value}',
  'maxValueError': 'El valor máximo es %{value}',
  'numberOfActivities': 'Número de actividades'
};

/* eslint-enable max-len */
