import {CheckoutView} from '@Components/checkout/types';
import {EShippingMethodsRequirement, IQuestion} from '@Consts/apiGlobals';
import {IBasketItem} from '@Consts/types';
import {isActivityHotelNarrowed} from '@Utils/activity/activity';
import {getBasketItemNumberOfAddmissionDates} from '@Utils/basket';
import {isTicketAnswerScope} from '@Utils/question';
import {isVariantGroup} from '@Utils/variantType';

export type Requirements = {
  basketItem: IBasketItem;
  basketItems: IBasketItem[];
  creations: CheckoutView[];
  questions: IQuestion[];
  question?: IQuestion;
};

export const noCreations = ({creations}: Requirements) =>
  Boolean(creations?.length < 1);

export const differentParticipantsNumbers = ({basketItem}: Requirements) => {
  const {minNumberOfParticipants, maxNumberOfParticipants} = basketItem?.activity?.admissionPolicy;

  return (minNumberOfParticipants && maxNumberOfParticipants) && maxNumberOfParticipants !== minNumberOfParticipants;
};

export const differentSeatsNumbers = (basketItem: IBasketItem) => {
  const minNumberOfSeats = basketItem?.variant.minNumberOfSeats;
  const maxNumberOfSeats = basketItem?.variant.maxNumberOfSeats;

  return minNumberOfSeats && maxNumberOfSeats ? minNumberOfSeats !== maxNumberOfSeats : false;
};

export const hasQuestions = ({basketItem}: Requirements) => basketItem.variant?.questions?.length;

export const hasAdditions = ({basketItem}: Requirements) => basketItem.variant?.additions?.length;

export const isShippingMethodRequired = (basketItem: IBasketItem) =>
  basketItem.activity?.shippingMethodRequirement === EShippingMethodsRequirement.REQUIRED;

export const isShippingMethodOptional = (basketItem: IBasketItem) =>
  basketItem.activity?.shippingMethodRequirement === EShippingMethodsRequirement.OPTIONAL;

export const itemHasDates = ({basketItem}: Partial<Requirements>) => {
  if (!basketItem) {
    return false;
  }
  const activityAdmissionPolicy = basketItem.activity.admissionPolicy;

  return activityAdmissionPolicy.firstAdmissionDate;
};

export const noViewForSameDatesNumber = ({basketItem, creations}: Requirements) => {
  const {
    numberOfAdmissionDatesMin,
    numberOfAdmissionDatesMax
  } = getBasketItemNumberOfAddmissionDates(basketItem);

  return !creations?.find((creation: CheckoutView) =>
    creation.numberOfAdmissionDatesMin === numberOfAdmissionDatesMin &&
    creation.numberOfAdmissionDatesMax === numberOfAdmissionDatesMax
  );
};

export const noDefaultDate = ({basketItem}: Requirements) => {
  if (basketItem) {
    return !basketItem.selections.defaultDate;
  }

  return true;
};

export const noCreationForQuestionId = ({creations, question}: Requirements) => {
  const answerScope = question?.answerScope;

  if (isTicketAnswerScope(answerScope)) {
    return true;
  }

  if (!creations.length) {
    return true;
  }

  return !creations.find(creation => creation.questionId === question?.id);
};

export const itemIsHotelNarrowedType = ({basketItem}: Requirements) => {
  const {activity} = basketItem;
  const {passType} = activity;

  return isActivityHotelNarrowed(passType);
};

export const itemIsGroupVariantType = (basketItem: IBasketItem) => {
  const {type} = basketItem?.variant;

  return isVariantGroup(type);
};

export const canMergeGroupVariants = ({basketItem}: Requirements) =>
  differentSeatsNumbers(basketItem) && itemIsGroupVariantType(basketItem);
