import {IActivityProcessed} from '@Consts/globals';
import {isActivityWithDates} from './activity';

const isAdmissionDateValid = (admissionPolicy: IActivityProcessed['admissionPolicy']) => {
  if (admissionPolicy.admissionDate) {
    const {date, numberOfSeats} = admissionPolicy.admissionDate;
    const isAdmissionDateValid = !!date && !!numberOfSeats;

    return isAdmissionDateValid;
  }

  return true;
};

export const isActivityWithValidDates = (activity: IActivityProcessed) => {
  const {passType, admissionPolicy} = activity;

  // If activity doesn't have admission dates, it's valid.
  if (!isActivityWithDates(passType)) {
    return true;
  }

  if (admissionPolicy.firstAdmissionDate) {
    return isAdmissionDateValid(admissionPolicy);
  }

  return false;
};
