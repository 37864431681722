import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import locale from '@Utils/locale';
import {COLON_HOUR_FORMAT} from '@Utils/dayjs/const';
import {getFormattedPrice} from '@Utils/format';
import Typography from '@mui/material/Typography';
import {getTimezoneDate, isSameDate, getFormattedDate} from '@Utils/dayjs/dayjsUtils';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import Grid from '@mui/material/Grid';
import {TimePickerButton} from './TimePickerComponents';
import DefaultTimePickerCell from './DefaultTimePickerCell';
import TimePickerCellDetails from './TimePickerCellDetails';
import {getTimePickerItemDetails} from './utils';

const TimePickerOption = (
  {
    option,
    onChange,
    value,
    canShowItemsLeft,
    showPrices,
    buttonHeight,
    isWithAllAdditionalInformation,
    isWithAdditionalInformation,
    isTestB
  }) => {
  const {date: selectedTime} = {...value};
  const {
    currency,
    date,
    isOutOfLimit,
    totalPrice,
    numberOfSeats,
    isBargainPrice
  } = option;
  const timezone = useSelector(getFacilityTimezone);
  const dateWithTimezone = getTimezoneDate(date, timezone);
  const selectedTimeWithTimezone = getTimezoneDate(selectedTime, timezone);
  const selectedTimeSameAsDate = isSameDate(selectedTimeWithTimezone, dateWithTimezone, 'minute');
  const isSelected = selectedTime && selectedTimeSameAsDate;
  const isSoldOut = isOutOfLimit && numberOfSeats === 0;
  const hasNoSeats = isOutOfLimit && numberOfSeats > 0;

  function handleChange() {
    return onChange(option);
  }

  if (isTestB) {
    const timePickerItemConfig = getTimePickerItemDetails(option, canShowItemsLeft, showPrices, timezone, selectedTime);
    const {isDisabled, isSelected} = timePickerItemConfig;

    return (
      <Grid item xs={3}>
        <TimePickerButton
          isSelected={isSelected}
          isDisabled={isDisabled}
          isCentered={!isWithAdditionalInformation}
          disableFocusRipple
          disableRipple
          onClick={handleChange}
          sx={{
            height: buttonHeight
          }}
        >
          {
            isWithAdditionalInformation ?
              <TimePickerCellDetails
                timePickerItemConfig={timePickerItemConfig}
                isWithAllAdditionalInformation={isWithAllAdditionalInformation}
              /> :
              <DefaultTimePickerCell
                timePickerItemConfig={timePickerItemConfig}
              />
          }
        </TimePickerButton>
      </Grid>
    );
  }

  return (
    <li className="dl-time-picker__option-wrapper">
      <div
        className={classNames(
          'dl-time-picker__option',
          {
            'dl-time-picker__option--disabled': isOutOfLimit,
            'dl-time-picker__option--selected': isSelected
          })
        }
        onClick={handleChange}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            lineHeight: '1rem'
          }}
        >
          {getFormattedDate(dateWithTimezone, COLON_HOUR_FORMAT)}
        </Typography>

        {
          showPrices && (
            <Typography
              id="price"
              sx={{
                ...priceStyles,
                ...isBargainPrice && {
                  color: 'success.main'
                }
              }}
            >
              {getFormattedPrice(totalPrice)} {locale.translate(currency)}
            </Typography>
          )
        }

        {
          canShowItemsLeft && !isSoldOut && (
            <Typography sx={itemsLeftStyles}>
              {numberOfSeats} {locale.translate('seats')}
            </Typography>
          )
        }

        {
          hasNoSeats && (
            <Typography sx={itemsLeftStyles}>
              {locale.translate('hasNoSeats')}
            </Typography>
          )
        }

        {
          isSoldOut && (
            <Typography sx={itemsLeftStyles}>
              {locale.translate('seatsSoldOut')}
            </Typography>
          )
        }
      </div>
    </li>
  );
};

TimePickerOption.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default TimePickerOption;

const itemsLeftStyles = {
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
  marginTop: '2px',
  color: '#778899'
};

const priceStyles = {
  ...itemsLeftStyles,
  fontWeight: 600
};
