import React, {useEffect} from 'react';
import {Box, Button, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {useDispatch, useSelector} from 'react-redux';
import {HOME_PATH} from '@Utils/routerUtils';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getStorageLatestOrders} from '@Features/storage/storageSelectors';
import {
  filterLatestOrders,
  findLatestOrder
} from '@Features/order/orderUtils';
import {EStorageKeys} from '@Consts/storageConsts';
import {setStorage} from '@Features/storage/storageActions';
import {EVENT_BUY_SEES_SCREEN_WITH_PAYMENT_LINK_GENERATED, EVENT_CLICKED_BACK_TO_LIST} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import pubsub from '@Utils/pubsub';
import locale from '@Utils/locale';
import {copyToClipBoard} from '@Utils/utils';
import {fetchOrder} from '@Features/order/orderActions';
import {useCancellableAction} from '@Utils/hooks/useCancellableAction';
import {useNavigate} from 'react-router-dom';

const InternalPaymentView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const facility = useSelector(getFacility);
  const {affiliationHash, onlineGroupId, widgetType} =
    useSelector(getConfiguration);
  const latestOrders = useSelector(getStorageLatestOrders);
  const {isEventsSent, paymentViewUrl, code, orderId} =
    findLatestOrder(latestOrders, onlineGroupId, widgetType) || {};
  const {id: facilityId, name: facilityName, company} = {...facility};
  const {industry} = {...company};
  const [onCancel] = useCancellableAction(fetchOrder, code, orderId);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  function handleClick() {
    pubsub.trigger(EVENT_CLICKED_BACK_TO_LIST, {
      facilityId,
      facilityName,
      industry,
      affiliationHash,
      language: parseLanguage(locale.locale),
      isFirstLoading: !isEventsSent
    });
    onCancel();

    const filteredOrders = filterLatestOrders(
      latestOrders,
      onlineGroupId,
      widgetType
    );

    dispatch(setStorage(EStorageKeys.LATEST_ORDERS, filteredOrders, true));

    navigate(HOME_PATH);
  }

  useEffect(() => {
    pubsub.trigger(EVENT_BUY_SEES_SCREEN_WITH_PAYMENT_LINK_GENERATED, {
      facilityId,
      facilityName,
      industry,
      orderId
    });
  }, []);

  return (
    <Stack sx={{alignItems: 'center', backgroundColor: '#fff', p: 3}}>
      <Button
        size={isMobile ? 'medium' : 'small'}
        color="inherit"
        variant="text"
        startIcon={<ArrowBackOutlinedIcon />}
        sx={{
          alignSelf: 'flex-start',
          fontSize: {xs: '1rem', sm: '0.93rem'}
        }}
        onClick={handleClick}
      >
        {locale.translate('newOrder')}
      </Button>
      <Box
        component="img"
        src="img/FilesSent.svg"
        alt="files sent"
        sx={{
          width: '100%',
          maxWidth: {xs: '220px', sm: '300px'},
          height: {xs: '220px', sm: '300px'}
        }}
      />
      <Typography sx={{fontSize: '1.5rem', fontWeight: 500, mt: 3, mb: 2}}>
        {locale.translate('LastStep')}
      </Typography>
      <Typography sx={{mb: 2.5, textAlign: 'center'}}>
        {locale.translate('copyLinkAndSendToCustomer')}
      </Typography>
      <Typography
        sx={{p: 1, backgroundColor: '#F5F5F5', textAlign: 'center'}}
      >
        {paymentViewUrl}
      </Typography>
      <Typography sx={{my: 2}}>{locale.translate('orClick')}</Typography>
      <Button
        variant="contained"
        startIcon={<FileCopyOutlinedIcon />}
        onClick={() => copyToClipBoard(paymentViewUrl)}
        sx={{mb: 3}}
      >
        {locale.translate('CopyLink')}
      </Button>
    </Stack>
  );
};

export default InternalPaymentView;
