import {
  getActivityLimit,
  isActivityLimitReached,
  isActivityWithDates
} from '@Utils/activity/activity';
import {isActivityWithValidDates} from '@Utils/activity/utils';
import {getVariantById} from '@Utils/activity/variant';
import {getActivityBasketItems} from '@Utils/basket';
import locale from '@Utils/locale';
import {IBasketItem} from '@Consts/types';
import {IActivityProcessed, IActivityVariantProcessed} from '@Consts/globals';
import {isDefaultDateLimitReached} from '@Utils/basketUtils';
import {getFormattedDate} from '@Utils/dates/timezoneDates';

type LabelConfig = {
  isActivityWithInvalidDates: boolean;
  isActivityWithValidPrice: boolean;
}

export const getActivityLabelText = (
  activity: IActivityProcessed,
  {isActivityWithInvalidDates, isActivityWithValidPrice}: LabelConfig
) => {
  const {passType, salePolicy, admissionPolicy} = activity;
  const {canShowItemsLeft, showEndDate, isEndingSoon, isSoldOut} = salePolicy;
  const {admissionDate} = admissionPolicy;
  const limit = getActivityLimit(activity);
  const endDate = showEndDate; // Field showEndDate is a Dayjs object or null
  const activityWithDates = isActivityWithDates(passType);

  if (isSoldOut) {
    return locale.translate('soldOut');
  }

  if (isActivityWithInvalidDates || !isActivityWithValidPrice) {
    return locale.translate('noDates');
  }

  const endText = endDate ? locale.translate('willEndBefore', {date: getFormattedDate(endDate, 'DD.MM')}) : null;

  if (canShowItemsLeft) {
    if (activityWithDates && endDate) {
      return endText;
    }

    if (activityWithDates && admissionDate?.numberOfSeats) {
      const {numberOfSeats} = admissionDate;

      return locale.translate('itemsLeft', {limit: numberOfSeats});
    }

    if (activityWithDates) {
      return null;
    }

    const itemsText = limit === null ?
      locale.translate('soldOut') :
      locale.translate('itemsLeft', {limit});

    if (endDate) {
      return `${itemsText} • ${endText}`;
    }

    return itemsText;
  }

  if (endDate) {
    return endText;
  }

  if (isEndingSoon) {
    return locale.translate('willEndSoon');
  }

  return null;
};

export type PickerDisableReasonProps = {
  activity: IActivityProcessed;
  basketItems: IBasketItem[];
  maximumTicketsInOrder: number;
  variantId: number;
};

export const getPickerDisableReason = ({
  activity,
  basketItems,
  maximumTicketsInOrder,
  variantId
}: PickerDisableReasonProps) => {
  const datesAreInvalid = !isActivityWithValidDates(activity);
  const {id: activityId, variants} = activity;
  const variant = getVariantById(variants, variantId);
  const activityBasketItems = getActivityBasketItems(basketItems, activityId);
  const numberOfBasketItems = basketItems.length;

  if (datesAreInvalid) {
    return locale.translate('noValidDatesRange');
  }

  if (numberOfBasketItems >= maximumTicketsInOrder) {
    return locale.translate('chooseLimitReached');
  }

  if (variant) {
    const selectedActivityBasketItemAmount = activityBasketItems
      .reduce(amount => amount + 1, 0);

    if (isActivityLimitReached(activity, selectedActivityBasketItemAmount)) {
      return locale.translate('canNotBuyMoreTickets');
    }

    if (isDefaultDateLimitReached(activityBasketItems)) {
      return locale.translate('canNotBuyMoreTickets');
    }
  }

  return null;
};

export type VariantGroupMinLimit = {
  variant: IActivityVariantProcessed;
  activity: IActivityProcessed;
};

export const getVariantGroupLimit = ({
  variant,
  activity
}: VariantGroupMinLimit) => {
  if (!variant) {
    return 0;
  }

  // For group variant type
  const maxNumberOfSeats = variant.maxNumberOfSeats;
  // For group event pass type
  const maxNumberOfParticipants = activity.admissionPolicy.maxNumberOfParticipants;

  return maxNumberOfSeats || maxNumberOfParticipants || 0;
};

const getAdmissionDateLimit = (
  activity: IActivityProcessed,
  numberOfActivityItems: number,
  numberOfVariantItems: number
) => {
  // Limit from admission date (default selected date)
  const admissionDateNumberOfSeats = activity?.admissionPolicy?.admissionDate?.numberOfSeats;
  const admissionDateLimit = admissionDateNumberOfSeats ? admissionDateNumberOfSeats : null;

  if (!admissionDateLimit) {
    return null;
  }

  // The number of seats for the activity admission date is a common value for all activity variants
  // and it is calculated for current variant
  return admissionDateLimit - numberOfActivityItems + numberOfVariantItems;
};

export type VariantMinLimitProps = {
  variant: IActivityVariantProcessed;
  activity: IActivityProcessed;
  maximumTicketsInOrder: number;
  numberOfBasketItems: number;
  numberOfVariantItems: number;
  numberOfActivityItems: number;
};

/**
 * Calculates the minimal limit value for variant counter picker
 * It takes into account the maximum number of tickets in the order, activity limit with uses count,
 * and admission date limit. Limit value does not include variant basket items.
 */

export const getVariantLimit = ({
  variant,
  activity,
  maximumTicketsInOrder,
  numberOfBasketItems,
  numberOfVariantItems,
  numberOfActivityItems
}: VariantMinLimitProps) => {
  if (!variant) {
    return 0;
  }

  // Limit from the maximum number of activities in the order without activities for this variant
  const orderLimit = maximumTicketsInOrder - numberOfBasketItems + numberOfVariantItems;

  // Limit from existing activity limit with uses count and selected activity variants
  const {limit, usesCount} = activity?.salePolicy;
  const usesCountLimit = limit ? limit - usesCount : null;
  const activityLimit = usesCountLimit ? usesCountLimit - numberOfActivityItems + numberOfVariantItems : null;

  const admissionDateLimit = getAdmissionDateLimit(activity, numberOfActivityItems, numberOfVariantItems);

  const existsLimits = [
    orderLimit, activityLimit, admissionDateLimit
  ].filter(v => Number.isInteger(v)) as number[];

  return existsLimits.length ? Math.min(...existsLimits) : 0;
};

export const getCounterMaxValue = ({
  variant,
  activity,
  maximumTicketsInOrder,
  numberOfBasketItems,
  numberOfVariantItems,
  numberOfActivityItems,
  isGroupVariantType
}: VariantMinLimitProps & {isGroupVariantType: boolean}) => {
  if (isGroupVariantType) {
    return getVariantGroupLimit({
      variant,
      activity
    });
  }

  return getVariantLimit({
    variant,
    activity,
    maximumTicketsInOrder,
    numberOfBasketItems,
    numberOfVariantItems,
    numberOfActivityItems
  });
};
