import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {AdmissionPolicyDate, EPassType} from '@Consts/apiGlobals';
import {BasketItemVariant} from '@Consts/globals';
import {AdmissionDateExtended, DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {isActivitySinglePickDate, isActivityWithDateTimes} from '@Utils/activity/activity';
import {isVariantMultiday} from '@Utils/activity/variant';
import {getFormattedDate} from '@Utils/dates/timezoneDates';
import {COLON_HOUR_FORMAT, DOT_DAY_FORMAT_REVERSED} from '@Utils/dayjs/const';
import locale from '@Utils/locale';
import {getFormattedDatetimePickerDate, getFormattedSinglePickDate} from './utils';

const getFormattedDefaultDateCopy = (defaultDate: AdmissionPolicyDate, passType: EPassType) => {
  const dateFormatted = getFormattedDate(defaultDate.date, DOT_DAY_FORMAT_REVERSED);

  if (isActivityWithDateTimes(passType)) {
    const timeFormatted = getFormattedDate(defaultDate.date, COLON_HOUR_FORMAT);

    const hourAbbr = locale.translate('hour');
    const dateOfUse = locale.translate('dateOfUse');
    const dateCopy = `${dateOfUse}: ${dateFormatted}, ${hourAbbr} ${timeFormatted}`;

    return {
      dateCopy,
      description: defaultDate.description
    };
  }

  return {
    dateCopy: locale.translate('realiseInDay', {date: dateFormatted}),
    description: defaultDate.description
  };
};

type GetFormattedDatePickerProps = {
  selectedDates: IDatesGroupedByDay[] | null;
  selectedTime: AdmissionDateExtended | null;
  variant: BasketItemVariant;
  passType: EPassType;
  dayOffer: DayOffersProcessed | null;
  timezone: string;
  defaultDate: AdmissionPolicyDate | null;
}

export const getFormattedDatePicker = ({
  selectedDates, selectedTime, variant, passType, dayOffer, timezone, defaultDate
}: GetFormattedDatePickerProps) => {

  if (defaultDate) {
    return getFormattedDefaultDateCopy(defaultDate, passType);
  }

  if (isVariantMultiday(variant) || isActivitySinglePickDate(passType)) {
    const dateCopy = getFormattedSinglePickDate({selectedDates, variant, timezone});
    const description = selectedDates?.[0]?.metaData?.description;

    return {
      dateCopy,
      description
    };
  }

  return getFormattedDatetimePickerDate({selectedTime, dayOffer, timezone});
};
