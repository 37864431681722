export const findAddition = (additions, additionId, variantId) => {
  const additionToValid = additions.find(addition => addition.id === additionId);

  return additionToValid.variantIds.some(additionVariantId => additionVariantId === variantId);
};

export const findSelectedAdditionQuantity = (additions, additionId) => {
  const selectedAddition = additions?.length && additions.find(addition => addition.additionId === additionId);

  if (selectedAddition) {
    return selectedAddition?.quantity;
  }

  return 0;
};

export const makeAdditionsSelections = (additions, additionId, quantity) => {
  const currentAdditions =
    additions?.length && additions.filter(addition => addition.additionId !== additionId) || [];

  if (quantity > 0) {
    let newAdditions = [...currentAdditions, {additionId, quantity}];

    newAdditions = newAdditions.sort((additionFirst, additionSecond) =>
      additionFirst.additionId - additionSecond.additionId);

    return {additions: newAdditions};
  } else if (currentAdditions?.length) {
    return {additions: [...currentAdditions]};
  } else {
    return {additions: []};
  }
};

export const getBasketItemsAdditionsSelected = basketItems =>
  basketItems.reduce((additions, item) => {
    const {selections, variant: {numberOfSeats}} = item;
    const itemAdditions = selections?.additions || [];
    const selectedNumberOfSeats = selections?.numberOfSeats || null;

    if (itemAdditions?.length) {
      const itemAdditionsWithSeatsNumber = itemAdditions.map(itemAddition => (
        {...itemAddition, numberOfSeats, selectedNumberOfSeats}
      )) || [];

      return additions.concat(...itemAdditionsWithSeatsNumber);
    }

    return additions.concat(...itemAdditions);
  }, []);

export const haveSomeElementToRender = basketItemsList =>
  basketItemsList.some(item => item);

export const isAdditionsLimitReached = (
  quantity,
  numberOfSeats,
  maxNumberPerTicket
) => {
  if (numberOfSeats <= 1) {
    return quantity * numberOfSeats >= maxNumberPerTicket;
  }

  return (quantity + 1) * numberOfSeats > maxNumberPerTicket;
};
