import {boolean, object, string} from 'yup';
import {schema as phonePrefixSchema} from './field/PhonePrefixField';
import {
  PHONE_REGEX,
  schema as phoneNumberSchema
} from './field/PhoneNumberField';
import {schema as firstNameSchema} from './field/FirstNameField';
import {schema as lastNameSchema} from './field/LastNameField';
import {schema as emailSchema} from './field/EmailField';
import {schema as paymentMethodSchema} from './field/PaymentMethodField';
import {schema as paymentDeadlingDateFieldSchema} from './field/PaymentDeadlingDateField';
import {schema as companyNameFieldSchema} from '@Components/invoice/formFields/CompanyNameField';
import {schema as companyNipFieldSchema} from '@Components/invoice/formFields/CompanyNipField';
import {schema as companyVatIdFieldSchema} from '@Components/invoice/formFields/CompanyVatIdField';
import {schema as invoiceFirstNameSchema} from '@Components/invoice/formFields/InvoiceFirstNameField';
import {schema as invoiceLastNameFieldSchema} from '@Components/invoice/formFields/InvoiceLastNameField';
import {schema as invoiceCityFieldSchema} from '@Components/invoice/formFields/InvoiceCityField';
import {schema as invoiceStreetFieldSchema} from '@Components/invoice/formFields/InvoiceStreetField';
import {schema as invoicePostalCodeFieldSchema} from '@Components/invoice/formFields/InvoicePostalCodeField';
import {
  schema as invoicePostalCodeSimpleFieldSchema
} from '@Components/invoice/formFields/InvoicePostalCodeSimpleField';
import {schema as invoiceCountryFieldSchema} from '@Components/invoice/formFields/InvoiceCountryField';
import {CountryType, InvoiceAddressCountry} from '@Consts/invoiceCountries';
import {EInvoiceFormType} from '@Components/invoice/types';
import locale from '@Utils/locale';

export const getCheckoutGenerateSchema = (timezone: string) => object().shape({
  isPaymentOnline: boolean(),
  email: emailSchema(),
  firstName: firstNameSchema(),
  lastName: lastNameSchema(),
  paymentMethod: paymentMethodSchema(),
  withContactPhoneNumber: boolean(),
  phoneNumber: string()
    .when('$withContactPhoneNumber', {
      is: true,
      then: () => phoneNumberSchema(),
      otherwise: () => string()
        .transform(value => (value === '' ? null : value))
        .nullable()
        .matches(PHONE_REGEX, locale.translate('invalidPhone'))
    }),
  phonePrefix: object()
    .nullable() // Could be null when not required and phone number is not provided
    .when(['$withContactPhoneNumber', 'phoneNumber'], {
      is: ($withContactPhoneNumber: boolean, phoneNumber: string) => $withContactPhoneNumber || phoneNumber,
      then: () => phonePrefixSchema()
    }),
  paymentCompleteUntil: string().when('isPaymentOnline', {
    is: true,
    then: () => paymentDeadlingDateFieldSchema(timezone)
  }),
  sendInvoice: boolean(),
  invoiceFormType: string().oneOf(Object.values(EInvoiceFormType)),
  invoiceCompanyName: string().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType === EInvoiceFormType.COMPANY,
    then: () => companyNameFieldSchema()
  }),
  invoiceCompanyNip: string().when(['sendInvoice', 'invoiceFormType', 'invoiceAddressCountry'],
    keys => {
      const [sendInvoice, invoiceFormType, invoiceAddressCountry] = keys as
      [boolean, EInvoiceFormType, CountryType];

      if (sendInvoice && invoiceFormType !== EInvoiceFormType.PRIVATE_PERSON) {
        return invoiceAddressCountry.code === InvoiceAddressCountry.PL ||
        invoiceFormType === EInvoiceFormType.COMPANY_SIMPLIFIED ? companyNipFieldSchema() : companyVatIdFieldSchema();
      }

      return string().notRequired();
    }),
  invoiceFirstName: string().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType === EInvoiceFormType.PRIVATE_PERSON,
    then: () => invoiceFirstNameSchema()
  }),
  invoiceLastName: string().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType === EInvoiceFormType.PRIVATE_PERSON,
    then: () => invoiceLastNameFieldSchema()
  }),
  invoiceAddressCity: string().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType !== EInvoiceFormType.COMPANY_SIMPLIFIED,
    then: () => invoiceCityFieldSchema()
  }),
  invoiceAddressStreet: string().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType !== EInvoiceFormType.COMPANY_SIMPLIFIED,
    then: () => invoiceStreetFieldSchema()
  }),
  invoiceAddressPostalCode: string().when([
    'sendInvoice', 'invoiceFormType', 'invoiceAddressCountry'
  ], keys => {
    const [sendInvoice, invoiceFormType, invoiceAddressCountry] = keys as
    [boolean, EInvoiceFormType, CountryType];

    if (sendInvoice && invoiceFormType !== EInvoiceFormType.COMPANY_SIMPLIFIED) {
      return invoiceAddressCountry.code === InvoiceAddressCountry.PL ?
        invoicePostalCodeFieldSchema() : invoicePostalCodeSimpleFieldSchema();
    }

    return string().notRequired();
  }),
  invoiceAddressCountry: object().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType === EInvoiceFormType.COMPANY,
    then: () => invoiceCountryFieldSchema()
  }),
  isInvoiceRecipient: boolean(),
  invoiceRecipientName: string().when(['sendInvoice', 'isInvoiceRecipient', 'invoiceFormType'], {
    is: (sendInvoice: boolean, isInvoiceRecipient: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && isInvoiceRecipient && invoiceFormType === EInvoiceFormType.COMPANY,
    then: () => companyNameFieldSchema()
  }),
  invoiceRecipientAddressStreet: string().when(['sendInvoice', 'isInvoiceRecipient', 'invoiceFormType'], {
    is: (sendInvoice: boolean, isInvoiceRecipient: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && isInvoiceRecipient && invoiceFormType === EInvoiceFormType.COMPANY,
    then: () => invoiceStreetFieldSchema()
  }),
  invoiceRecipientAddressCity: string().when(['sendInvoice', 'isInvoiceRecipient', 'invoiceFormType'], {
    is: (sendInvoice: boolean, isInvoiceRecipient: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && isInvoiceRecipient && invoiceFormType === EInvoiceFormType.COMPANY,
    then: () => invoiceCityFieldSchema()
  }),
  invoiceRecipientAddressPostalCode: string().when([
    'sendInvoice',
    'isInvoiceRecipient',
    'invoiceRecipientAddressCountry',
    'invoiceFormType'
  ], keys => {
    const [sendInvoice, isInvoiceRecipient, invoiceRecipientAddressCountry, invoiceFormType] =
    keys as [boolean, boolean, CountryType, EInvoiceFormType];

    if (sendInvoice && isInvoiceRecipient && invoiceFormType === EInvoiceFormType.COMPANY) {
      return invoiceRecipientAddressCountry.code === InvoiceAddressCountry.PL ?
        invoicePostalCodeFieldSchema() : invoicePostalCodeSimpleFieldSchema();
    }

    return string().notRequired();
  }),
  invoiceRecipientAddressCountry: object().when(['sendInvoice', 'isInvoiceRecipient', 'invoiceFormType'], {
    is: (sendInvoice: boolean, isInvoiceRecipient: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && isInvoiceRecipient && invoiceFormType === EInvoiceFormType.COMPANY,
    then: () => invoiceCountryFieldSchema()
  })
});
