export const LOGGED_USER_KEY = 'dl_logged_user_state';

// todo: figure out what type this is
const getLoggedUser = () => {
  const storedUser = window.localStorage.getItem(LOGGED_USER_KEY);

  if (storedUser) {
    return JSON.parse(storedUser);
  }

  return null;
};

export default getLoggedUser;
