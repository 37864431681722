import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '@mui/material/Button';
import pubsub from '@Utils/pubsub';
import {
  EVENT_CLICK_ON_ADD_TO_APPLE_WALLET,
  EVENT_CLICK_ON_ADD_TO_GOOGLE_WALLET,
  EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON
} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {useSummaryData} from '../../../hooks/useSummaryData';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import WalletPass from './WalletPass';

const TicketsOnlineContent = () => {
  const {
    widgetType,
    onlineOrderTickets,
    latestOrderStorageData,
    isPreviewMode,
    documentAddress,
    previewTickets,
    isWigetOnlineType,
    appleWalletPassUrl,
    googleWalletPassUrl
  } = useSummaryData();

  if (!onlineOrderTickets && !previewTickets) {
    return null;
  }

  const handleDownloadTickets = () => {
    if (documentAddress) {
      pubsub.trigger(EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON, {
        language: parseLanguage(locale.locale),
        isFirstLoading: !latestOrderStorageData.isEventsSent
      });
      window.open(documentAddress);
      window.focus();
    }
  };

  function getWidgetType() {
    const typeSplitted = widgetType && widgetType.split('_');

    return typeSplitted && typeSplitted[0].toLowerCase();
  }

  function handleOnAppleWalletClick() {
    if (appleWalletPassUrl) {
      pubsub.trigger(EVENT_CLICK_ON_ADD_TO_APPLE_WALLET, {
        source: 'widget',
        saleType: getWidgetType(),
        language: parseLanguage(locale.locale),
        isFirstLoading: !latestOrderStorageData.isEventsSent
      });
    }
  }

  function handleOnGoogleWalletClick() {
    if (googleWalletPassUrl) {
      pubsub.trigger(EVENT_CLICK_ON_ADD_TO_GOOGLE_WALLET, {
        source: 'widget',
        saleType: getWidgetType(),
        language: parseLanguage(locale.locale),
        isFirstLoading: !latestOrderStorageData.isEventsSent
      });
    }
  }

  return (
    <Stack sx={{flexDirection: 'column', alignItems: 'center'}}>
      <Typography sx={{my: 2, fontWeight: 500}} variant="h5">
        {locale.translate('everythingDone')}
      </Typography>
      <Typography sx={{mb: 4, maxWidth: 360, textAlign: 'center', color: 'text.secondary'}} >
        {locale.translate('ticketsReadyToDownload')}
      </Typography>
      <FollowCursorTooltip
        title={locale.translate('actionDisabledInPreviewMode')}
        enable={isPreviewMode}
        styles={{width: {xs: 1, sm: 'auto'}}}
      >
        <Button
          color="success"
          variant={isWigetOnlineType ? 'contained' : 'outlined'}
          onClick={handleDownloadTickets}
          sx={{width: 1, mb: 2}}
          endIcon={<FileDownloadOutlinedIcon />}
          disabled={isPreviewMode}
        >
          {locale.translate('download')}
        </Button>
      </FollowCursorTooltip>
      {
        (appleWalletPassUrl || googleWalletPassUrl || previewTickets?.length) &&
          <Typography sx={{fontSize: 12, mb: 2, color: 'text.secondary', textTransform: 'capitalize'}}>
            {locale.translate('or')}
          </Typography>
      }
      {
        (appleWalletPassUrl || googleWalletPassUrl) &&
          <Stack direction={{xs: 'column', md: 'row'}} sx={{mb: 1, alignItems: 'center'}} spacing={1}>
            {appleWalletPassUrl && (
              <WalletPass
                url={appleWalletPassUrl}
                onClick={handleOnAppleWalletClick}
                testid="apple-wallet"
                srcImg={`img/appleWalletPass/${locale.locale}.svg`}
              />
            )}
            {googleWalletPassUrl && (
              <WalletPass
                url={googleWalletPassUrl}
                onClick={handleOnGoogleWalletClick}
                testid="google-wallet"
                srcImg={`img/googleWalletPass/${locale.locale}.png`}
              />
            )}
          </Stack>
      }
      {
        previewTickets?.length &&
          <Box sx={{mb: 1}}>
            <FollowCursorTooltip
              title={locale.translate('actionDisabledInPreviewMode')}
              enable={isPreviewMode}
            >
              <Stack direction={{xs: 'column', md: 'row'}} sx={{mb: 1, alignItems: 'center'}} spacing={1}>
                <WalletPass
                  testid="apple-wallet"
                  srcImg={`img/appleWalletPass/${locale.locale}.svg`}
                />
                <WalletPass
                  testid="google-wallet"
                  srcImg={`img/googleWalletPass/${locale.locale}.png`}
                />
              </Stack>
            </FollowCursorTooltip>
          </Box>
      }
    </Stack>
  );
};

export default TicketsOnlineContent;
