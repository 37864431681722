import {getActivitiesUniquesIds, getBasketItemsByActivityId, splitBasketItemsByUpsell} from '../basket';
import {createAdmissionDatesViews} from './admissionDatesViews';
import {createQuestionsViews} from './questionsViews';
import {createAdditionsViews} from './additionsViews';
import {createHotelNarrowedDatesViews} from './hotelNarrowedViews';
import {getLinkedCheckoutViews} from '@Utils/checkout';
import {createShippingMethodsViews} from './shippingMethodViews';
import {ECheckoutViewsTypes} from './checkoutViewsConsts';

export const shouldCreateView = ({creations, questions, question, basketItems, basketItem, requires}) => {
  if (!requires?.length) {
    return true;
  }

  return requires.every(require => require({creations, questions, question, basketItems, basketItem}));
};

export const shouldMerge = ({creations, basketItem, requires}) => {
  if (!requires?.length) {
    return true;
  }

  return requires.every(require => require({creations, basketItem}));
};

export const getBasketItemsCheckoutViews = (basketItems, numberOfShippingMethods) => {
  if (!basketItems?.length) {
    return [];
  }

  const activitiesUniquesIds = getActivitiesUniquesIds(basketItems);
  let checkoutViews = [];
  let shippingMethodsView = {};

  activitiesUniquesIds.forEach(activityId => {
    const activityBasketItems = getBasketItemsByActivityId(basketItems, activityId);
    const questionsViews = createQuestionsViews(activityBasketItems);
    const additionsViews = createAdditionsViews(activityBasketItems);
    const datesViews = createAdmissionDatesViews(activityBasketItems);
    const hotelNarrowedViews = createHotelNarrowedDatesViews(activityBasketItems);

    shippingMethodsView = createShippingMethodsViews(activityBasketItems, shippingMethodsView, numberOfShippingMethods);

    checkoutViews = [
      ...checkoutViews,
      ...hotelNarrowedViews,
      ...datesViews,
      ...additionsViews,
      ...questionsViews
    ];
  });

  if (Object.keys(shippingMethodsView).length) {
    checkoutViews = [
      ...checkoutViews,
      shippingMethodsView
    ];
  }

  return checkoutViews;
};

export const getCheckoutViews = (allBasketItems, numberOfShippingMethods, upsellActivities) => {
  const {basketItems, upsellBasketItems} = splitBasketItemsByUpsell(allBasketItems);
  const basketItemsCheckoutViews = getBasketItemsCheckoutViews(basketItems, numberOfShippingMethods);
  const upsellBasketItemsCheckoutViews = getBasketItemsCheckoutViews(upsellBasketItems, numberOfShippingMethods);
  const isShippingMethodsType = type => type === ECheckoutViewsTypes.SHIPPING_METHODS;
  const isShippingInBasketViews = basketItemsCheckoutViews.some(view => isShippingMethodsType(view.type));

  const validUpsellViews = isShippingInBasketViews ?
    upsellBasketItemsCheckoutViews.filter(view => !isShippingMethodsType(view.type)) :
    upsellBasketItemsCheckoutViews;

  if (upsellActivities) {
    const upsellView = {type: ECheckoutViewsTypes.UPSELL};

    return getLinkedCheckoutViews([...basketItemsCheckoutViews, upsellView, ...validUpsellViews]);
  }

  return getLinkedCheckoutViews([...basketItemsCheckoutViews, ...validUpsellViews]);
};
