import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ActivitySingle from './ActivitySingle';
import {isActivitySingleGroupEvent} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import ActivityContent, {SPACING_TYPE} from '@Components/activities/activity/ActivityContent';
import Text from '@Components/common/text/Text';
import {getFormattedDatePicker} from './activityPreviewUtils';
import {ETagType, ETextColor} from '@Components/common/text/types';
import {isVariantGroup} from '@Utils/variantType';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import {IBasketItem} from '@Consts/types';

type Props = {
  basketItem: IBasketItem;
}

const ActivityFixedDate = ({basketItem}: Props) => {
  const timezone = useSelector(getFacilityTimezone)!;
  const {
    activity,
    selections,
    variant
  } = basketItem;
  const {
    numberOfParticipants,
    dates: selectedDates,
    time: selectedTime,
    numberOfSeats,
    dayOffer,
    defaultDate
  } = selections;
  const {passType} = activity;
  const {
    dateCopy,
    description
  } = getFormattedDatePicker({selectedDates, selectedTime, variant, passType, dayOffer, timezone, defaultDate});

  return (
    <>
      <ActivitySingle basketItem={basketItem} />

      {
        isActivitySingleGroupEvent(passType) && (
          <ActivityContent spacing={SPACING_TYPE.TOP}>
            <PeopleAltOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
            <Text color={ETextColor.LIGHT} dl-test="participantsText">
              {`${locale.translate('numberOfParticipants')}: ${numberOfParticipants}`}
            </Text>
          </ActivityContent>
        )
      }

      {
        isVariantGroup(variant?.type) && (
          <ActivityContent spacing={SPACING_TYPE.TOP}>
            <PeopleAltOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
            <Text color={ETextColor.LIGHT}>
              {`${locale.translate('numberOfPeopleInGroup')}: ${numberOfSeats}`}
            </Text>
          </ActivityContent>
        )
      }

      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />

        <Text color={ETextColor.LIGHT}>
          <>
            <Text tagType={ETagType.P} dl-test="dateCopy">{dateCopy}</Text >
            {description && <Text tagType={ETagType.P}>{description}</Text>}
          </>
        </Text>
      </ActivityContent>
    </>
  );
};

export default ActivityFixedDate;
