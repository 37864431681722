import React from 'react';
import locale from '@Utils/locale';
import ButtonWrapper, {ButtonWrapperProps} from './ButtonWrapper';
import Button from '@mui/material/Button/Button';
import {counterPickerButtonStyles} from '@Utils/componentStyles';

type Props = {
  onClick: () => void;
} & ButtonWrapperProps;

const ActivityAddButton = ({onClick, disableReason}: Props) => (
  <ButtonWrapper disableReason={disableReason}>
    <Button
      onClick={onClick}
      variant="contained"
      disabled={!!disableReason}
      sx={counterPickerButtonStyles}
    >
      {locale.translate('add')}
    </Button>
  </ButtonWrapper>
);

export default ActivityAddButton;
