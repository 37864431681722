import React, {useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import InvoiceToggler from '@Components/invoice/InvoiceToggler';
import {EInvoiceFormType, IAgreementsInvoiceSection} from '@Components/invoice/types';
import {isCompanySimplifiedInvoiceFormType} from '@Utils/invoice';
import {useSelector, useDispatch} from 'react-redux';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {IBasketItem} from '@Consts/types';
import {useFormContext} from 'react-hook-form';
import InvoiceFormTypeField, {
  FIELD_NAME as FORM_TYPE_FIELD_NAME
} from '@Components/invoice/formFields/InvoiceFormTypeField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InvoiceFirstNameField from '@Components/invoice/formFields/InvoiceFirstNameField';
import InvoiceNipSection, {FIELD_NAME as COMPANY_INVOICE_NIP_FIELD_NAME} from '@Components/invoice/InvoiceNipSection';
import CompanyNameField from '@Components/invoice/formFields/CompanyNameField';
import InvoiceLastNameField from '@Components/invoice/formFields/InvoiceLastNameField';
import InvoicePostalCodeSection from '@Components/invoice/InvoicePostalCodeSection';
import InvoiceCityField from '@Components/invoice/formFields/InvoiceCityField';
import InvoiceStreetField from '@Components/invoice/formFields/InvoiceStreetField';
import InvoiceNoteField from '@Components/invoice/formFields/InvoiceNoteField';
import CompanySimplifiedInvoiceForm from '@Components/invoice/CompanySimplifiedInvoiceForm';
import RecipientToggler from '@Components/invoice/RecipientToggler';
import locale from '@Utils/locale';
import InvoiceCountryField from '@Components/invoice/formFields/InvoiceCountryField';
import {CountryType, InvoiceAddressCountry} from '@Consts/invoiceCountries';
import {useFetchInvoiceData} from '@Utils/hooks/useFetchInvoiceData';
import {fillMissedData, isInvoiceDataComplete} from '@Utils/formUtils';
import {setStorage} from '@Features/storage/storageActions';
import pubsub from '@Utils/pubsub';
import {EVENT_INVOICE_FORM_COMPANY_DATA_LOAD} from '@Utils/events';
import {getFacility} from '@Features/facility/facilitySelectors';

const AgreementsInvoiceSection = ({
  isUsersInvoiceNoteEnabled,
  isOnlinePaymentReceiptEnabled
}: IAgreementsInvoiceSection) => {
  const dispatch = useDispatch();
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const facility = useSelector(getFacility)!;
  const {id: facilityId, name: facilityName} = facility;
  const {watch, getValues, setValue} = useFormContext();
  const [invoiceFormType, invoiceCompanyNip, sendInvoice, isInvoiceRecipient, invoiceAddressCountry] = watch(
    [FORM_TYPE_FIELD_NAME, COMPANY_INVOICE_NIP_FIELD_NAME, 'sendInvoice', 'isInvoiceRecipient', 'invoiceAddressCountry']
  ) as [EInvoiceFormType, string, boolean, boolean, CountryType];

  const isPrivatePersonFormType = invoiceFormType === EInvoiceFormType.PRIVATE_PERSON;
  const companyFormCanBeSimplified = invoiceFormType === EInvoiceFormType.COMPANY &&
    isCompanySimplifiedInvoiceFormType({
      basketItems,
      isOnlinePaymentReceiptEnabled,
      invoiceAddressCountryCode: invoiceAddressCountry.code
    });

  const isCompanyFormType = invoiceFormType === EInvoiceFormType.COMPANY && !companyFormCanBeSimplified;
  const isCompanySimplifiedFormType = invoiceFormType === EInvoiceFormType.COMPANY && companyFormCanBeSimplified;
  const isPolishCountryCode = invoiceAddressCountry.code === InvoiceAddressCountry.PL;
  const canFetchInvoiceData = !isCompanySimplifiedFormType &&
  isPolishCountryCode &&
  !isInvoiceDataComplete(getValues());
  const {invoiceData, isError} = useFetchInvoiceData(
    invoiceCompanyNip, invoiceAddressCountry.code, canFetchInvoiceData
  );

  const handleSetValue = (name: string, value: string) => {
    dispatch(setStorage(name, value, true));
    setValue(name, value, {shouldValidate: true});
  };

  useEffect(() => {
    if (invoiceData && !isError && !isCompanySimplifiedFormType) {
      const formValues = getValues();

      if (!isInvoiceDataComplete(formValues)) {
        pubsub.trigger(EVENT_INVOICE_FORM_COMPANY_DATA_LOAD, {facilityId, facilityName, nip: invoiceData.companyNip});
      }

      fillMissedData(formValues, invoiceData, handleSetValue);
    }
  }, [invoiceData]);

  return (
    <>
      <InvoiceToggler sendInvoice={sendInvoice} styles={{my: 3}} saveInStorage />
      {
        sendInvoice &&
        <Box sx={{mb: 4}} data-testid="agreements-invoice">
          <InvoiceFormTypeField saveInStorage />

          {
            isCompanySimplifiedFormType && (
              <Grid item xs={12}>
                <CompanySimplifiedInvoiceForm />
              </Grid>
            )
          }

          {
            (isPrivatePersonFormType || isCompanyFormType) && (
              <Grid container rowSpacing={3} columnSpacing={2} >
                <Grid item xs={12}>
                  <InvoiceCountryField saveInStorage />
                </Grid>
                {
                  isPrivatePersonFormType ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <InvoiceFirstNameField saveInStorage />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InvoiceLastNameField saveInStorage />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}>
                        <InvoiceNipSection saveInStorage />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CompanyNameField saveInStorage />
                      </Grid>
                    </>
                  )
                }
                <Grid item xs={12}>
                  <InvoiceStreetField saveInStorage />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InvoicePostalCodeSection saveInStorage />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InvoiceCityField saveInStorage />
                </Grid>
                {
                  isUsersInvoiceNoteEnabled && (
                    <Grid item xs={12}>
                      <InvoiceNoteField
                        saveInStorage
                      />
                    </Grid>
                  )
                }
                {
                  isCompanyFormType && (
                    <>
                      <RecipientToggler saveInStorage />
                      {
                        isInvoiceRecipient && (
                          <>
                            <Grid item xs={12}>
                              <InvoiceCountryField name="invoiceRecipientAddressCountry" saveInStorage />
                            </Grid>
                            <Grid item xs={12}>
                              <CompanyNameField
                                name="invoiceRecipientName"
                                label={locale.translate('recipientName')}
                                saveInStorage
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <InvoiceStreetField name="invoiceRecipientAddressStreet" saveInStorage />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <InvoicePostalCodeSection
                                fieldName="invoiceRecipientAddressPostalCode"
                                countryFieldName="invoiceRecipientAddressCountry"
                                saveInStorage
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <InvoiceCityField name="invoiceRecipientAddressCity" saveInStorage />
                            </Grid>
                          </>
                        )
                      }
                    </>
                  )
                }
              </Grid>
            )
          }
        </Box>
      }
    </>
  );
};

export default AgreementsInvoiceSection;
