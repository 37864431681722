import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Grid from '@mui/material/Grid';
import EmailField from '@Components/checkout/field/EmailField';
import FirstNameField from '@Components/checkout/field/FirstNameField';
import LastNameField from '@Components/checkout/field/LastNameField';
import Box from '@mui/material/Box';
import locale from '@Utils/locale';
import MarkedHeader from '@Components/headers/MarkedHeader';

const CustomerSection = () => (
  <Box>
    <MarkedHeader text={locale.translate('buyerData')} />
    <Grid container rowSpacing={3} columnSpacing={2} sx={{mb: 2}} >
      <Grid item xs={12}>
        <EmailField />
      </Grid>
      <Grid item xs={12} md={6}>
        <FirstNameField />
      </Grid>
      <Grid item xs={12} md={6}>
        <LastNameField />
      </Grid>
    </Grid>
  </Box>
);

export default CustomerSection;
